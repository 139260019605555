import React,{useState,useEffect, useContext} from "react";
import {approveApi, repairApi} from "../../api/axios.js";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

import BootstrapTable from 'react-bootstrap-table-next';
import Note from "./note.jsx";
import 'moment/min/locales';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import { handleEtat, handleTranName,filterCurency, notifySuccess, handlePrice, handleCountryNamePayment, domicileTranslate} from "../../utils/repairState.js";
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import ListMobile from "./ListMobile.jsx";
import { useSelector } from "react-redux";
import SpinnerLoadClient from "../../components/SpinnerLoadCLient";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchPaymentSuccess } from "../../store/Actions/paymentAction.js";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function RepairList(){

  const {t,i18n} = useTranslation("common");

    const history=useHistory();
    const [repair,setMyRepair]=useState([]);
    const [callNote,setcallNote]=useState(false);
    const { promiseInProgress } = usePromiseTracker();
    const [message,setMessage]=useState([]);
    const country= useSelector((state) => state.country.countryData)
    const user= useSelector((state) => state.user)
    const dispatch=useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    let onValueFilter = null;

    const columns = [{
      dataField: 'code',
      text:[t('repair.Code')],
      filter: customFilter(),
      filterRenderer: (onFilter, column) => {
        onValueFilter = onFilter;
        return null;
      },
      formatter: (cell) => {
        return <><span className="codeBefore">{cell}</span></>
      }
      
    }, {
      dataField: handleTranName(i18n.resolvedLanguage,"",true),
      text: [t('repair.Repair')],
      formatter: (cell,row) => {
        return <>{cell ? cell :row.category.displayName}</>
      }
      },
    
    {
      dataField: 'etat',
      text: [t('repair.State')],
      formatter: (cell) => {
        return <>{t('etat.'+handleEtat(cell))}</>
      }
      },
    
    {
      dataField: 'ReparationProduct.prix',
      text: [t('public.Price')],
      formatter: (cell, row) => handlePrice(cell,row,country,t)  
},


    {
      dataField: 'created_at',
      text: [t('repair.Date of demand')],
      formatter: (cell) => {
        return <>{moment(cell).locale(i18n.resolvedLanguage!="ar" ? i18n.resolvedLanguage :'ar-tn').fromNow()}</>
      }
      },
   /* {
      dataField: 'centreDepot.name',
      text: [t('repair.Deposit Center')]
    },*/

   
    {
      dataField: 'updatedAt',
      text: [t('repair.Last update')],
      formatter: (cell) => {
        return <>{moment(cell).locale(i18n.resolvedLanguage!="ar" ? i18n.resolvedLanguage :'ar-tn').fromNow()}</>
      
      },
      
    },
  
  ];

// set filter vlaue
  const onCodeChange = (event) => {
    const {value} = event.target;
    onValueFilter(value);
   
  };

// object of pagination option
  const paginationOption = {
    page:1,
    //totalSize: repair.length,
    sizePerPage:8,
    hideSizePerPage:true,
    alwaysShowAllBtns:true,
    hidePageListOnlyOnePage:true,
    onPageChange:()=>window.scrollTo(0,35),
     
  };

    // save payment necessary data in sotre and push to the payment page

  const handlePayment=(id,codeEtat,total,curency,name,codeRepair,typePayment)=>{
    //setShow(true)
    //handleCountryNamePayment();
    let paymentData={"id":id,"codeEtat":codeEtat,"total":total,"name":name,"curency":curency,"codeRepair":codeRepair,"typePayment":typePayment}
    dispatch(fetchPaymentSuccess(paymentData))
    history.push("/"+i18n.resolvedLanguage+"/dashboard/reparation/payment");

  }

  //show model to approve or refuse reparation
  const handleModel=()=>{
    setShow(true)
  }

  // match and render expanded row
    const expandRow = {
      renderer: row => {
        return(
          
        repair.map((repairs,index)=>(
          
          row.code===repairs.code?
            <div  key={repairs.code} style={{width:"100%",padding:"20px"}} className="form-group">
            <Tabs defaultActiveKey="diagnostic" id="repair-tabSub" className="repair-tabSubc mb-3">
            <Tab eventKey="diagnostic" title={t('repair.Diagnostic')}>
            
             {repairs.etat==="R108T"?
             <div class="container row">
              <div class="mt-2 mb-4">
              <p className="mr-4 d-inline">{t('public.The status of your repair is')} {t('etat.'+handleEtat(repairs.etat))} , {t('public.Price')} : {repairs.prix+" "+filterCurency(country,row)}
             </p>
             </div>
             <div class="mb-4">
                <button onClick={handleModel} type="submit" class="margin-direction save-profile btn btn-primary">{t('public.Accept')}</button>
                <Modal show={show} onHide={handleClose}>
                <Modal.Header  closeButton>
                  <strong>{t('public.Method of payment')}</strong>
                </Modal.Header>
                <Modal.Body>{repairs.code} {t('public.Choose the payment method that suits you')}</Modal.Body>
                <Modal.Footer>
                  <Button className="btn btn-primary" onClick={()=>handlePayment(repairs._id,"R110",repairs.prix,filterCurency(country,row),handleTranName(i18n.resolvedLanguage,repairs,true,true),repairs.code)}>
                  {t('public.Pay online')}
                  </Button>
                  <Button className="btn btn-primary" onClick={()=>handlePayment(repairs._id,"R110",repairs.prix,filterCurency(country,row),handleTranName(i18n.resolvedLanguage,repairs,true,true),repairs.code,"cash on delivery")}>
                  {t('public.Pay on delivery')}
                  </Button>
                 {/* <Button className="btn btn-primary" onClick={()=>handleApprove(repairs._id,"R110")}>
                  {t('public.Pay on delivery')}
        </Button>*/}
                </Modal.Footer>
              </Modal>
                {repairs.etat==="R109"?
                <button style={{opacity:"50%"}} type="button" class="ml-2 mr-2 save-profile btn btn-primary">{t('public.Refuse')}</button>
                :
                <button onClick={()=>handleApprove(repairs._id,"R109")} type="submit" class="ml-2 mr-2 save-profile btn btn-primary">{t('public.Refuse')}</button>
                }
                </div>
                </div>
                :""}
          
                <Note translate={t} id={repairs._id} code={repairs.code} call={callNote} lang={i18n.resolvedLanguage}/>         
            </Tab>
            <Tab eventKey="history" title={t('repair.Logistics')}>
            
            {repairs.typeDepot=="c2"?
            <div className="p-5 row">
            <div className="col-7">
            {t('repair.Repair center')}
            <p className="mt-3">{t('repair.Shop name',{name:repairs.centreRecuperation.name})}</p>
            {repairs.typeDepot=="c3"?
            <p>{t('repair.Address',{name:repairs.centreRecuperation.adress})}</p>
            :
            <p>{t('repair.Address',{name:repairs.recupHome})}</p>
          }
            <p>{t('repair.Phone',{nb:repairs.centreRecuperation.phone})}</p>

            </div>

            <div className="col-5">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51084.040391434915!2d10.156956929592832!3d36.84840254478863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd35fa41b73cf3%3A0x40651c0b73193dec!2sTrustiT!5e0!3m2!1sen!2stn!4v1654004200903!5m2!1sen!2stn" width="100%" height="100%" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

            </div>

            </div>
            :""}

      {repairs.typeDepot!="c2"?
          <>
            <div className="p-5 row">
            <div className="col-7">
            {t('repair.Deposit Center')}
            {repairs.centreDepot && repairs.centreDepot.name?<p className="mt-3">{t('repair.Shop name',{name:domicileTranslate(repairs.centreDepot.name,t)})}</p>
            :""}
            {repairs.typeDepot=="c3"?
            <p>{t('repair.Address',{name:repairs.centreDepot ?repairs.centreDepot.adress:""})}</p>
            :
            <p>{t('repair.Address',{name:repairs.recupHome})}</p>
            
           }
            <p>{t('repair.Phone',{nb:repairs.centreDepot? repairs.centreDepot.phone:""})}</p>


            </div>

            <div className="col-5">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51084.040391434915!2d10.156956929592832!3d36.84840254478863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd35fa41b73cf3%3A0x40651c0b73193dec!2sTrustiT!5e0!3m2!1sen!2stn!4v1654004200903!5m2!1sen!2stn" width="100%" height="100%" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

            </div>

            </div>


            <div className="p-5 row">
            <div className="col-7">
            {t('repair.Recovery center')}
            {repairs.centreRecuperation && repairs.centreRecuperation.name ?
            <p className="mt-3">{t('repair.Shop name',{name:domicileTranslate(repairs.centreRecuperation.name,t)})}</p>
            :""}
            {repairs.typeDepot=="c3"?
            <p>{t('repair.Address',{name:repairs.centreRecuperation?repairs.centreRecuperation.adress:""})}</p>
            :
            <p>{t('repair.Address',{name:repairs.recupHome})}</p>
          }
            <p>{t('repair.Phone',{nb:repairs.centreRecuperation?repairs.centreRecuperation.phone:""})}</p>

            </div>

            <div className="col-5">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51084.040391434915!2d10.156956929592832!3d36.84840254478863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd35fa41b73cf3%3A0x40651c0b73193dec!2sTrustiT!5e0!3m2!1sen!2stn!4v1654004200903!5m2!1sen!2stn" width="100%" height="100%" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

            </div>

            </div>
            </>
            :""}
            </Tab>
            <Tab eventKey="center" title={t('repair.History')}>
            <div className="ml-2 mr-2 p-5 row">
            <div className="history-tab col-6">
              <p style={{color:"#35A0EE",fontSize:"20px",fontWeight:600}}>{t('repair.History')}</p>
              {repairs.historique.map((historiques,index) => 
           <div key={historiques.codeEtat} className="mt-4">
            <p className="history-steper"></p>
            <div style={{minHeight:repairs.historique.length===index+1?"0":""}}className="vertical-line-history"></div>
            <p style={{color:"#2A7DBA"}}>
              {historiques.codeEtat?
              t('etat.'+handleEtat(historiques.codeEtat))
              :historiques.etat}
              </p>
            
            <p>{historiques.etat || historiques.codeEtat ? moment(historiques.created_at).format(i18n.resolvedLanguage=="ar"? "yy-MM-DD, HH:mm":'DD-MM-yy, HH:mm'):""}</p>
            </div>
            
        )}
            </div>
            </div>

            </Tab>
          </Tabs>
           
        </div>
        :''
          )
        ))
        
      },
      onlyOneExpanding: true,

    }

    // called when reparation is refused
    const handleApprove=(id,etat)=>{
      const data=approveApi(id,etat,user.userData.token);
      data.then(result=>{
        setMessage(result);
        //console.log("testou",result)
        if(result[0].status===200){
          getRepairApi();
          window.scrollTo(0, 0);

          notifySuccess(t('etat.'+result[0].message));
          setTimeout(() => {
            setMessage([]);
          }, 3500);
        } 
      })
    }

    // get all reparation list data
    const getRepairApi=()=>{
      let data=[];
      trackPromise(
       data=repairApi(user.userData.token)
      );       
       data.then(result =>
          setMyRepair(result));

    }

    
    useEffect(() => {
      //console.log(user.userData.token)
        getRepairApi();
      }, []);


    return (


  <div className="repairList-contianer col-lg-9 col-md-12">
  <h3 className="title-form">{t('repair.My repairs')}</h3>
  

{!promiseInProgress && repair && repair.length>0 ?
<>
      <div className="row float-right mb-3">
      <input className="form-control search-table" onChange={onCodeChange} placeholder={t('repair.Type your code')}/>
      </div>
    <BootstrapTable
      keyField='code'
      data={ repair }
      columns={ columns }
      expandRow={expandRow}
      wrapperClasses="mt-3 table-responsive"
      classes="repair-table"
      pagination={ paginationFactory(paginationOption)}
      filter={ filterFactory()}        
    />
        <ListMobile translate={t} resolvedLanguage={i18n.resolvedLanguage} repairProps={repair}/>
        
        

    </>
    :''}
    
    {!promiseInProgress && repair && repair.length<1 ?
    <p className="text-center" style={{marginTop:"80px"}}>{t('repair.No repair available')}</p>:''}

  <SpinnerLoadClient/>


    </div>
    );
}
export default RepairList;