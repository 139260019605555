import React,{useCallback,useState,useEffect} from "react";
import { FormikConsumer, useFormik } from "formik";
import {repairValidation} from "../../schema/formValid";
import { FocusError } from 'focus-formik-error';
import SelectSearch from "../../components/SelectSearch";
import { components } from "react-select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { stepCurrent } from "../../store/Actions/stepCounterAction";
import { categoryPush } from "../../utils/repairFn";

function FormRepair(props){


  const [data,setData]=useState({});
  const [hasColor,SetHasColor]=useState(true);
  const [imeiValue,SetImei]=useState("");
  const steperData= useSelector((state) => state.stepData.steperData)
  const dispatch=useDispatch();

    useEffect(() => {
      if(!steperData["step5"].hasOwnProperty('color')){
            SetHasColor(false);
        }

      if(steperData["step6"]){
       setData(steperData["step6"]);
      }
      else {
        categoryPush(data,6,steperData,dispatch)

      }

    },[]);

    // hande reparation form before last

    const handleOnSubmit =(values) => {
        const {imei,couleur,detailPanne}=values;       
        let data={
            "imei":imei,
            "couleur":couleur,
            "detailPanne":detailPanne
        }
        setData(data);
        props.submitForm1(true);
        categoryPush(data,6,steperData,dispatch)
        dispatch(stepCurrent(7));

        
        };


    const formik = useFormik({
        initialValues:data,
        enableReinitialize: true,
        validationSchema: repairValidation(props.translate,hasColor,imeiValue),
        onSubmit: handleOnSubmit,
        validateOnChange:false,

      });

      const setInputValue = useCallback(
        (key, value) =>
          formik.setValues({
            ...formik.values,
            [key]: value,
          }),
        [formik],
      );

      const { Option } = components;


      // set icon of select
      const IconOption = props => (
        <Option {...props}>
         <i style={{marginRight:"8px",verticalAlign:"text-bottom",display:"inline-block",borderRadius:"50%",width:"19px",height:"19px",background:props.data.icon}}></i>
          {props.data.label}
        </Option>
      );
      
    
return (
<div className="form-repair col-lg-12">
        <form className="mt-3" noValidate onSubmit={formik.handleSubmit}>
        <FocusError formik={formik} />
        <div className="form-group">
            <input type="text" value={formik.values.imei} className={formik.errors.imei?"form-control is-invalid":"form-control"} name="imei" placeholder={props.translate('input:placeholder.Serial Number (IMEI)')} onChange={(e) => {setInputValue("imei", e.target.value);SetImei(e.target.value)}}/>
            <small className="text-error">{formik.errors.imei}</small>            
        </div>
            {hasColor===true?
            <div className="form-group">
             
            <SelectSearch  components={{ Option: IconOption }} placeholder={props.translate('input:placeholder.Color')} option={steperData["step5"] && steperData["step5"].color? steperData["step5"].color:[]} name="couleur" change={(opt,e) => setInputValue("couleur", opt.value)}/>

            <small className="text-error">{formik.errors.couleur}</small>            

            </div>
            :""}
            <div className="form-group">
             <textarea value={formik.values.detailPanne} className={formik.errors.detailPanne?"form-control is-invalid":"form-control"} name="history" rows="3" placeholder={props.translate('input:placeholder.Panne Details and History')} onChange={(e) => setInputValue("detailPanne", e.target.value)}></textarea>
             <small className="text-error">{formik.errors.detailPanne}</small>     
            </div>
            <p className="info-ligth">
            {props.translate('step.Every piece of information and detail is important to optimize diagnosis and repair time')}
            </p>
        <div className="form-group">
            <input type="text" className="form-control" name="coupon" placeholder={props.translate('input:placeholder.Discount coupon')}/>
        </div>
        
        <div className="col justify-content-center text-center button-same">
        <button type="submit" className="mt-3 btn btn-primary">{props.translate('public.Next')}</button>
        </div>

        </form>
 
</div>

);
}
export default FormRepair;
