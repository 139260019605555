import React from "react";
import PhoneComponent from "../../components/PhoneComponent";
import { handlePhoneChange } from "../../utils/repairState";
import PhoneVerification from "./phoneVerification";

function PhoneRegister(props){

    return(
        <>
        {props.phoneEtat===false ||props.phoneEtat==true && props.phonesubmited==false?
        <>
        <div className="registerby-phone form-group">
            <PhoneComponent setPhoneCountry={props.setPhoneCountry} change={(e)=>handlePhoneChange(e,props.setInputValue,props.setPhoneEtat)} formikError={props.formik.errors.phone} value={props.formik.values.phone} translate={props.translate}/>
            <small className="text-error">{props.formik.errors.phone}</small>            
            <small className="text-error">{props.phoneExistMessage?props.translate('register.'+props.phoneExistMessage):""}</small>            
            </div> 
            <div className="row">
          <div className="mt-3 col text-center">
          <button type="submit" className="save-profile btn btn-primary">{props.translate('public.Validate')}</button>
          </div>
          </div>
          </>
          :<PhoneVerification codeVerif={props.codeVerif} setCodeVerif={props.setCodeVerif} phoneNumber={props.formik.values.phone} setStepCurrentVal={props.setStepCurrentVal} setPhoneEtat={props.setPhoneEtat} translate={props.translate} setRegisterType={props.setRegisterType}/>}

       
        </>
    )
}
export default PhoneRegister;