export const increment = () => {
    return {
      type: "INC",
    };
  };
  
  export const decrement = () => {
    return {
      type: "DEC",
    };
  };

  export const add = (nb) => {
    return {
      type: "ADD",
      payload:nb
    };
  };