import { combineReducers } from '@reduxjs/toolkit';
import blogReducer from './Reducers/blogReducer';
import blogSlugReducer from './Reducers/blogSlugReducer';
import brandReducer from './Reducers/brandReducer';
import counterReducer from './Reducers/counterReducer';
import countryReducer from './Reducers/countryReducer';
import detectionReducer from './Reducers/detectedCountryReducer';
import deviceReducer from './Reducers/deviceReducer';
import disableVIewReducer from './Reducers/disableVIewReducer';
import langReducer from './Reducers/langReducer';
import paymentReducer from './Reducers/paymentReducer';
import stepcounterReducer from './Reducers/stepCounterReducer';
import steperDataReducer from './Reducers/steperDataReducer';
import userReducer from './Reducers/userReducer';

const rootReducer = combineReducers({
  country:countryReducer,
  countryDetected:detectionReducer,
  device:deviceReducer,
  blog:blogReducer,
  blogSlug:blogSlugReducer,
  user:userReducer,
  brand:brandReducer,
  counter:counterReducer,
  stepcounter:stepcounterReducer,
  stepData:steperDataReducer,
  langApp:langReducer,
  paymentApp:paymentReducer,
  disableViewEtat:disableVIewReducer

});

export default rootReducer;
