import React from "react";
import ContactForm from "../../components/ContactForm";
import { useTranslation } from 'react-i18next';
import ContactAdress from "./contactAdress";
import SEO from "../../components/SEO";

function Contact(){

    const {t} = useTranslation("common");

    return(

        <div className="mt-5 contact-container container">
            <SEO 
            title={t('seoContent.title contact',{name:process.RAZZLE_REACT_APP_NAME})}
            description={t('seoContent.description contact',{name:process.RAZZLE_REACT_APP_NAME})}
            keywords={t('seoContent.keyword contact')}
           />
            <h1 className="text-header">{t('SeoBody.contact h1',{name:process.RAZZLE_REACT_APP_NAME})}</h1>

            <div className="row">
            <div className="col-lg-12 mb-4">
            <h2 className="text-center">{t('public.Contact us')}</h2>
            </div>
        </div>
        <ContactAdress name={"contact-desktop"} className="desktop-contact" translate={t}/>
        
        
        <div className="row">
        <div className="mt-3 col-lg-12">
        <ContactForm translate={t}/>
        </div>
        </div>

        <ContactAdress name={"contact-mobile"} translate={t}/>

        </div>


    );

}

export default Contact;