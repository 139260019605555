import React,{useState,useEffect} from "react";
import {blogCount} from "../api/axios.js";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Pagination from 'react-bootstrap/Pagination';

function BlogCard(props){

    const history=useHistory();
    const blogData= useSelector((state) => state.blog.blogData)
    const blogAdditionalData= useSelector((state) => state.blog.blogAdditionalData)
    const nPages = Math.ceil(blogAdditionalData.totalBlog / blogAdditionalData.countPerPage)
    const pageNumbers = nPages>0?[...Array(nPages + 1).keys()].slice(1):0;  


    //handle pagination change
    const handlePagination=(nbPage)=>{
        history.push(`/${props.lang}/blog/`+nbPage);
    }

    //render pagination 
    const paginationView=()=>{
        return(
            blogData.length>0 && nPages>1 ?
            <Pagination className="pagination-hold">
                
            {pageNumbers.map(pageNumberss=>
                <Pagination.Item key={pageNumberss} activeLabel="" active={pageNumberss==blogAdditionalData.page?true:false} onClick={()=>handlePagination(pageNumberss)}>{pageNumberss}</Pagination.Item>
            )}
        
        
            </Pagination>
            :""
            );
        }



    const handleSlug=(title)=>{
        history.push(`/${props.lang}/blog/detail/`+title);
        
    }

    return (
        <>
        <div className="row">
        <div className="mt-4 card-deck">
        {blogData.map((blogDatas,index)=>(
            <div  key={index} className="col-md-6 col-lg-4 col-sm-6 col-xs-6">
          <div className="card" onClick={()=>handleSlug(blogDatas.slug)}>
            <img width="210" heigth="210" className="card-img-top" src={process.env.RAZZLE_REACT_APP_BASE_URL+"/"+blogDatas.cover} alt={blogDatas.title}/>
            <div className="card-body">
              <h5 className="card-title">{blogDatas.title}</h5>
              <p className="card-text"><small className="text-muted">{blogDatas.description}</small></p>
            </div>
          </div>
          </div>
        ))}

        </div>
 
        </div>

        <div className="mt-5 row justify-content-center">
        {paginationView()}
        </div>

        </>
                
    );

}

export default BlogCard;