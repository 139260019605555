import { Steps} from 'rsuite';
import React from "react";

function StepperComponent(props){

  const styles = {
    verticalAlign: 'top',

  };

      const handleClick=(step)=>{
        //alert(step)
    }
      
      const steperItem=[
        {"title":""},
        {"title":""},
        {"title":""},
    
      ]

    return(
        <>
        <Steps className="steper-phone" current={props.stepCurrentVal} style={styles}>
          
          {steperItem.map((steperItems,index)=>
             <Steps.Item  key={index} onClick={()=>handleClick(index+1)} title={steperItems.title}/>
        )}
          
        </Steps>

      </>
    );
}

export default StepperComponent;