import {brandApi, deviceApi, gammeApi, modelApi, productRepairApi} from "../../api/axios"
import { categoryPush, handleTranslatedName } from "../../utils/repairFn";
import { add } from "./counterAction";
import { stepCurrent } from '../../store/Actions/stepCounterAction';

export const fetchDeviceSuccess = device => ({
    type: 'FETCH_ALL_DEVICE',
    payload: { device }
})

let device=[];

const handleParam=(count,objectFiltred,newdata,getState,dispatch,langApp)=>{
    const {steperData} = getState().stepData;
    dispatch(add(count))
    dispatch(stepCurrent(count)); 
    categoryPush({id:objectFiltred[0]?objectFiltred[0]._id:"",name:objectFiltred[0]?handleTranslatedName(objectFiltred[0],count-1,langApp):"-",picture:objectFiltred[0]?objectFiltred[0].picture:[]},count-1,steperData,dispatch);
    device=newdata
}

const handleDecodedUrl=(lang,cleanParam)=>{
    if(lang=="ar")
    return decodeURI(cleanParam)
    else
    return cleanParam
}

// get all steper data by param 

export const fetchDevice =  (param) => {
    return async (dispatch,getState) => {
        try {

            const {langApp,countryDetected} = getState();
            const country_id=countryDetected?countryDetected.detectionData.value:"";
            let removeAccents = require('remove-accents');
            device = await deviceApi(country_id);
            
            let brand=[];
            let gamme=[];
            let model=[];
            let deviceFiltred=[];
            let brandFiltred=[];
            let gammeFiltred=[];
            let modelFiltred=[];
            let productRepair=[];

           if(param.step1){  
                let cleanParam=param.step1.toLowerCase();
                cleanParam=handleDecodedUrl(langApp,cleanParam)
                deviceFiltred=device.filter(devices=>removeAccents(handleTranslatedName(devices,1,langApp)).toLowerCase().split(' ').join('-').includes(cleanParam));
                brand=await brandApi(deviceFiltred[0]._id,country_id);
                handleParam(2,deviceFiltred,brand,getState,dispatch,langApp)
            }

            if(param.step2){
                let cleanParam=param.step2.toLowerCase();
                cleanParam=handleDecodedUrl(langApp,cleanParam)

                brandFiltred=brand.filter(brands=>removeAccents(brands.name).toLowerCase().split(' ').join('-').includes(cleanParam));
                gamme=await gammeApi(deviceFiltred[0]._id,brandFiltred[0]._id,country_id);
                handleParam(3,brandFiltred,gamme,getState,dispatch,langApp)
            }

            if(param.step3){
                let cleanParam=param.step3.toLowerCase();
                cleanParam=handleDecodedUrl(langApp,cleanParam)

                gammeFiltred=gamme.filter(gammes=>removeAccents(gammes.name).toLowerCase().split(' ').join('-').includes(cleanParam));
                model=await modelApi(deviceFiltred[0]._id,brandFiltred[0]._id,gammeFiltred[0]?gammeFiltred[0]._id:"",country_id);
                handleParam(4,gammeFiltred,model,getState,dispatch,langApp)
            }

            if(param.step4){
                //const {detectionData} = getState().countryDetected;
                if(countryDetected){
                    //let country_id=detectionData.value
                    console.log(country_id)
                    let cleanParam=param.step4.toLowerCase();
                    modelFiltred=model.filter(models=>removeAccents(models.name).toLowerCase().split(' ').join('-').includes(cleanParam));
                    productRepair=await productRepairApi(deviceFiltred[0]._id,brandFiltred[0]._id,gammeFiltred[0]?gammeFiltred[0]._id:"",modelFiltred[0]._id,country_id);
                    handleParam(5,modelFiltred,productRepair,getState,dispatch,langApp)
                }
            }
            
            
            dispatch(fetchDeviceSuccess(device))

        }
        catch(e){
            console.log(e)
        }
    }
}