import React, { useEffect, useState,useRef} from "react";
import header from "../../assets/image/header/homeHeader.svg";
import imgdevis from "../../assets/image/static-page/home-offre.svg";
import main from "../../assets/image/static-page/main-image.png";
import homeoffre from "../../assets/image/static-page/home-offre.svg";
import googleplay from "../../assets/image/other/google-play.svg";
import istore from "../../assets/image/other/istore.svg";
import ButtonLink from "../../components/ButtonLink.js";
import CodeVerification from "./codeVerification.jsx";
import RepairCategory from "./repairCategory.jsx";
import Stepper from "./stepper";
import StepperTop from "./stepperTop";
import Slider from "../../components/SliderReview.js";
import {useParams,useLocation,useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CallBack from "./callback";
import 'react-phone-number-input/style.css'
import SEO from "../../components/SEO";
import { fetchDevice, fetchDeviceSuccess } from "../../store/Actions/deviceAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { stepCurrent } from "../../store/Actions/stepCounterAction";
import { add } from "../../store/Actions/counterAction";
import { fetchSteperData } from "../../store/Actions/steperDataAction";
import { deviceApi } from "../../api/axios";

const Home=(props)=>{

    const {t,i18n} = useTranslation("common");
    const lang=i18n.resolvedLanguage;
    const param = useParams();

    const location= useLocation()
    const history= useHistory()

    const [codeGenerated,SetCodeGenerated]=useState(false);
    const [noSubCategory,SetNoSubCategory]=useState(false);
    const ref = useRef(true);
    const {isLoading}=props
    const detection= useSelector((state) => state.countryDetected)
    const steperData= useSelector((state) => state.stepData.steperData)
    const step= useSelector((state) => state.stepcounter)
    const { step1,step2,step3,step4} = useParams();
    const dispatch=useDispatch();
    const device= useSelector((state) => state.device.deviceData);


    // if deposit reparation clicked it will clear all previous step and set the devis
   useEffect(()=>{
        const country_id=detection?detection.detectionData.value:"";
        if(location && location.state && location.state.reset){
            if(!device || device.length==0){
            let data=deviceApi(country_id);
            data.then(result=>{
                dispatch(fetchDeviceSuccess(result))

            })
        }
            dispatch(stepCurrent(1))
            dispatch(add(1))
            SetNoSubCategory(false);
            SetCodeGenerated(false)
            dispatch(fetchSteperData([]))
            if(!location.state.noscroll){
            window.scrollTo({ behavior: 'smooth', top:400})
            }
            window.history.replaceState({}, document.title)

        }
       
      },[location.state])
  
    // true when all stepr finished and reparation passed to show code generated component
    const codeGeneratedProps=()=>{
        SetCodeGenerated(true);
        //steperData([]);
    }

    // block category from being clicked if not reached in the steper
    const noSubCategoryProps=(value)=>{
        SetNoSubCategory(value);
        //steperData([]);
    }

    
    return(
        <div className="container header-container home-container">
            <SEO 
            title={t('seoContent.title home',{name:process.env.RAZZLE_REACT_APP_NAME})}
            description={t('seoContent.home description',{name:process.env.RAZZLE_REACT_APP_NAME})}
            keywords={t('seoContent.keyword home')}
           />

            <h1 className="text-header">{t('SeoBody.home h1')}</h1>
            {codeGenerated ===false?
            <div className="row">

            <div className="col-lg-8">
            <h2 className="header-text">TrustiT {t('home.the network of trusted repairers')}</h2>
            <img className="mb-4 img-header" src={header} alt="Header"/>
            </div>
            {detection && detection.detectionData && detection.detectionData.code=="TN"?
            <div className="mt-4 mb-3 col-lg-4">
            <CallBack resolvedlang={lang} translate={t}/>
  
            </div>
            :""}
            </div>
            :''}

            <div className="row margin-space">
            <div className={step===7?"col-lg-12":"col-lg-8"}>
            {codeGenerated ===false?
            <h3 className="d-inline title-form block-steper">{t('home.Depose your Repair')}</h3>
            :''}
            {codeGenerated ===false && steperData && steperData["step1"]?

            <StepperTop stepCurrent={step}/>
            :''}
            <div className={codeGenerated ===false? "mt-4 row":'row'}>
            {codeGenerated ===false?
            <div className={step===7 ?"mb-3 col-lg-3" :'mb-3 col-lg-4'}>
            <Stepper translate={t} noSubCategory={noSubCategory} stepCurrent={step}/>
            </div>
            :''}

            <div className={codeGenerated===false? step===7?"col-lg-9":'col-lg-8' :'col-lg-12'}>
           
            {/*{step<6?
            <SearchCategory translate={t}/>:''}*/}
            <RepairCategory resolvedlang={lang} translate={t} noSubCategory={noSubCategoryProps} codeGeneratedState={codeGenerated} codeGeneratedProps={codeGeneratedProps}/>
           
    </div>

    </div>

    </div>

    {step===1?
        <div className="col-lg-4">
        
        <CodeVerification resolvedlang={lang} translate={t}/>
        <div className="mb-3 row mt-5">
        <div className="col-lg-6 col-sm-6 col-6">
        <a target="_blank"  href={process.env.RAZZLE_REACT_APP_GOOGLE_PLAY}><img className="store-img" src={googleplay}/></a>
        </div>
            <div className="col-lg-6 col-sm-6 col-6">
            <a target="_blank"  href={process.env.RAZZLE_REACT_APP_APPLE_STORE} ><img className="store-img" src={istore}/></a>
            </div>

        </div>
        </div>
        :''}

        {step>1 && step <4?
        <div className="col-lg-4">

        <div className="col-lg-9">
        <img src={imgdevis}/>
        </div>
        <div className="col-lg-9">
        <p className="mt-2 text-center">{t('home.If you are a business, request your devis online.')}</p>
        
                <div className="mt-3 col text-center justify-content-center">
                <ButtonLink path={{pathname:`/${lang}/devis`,state:{"typeDevis":t('home.Normal')}}} name={t('public.Ask for devis')}/>
            </div>
        </div>
        
        </div>        
    
    :''}

        </div>
        {step===1?
        <>

        <div className="row margin-space">
        <div className="col-lg-4">
        <img width="100%" src={homeoffre} alt={t('home.If you are a business, request your devis online.')}/>
        </div>
        <div className="col-lg-4 mb-3">
        <h3 className="mt-2 mb-2 title-form">{t('public.Business offer')}</h3>
        <p className="mb-4">{t('home.If you are a business, request your devis online.')}</p>
        <ButtonLink fromCustompath={true} path={{pathname:`/${lang}/devis`,state:{"typeDevis":t('home.Normal')}}} name={t('public.Ask for devis')}/>
        </div>

        <div className="col-lg-4">
        <img width={"100%"} className="handImage" src={main}/>
        </div>

        </div>

        <div className="margin-space row">
        <div className=" mt-5 mb-3 text-center justify-content-center col-lg-12">
        <h5>{t('home.Testimonials')}</h5>
        </div>
        </div>

        <div className="row ">
        <div className="mt-4 mb-4 text-center justify-content-center col-lg-12">
        <Slider translate={t}/>
        </div>
        </div>

            {/*}
        <div className="mt-4 row">
        <div className="mt-5 text-center justify-content-center col-lg-12">
        <h5>{t('home.Recent Articles')}</h5>
        </div>
        </div>
        

        <div className="mb-4 blog-container container">
        <div className="row">

        <BlogCard count="3"/>
        
        </div>
        </div>
        
        
        <div className="mb-4 col-12 text-center justify-content-center">
         <ButtonLink path="/blog" name={t('public.See more')}/>
        </div>
        */}
        </>
        :''}

</div>
    );

}

Home.getInitialProps = async ({store,match,req}) => {
    if(req){
    const param=match.params;
    const device=store.dispatch(fetchDevice(param));
    return device
    }
  };

export default Home;