import React,{useState} from "react";
import PropagateLoader from "react-spinners/BeatLoader";
import { usePromiseTracker } from "react-promise-tracker";

const override= {
 textAlign:"center",
 marginTop:"60px"
};

function SpinnerLoadClient(){
  const { promiseInProgress } = usePromiseTracker();

    return(
      (promiseInProgress === true) ?
        <PropagateLoader 
        color="#35A0EE"
        size={15}
        cssOverride={override}
        />
        :
        null

    );
}
export default SpinnerLoadClient;