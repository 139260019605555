const stepcounterReducer = (stepcounter = 1, action) => {
    switch (action.type) {
      case "INCSTEP":
        return stepcounter + 1;
      case "DECSTEP":
        return stepcounter - 1;
        case "ADDSTEP":
          return action.payload

      default:
        return stepcounter;
    }
  };
  export default stepcounterReducer;