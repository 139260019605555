import React, { Suspense } from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter, Route} from 'react-router-dom';
import { ensureReady, After, getSerializedData } from '@jaredpalmer/after';
import routes from './router/routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-number-input/style.css'
import "./assets/style/style.css";
import "./assets/style/stepper.css";
import { Provider } from 'react-redux';
import { createStore } from './store';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next'; 
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from "./components/ScrollToTop.js";
import Layout from './Layout';
import FileSelector from "./FileSelector.js";
import Loader from './components/Loader';

const preloadedState = getSerializedData('preloaded_state');
const store = createStore(preloadedState);


  ensureReady(routes).then(data =>
    
    hydrate(
      <Suspense fallback={<Loader/>}>
      <I18nextProvider i18n={i18n}
      initialI18nStore={window.initialI18nStore}
      initialLanguage={window.initialLanguage}>
      <HelmetProvider>
      <BrowserRouter>
      <ScrollToTop>
        <Provider store={store}>
          <Layout>
          <FileSelector>
          <After data={data} routes={routes} store={store} />
          </FileSelector>
          </Layout>
        </Provider>
        </ScrollToTop>     
      </BrowserRouter>

      </HelmetProvider>
      </I18nextProvider>
      </Suspense>
      ,
      document.getElementById('root')
    )
  );
  

if (module.hot) {
  module.hot.accept();
}

