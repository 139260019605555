import React from "react";
import DevisForm from "../../components/DevisForm";
import imgdevis from "../../assets/image/contact-devis/image-devis.png";
import { useTranslation } from 'react-i18next';
import SEO from "../../components/SEO";

function Devis() {

    const {t} = useTranslation("common");

    return(
    <div className="inscrption-container container devis-form">
            <SEO 
            title={t('seoContent.title devis')}
            description={t('seoContent.description devis')}
            keywords={t('seoContent.keyword devis')}
    />
            <h1 className="text-header">{t('SeoBody.devis h1')}</h1>

        <div className="row">
        <div className="col-lg-5 mb-5">
            <figure>
            <img style={{width:"100%"}} src={imgdevis} alt={t('devis.Choosing is',{name:'TrustiT'})}/>
        </figure>
         <div className="col-lg-12 btn-class">
         <h4>{t('devis.Choosing is',{name:'TrustiT'})}</h4>
            </div>
            
        <div className="col-lg-12 btn-class">
            <span>{t('devis.Enjoy quality, fast and punctual service Work with professional repairers in the field Save time and effort')}</span>
        </div>
        </div>

        <div className="col-lg-7">
        <DevisForm translate={t}/>
        </div>


        </div>
        </div>


    );

}

export default Devis;