import axios from 'axios';
import { setCookie, setLocalStorage, getFromLocalStorage } from '../utils/auth';

const baseURL = process.env.RAZZLE_REACT_APP_BASE_URL;
//auth api
const clientURL = process.env.RAZZLE_REACT_APP_CLIENT_URL;
const registerURL = process.env.RAZZLE_REACT_APP_CLIENT_REGISTER_URL;
const registerPhoneURL = process.env.RAZZLE_REACT_APP_CLIENT_REGISTER_PHONE_URL;
const loginURL = process.env.RAZZLE_REACT_APP_CLIENT_LOGIN_URL;
const forgetURL = process.env.RAZZLE_REACT_APP_CLIENT_FORGET_URL;
const resetURL = process.env.RAZZLE_REACT_APP_CLIENT_RESET_URL;
const repairerReset = process.env.RAZZLE_REACT_APP_CLIENT_EXPERT_RESET_URL;
const verifyPhone = process.env.RAZZLE_REACT_APP_CLIENT_VERIFY_PHONE_URL;

//client mangement api
const scoreURL = process.env.RAZZLE_REACT_APP_CLIENT_SCORE_URL;
const repairURL = process.env.RAZZLE_REACT_APP_CLIENT_REPAIR_URL;
const passwordProfileURL =
  process.env.RAZZLE_REACT_APP_CLIENT_UPDATE_PASSWORD_URL;
const profileURL = process.env.RAZZLE_REACT_APP_CLIENT_PROFILE_URL;
const pictureURL = process.env.RAZZLE_REACT_APP_CLIENT_PICTURE_URL;
const noteURL = process.env.RAZZLE_REACT_APP_CLIENT_SEARCH_NOTE_URl;
const postNoteURL = process.env.RAZZLE_REACT_APP_CLIENT_NOTE_URl;
const approveURL = process.env.RAZZLE_REACT_APP_CLIENT_APPROVE;
const createPaymentUrl = process.env.RAZZLE_REACT_APP_CREATE_PAYMENT;
const publicPaymentUrl = process.env.RAZZLE_REACT_APP_CHECK_PAYMENT;
const backendPaymentCheck =
  process.env.RAZZLE_REACT_APP_CLIENT_VERIFY_PAYMENT_BACKEND;
const InvoiceApi = process.env.RAZZLE_REACT_APP_CLIENT_GENERATE_INVOICE_URL;

// public api
const publicURL = process.env.RAZZLE_REACT_APP_PUBLIC_URL;
const contactURL = process.env.RAZZLE_REACT_APP_PUBLIC_CONTACT_URL;
const blogURL = process.env.RAZZLE_REACT_APP_PUBLIC_BLOG_URL;
const bloginfoURL = process.env.RAZZLE_REACT_APP_PUBLIC_BLOG_INFO;
const blogCountUrl = process.env.RAZZLE_REACT_APP_PUBLIC_BLOG_COUNT;

const codeURL = process.env.RAZZLE_REACT_APP_PUBLIC_CODE_SEARCH_URL;
const subscribeURL = process.env.RAZZLE_REACT_APP_PUBLIC_SUBSCRIBE_URL;
const callbackURL = process.env.RAZZLE_REACT_APP_CALL_BACK_URL;
const packdURL = process.env.RAZZLE_REACT_APP_PACKD_URL;
const regionURL = process.env.RAZZLE_REACT_APP_REGION_ALL;
const cityURL = process.env.RAZZLE_REACT_APP_CITY_ALL;
const googleURL = process.env.RAZZLE_REACT_APP_GOOGLE_LOGIN_URL;
const smsURL = process.env.RAZZLE_REACT_APP_SEND_SMS;

// device and sub api
const countryURL = process.env.RAZZLE_REACT_APP_PUBLIC_COUNTRY_ALL_URL;
const deviceURL = process.env.RAZZLE_REACT_APP_PUBLIC_DEVICE_ALL_URL;
const brandURL = process.env.RAZZLE_REACT_APP_PUBLIC_BRAND_BYDEVICE_URL;
const gammeURL = process.env.RAZZLE_REACT_APP_PUBLIC_GAMME_URL;
const modelURL = process.env.RAZZLE_REACT_APP_PUBLIC_MODEL_URL;
const panneURL = process.env.RAZZLE_REACT_APP_PUBLIC_PANNE_URL;

//category public api
const categoryURL = process.env.RAZZLE_REACT_APP_PUBLIC_CATEGORY_LIST_URL;
const categoryChildrenURL =
  process.env.RAZZLE_REACT_APP_PUBLIC_CATEGORY_CHILD_URL;
const categorySearchURL =
  process.env.RAZZLE_REACT_APP_PUBLIC_CATEGORY_SEARCH_URL;
//boutique public api
//const boutiqueURL= process.env.RAZZLE_REACT_APP_PUBLIC_BOUTIQUE_URL;
const boutiqueURL = process.env.RAZZLE_REACT_APP_PUBLIC_BOUTIQUE_BY_COUNTRY_URL;
// reparation public new
const reparationURL = process.env.RAZZLE_REACT_APP_PUBLIC_REPARATION_URL;

//location public api
const locationURL = process.env.RAZZLE_REACT_APP_PUBLIC_LOCATION_URL;
const gouvernoratURL = process.env.RAZZLE_REACT_APP_PUBLIC_GOUVERNORATS_URL;
const delegationURL = process.env.RAZZLE_REACT_APP_PUBLIC_DELEGATION_URL;

//detection url
const detectionURL = process.env.RAZZLE_REACT_APP_COUNTRY_DETECTION;

// send id for paytabs to backend
export const payTabsFrom = async (header, id) => {
  const res = await axios.post(
    'https://api.trustit.app/client-api/v1/reparations/payedOnLine',
    {
      id,
    },
    {
      headers: {
        'x-auth-token': header,
      },
    },
  );
  return res;
};

export const fetchCountryData = async () => {
  let data = [];
  await axios
    .get('https://api.trustit.app/public-api/v1/country/all')
    .then((res) => {
      data = res.data;
      // console.log(data);
    })
    .catch((error) => {
      //console.log(error);
    });
  return data;
};

//send sms verif
export const verifPhone = async (phone) => {
  let message = [{ status: '', message: '' }];

  await axios
    .post(baseURL + clientURL + verifyPhone, {
      phone: phone,
    })
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [{ status: res.status, phoneExist: res.data }];
      }
    })
    .catch((error) => {
      console.log(error.response);
      /* if(error.response.status===409)
        message=[{"status":error.response.status,"message":"Please try later"}];
     */
    });
  return message;
};

//send sms verif
export const smsApi = async (phone) => {
  let message = [{ status: '', message: '' }];

  await axios
    .post(baseURL + publicURL + smsURL + phone)
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [{ status: res.status, message: res.data }];
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response.status === 409)
        message = [
          { status: error.response.status, message: 'Please try later' },
        ];
    });
  return message;
};

//register
export const registerApi = async (
  firstName,
  lastName,
  email,
  password,
  country,
  lang,
  phone,
) => {
  let message = [{ status: '', message: '' }];
  let registerURLTest = '';
  if (!phone) {
    registerURLTest = registerURL;
  } else {
    registerURLTest = registerPhoneURL;
  }
  await axios
    .post(baseURL + clientURL + registerURLTest, {
      fname: firstName,
      lname: lastName,
      email: email,
      password: password,
      country: country,
      lang: lang,
      phone: phone,
    })
    .then((res) => {
      // console.log(res)
      if (res.status === 200) {
        message = [{ status: res.status, message: 'Successfully registered' }];
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response.status === 409)
        message = [
          {
            status: error.response.status,
            message: 'Email address already exists',
          },
        ];
    });
  return message;
};

//login
export const loginApi = async (email, password) => {
  let message = [{ status: '', message: '' }];
  await axios
    .post(baseURL + clientURL + loginURL, {
      username: email,
      password: password,
    })
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [
          { status: res.status, message: 'You are logged in successfully' },
        ];
        //setLocalStorage("token",res.data.token);
        //setLocalStorage("user",res.data);
        setCookie('tokenTrustiT', res.data.token);
        setCookie('userTrustiT', res.data);
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response.status === 404)
        message = [
          {
            status: error.response.status,
            message: 'Email or password is incorrect !',
          },
        ];
    });
  return message;
};

//reset
export const forgetApi = async (email, lang) => {
  let message = [{ status: '', message: '' }];
  await axios
    .post(baseURL + clientURL + forgetURL, {
      email: email,
      lang: lang,
    })
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [
          {
            status: res.status,
            message:
              'Check your inbox, and follow the steps specified in the email to reset your password!',
          },
        ];
      }
    })
    .catch((error) => {
      console.log(error.response);
      message = [
        { status: error.response.status, message: 'Verify email address' },
      ];
    });
  return message;
};

//forget
export const resetApi = async (token, code, password, urlApi) => {
  let message = [{ status: '', message: '' }];
  let newUrl = '';
  if (urlApi === true) {
    newUrl = baseURL + repairerReset;
  } else {
    newUrl = baseURL + clientURL + resetURL;
  }
  await axios
    .post(newUrl + token + '?token=' + code, { password: password })
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [{ status: res.status, message: res.data.message }];
      }
    })
    .catch((error) => {
      console.log(error.response);
      message = [
        { status: error.response.status, message: 'This link has expired' },
      ];
    });
  return message;
};

//contact
export const contactApi = async (
  firstname,
  lastName,
  email,
  phone,
  msgbody,
) => {
  let message = [{ status: '', message: '' }];
  await axios
    .post(baseURL + publicURL + contactURL, {
      nom: firstname + ' ' + lastName,
      phone: phone,
      email: email,
      message: msgbody,
    })
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [
          { status: res.status, message: 'Message sent with success' },
        ];
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response.status === 404)
        message = [
          { status: error.response.status, message: 'Message not sent' },
        ];
    });
  return message;
};

// payment invoice
export const invoicePayment = async (
  tokenUser,
  email,
  fname,
  lname,
  phone,
  PanneName,
  repairCode,
  price,
  onlinePayment,
  clientAddress,
  clientCountryName,
) => {
  let message = [{ status: '', message: '' }];
  await axios
    .post(
      baseURL + clientURL + InvoiceApi,
      {
        clientEmail: email,
        clientName: fname + ' ' + lname,
        clientPhone: phone,
        reparationCode: repairCode,
        reparationLib: PanneName,
        reparationPrice: price,
        onlinePayment: onlinePayment,
        sendEmail: true,
        clientAddress: clientAddress,
        clientCountryName: clientCountryName,
        TVA: 9,
      },
      {
        headers: {
          'x-auth-token': tokenUser,
        },
      },
    )

    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [{ status: res.status, message: res }];
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return message;
};

// payment approuve and check backend
export const backendApiPaymentCheck = async (
  token,
  tokenUser,
  id,
  transactionId,
) => {
  let message = [{ status: '', message: '' }];
  await axios
    .post(
      baseURL + clientURL + backendPaymentCheck,
      {
        token: token,
        id: id,
        payed: true,
        transactionId: transactionId,
      },
      {
        headers: {
          'x-auth-token': tokenUser,
        },
      },
    )

    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [{ status: res.status, message: 'check' }];
      }
    })
    .catch((error) => {
      console.log(error.response);
      /*if(error.response.status===404)
        message=[{"status":error.response.status,"message":"Message non envoyé !"}];
      }*/
    });
  return message;
};

//approve state

export const approveApi = async (id, etat, header) => {
  let message = [];
  await axios
    .post(
      baseURL + approveURL,

      {
        id: id,
        etat: etat,
      },
      {
        headers: {
          'x-auth-token': header,
        },
      },
    )

    .then((res) => {
      console.log('approuve', res);
      if (res.status === 200) {
        if (etat == 'R110') {
          message = [
            {
              status: res.status,
              message: 'Repair approved',
              class: 'text-success',
            },
          ];
        } else if (etat == 'R109') {
          message = [
            {
              status: res.status,
              message: 'Repair refused',
              class: 'text-error',
            },
          ];
        }
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return message;
};

//score
export const scoreApi = async (tokenUser) => {
  let datascore = [];
  await axios
    .get(baseURL + clientURL + scoreURL, {
      headers: {
        'x-auth-token': tokenUser,
      },
    })

    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        datascore = res.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return datascore;
};

//profile
export const profileApi = async (firstName, lastName, phone, token) => {
  let message = [];
  await axios
    .post(
      baseURL + clientURL + profileURL,
      {
        fname: firstName,
        lname: lastName,
        phone: phone,
      },
      {
        headers: {
          'x-auth-token': token,
        },
      },
    )

    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [
          {
            status: res.status,
            message: 'Your data has been updated successfully',
          },
        ];
        setLocalStorage('user', res.data);
      }
    })
    .catch((error) => {
      console.log(error.response);
      message = [
        { status: error.response.status, message: 'Error while updating' },
      ];
    });
  return message;
};

//profile password reset
export const profilePassworApi = async (oldPassword, newPassword, token) => {
  let message = [];
  await axios
    .post(
      baseURL + clientURL + passwordProfileURL,
      {
        password: oldPassword,
        newPassword: newPassword,
      },
      {
        headers: {
          'x-auth-token': token,
        },
      },
    )

    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [
          {
            status: res.status,
            message: 'Your password has been successfully updated',
          },
        ];
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response.status === 500) {
        message = [
          {
            status: error.response.status,
            message: 'Current password incorrect',
          },
        ];
      }
    });
  return message;
};

//picture upload
export const pictureApi = async (file, token) => {
  let message = [];

  await axios
    .post(
      baseURL + clientURL + pictureURL,
      file,

      {
        headers: {
          'x-auth-token': token,
          'content-type': 'multipart/form-data',
        },
      },
    )

    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [{ status: res.status, picture: res.data.picture }];
        //setLocalStorage("user",res.data);
      }
    })
    .catch((error) => {
      console.log(error.response);
      message = [{ status: error.response.status }];
    });
  return message;
};

//note
export const noteApi = async (code, token) => {
  let note = [];

  await axios
    .get(baseURL + publicURL + noteURL + code, {
      headers: {
        'x-auth-token': token,
      },
    })

    .then((res) => {
      console.log(res.data);
      if (res.status === 200) {
        //note.push({"remarqueClient":res.data.remarqueClient,"remarqueTrustiT":res.data.remarqueTrustit});
        note.push(...res.data.remarqueClient, ...res.data.remarqueTrustit);
      }
    })
    .catch((error) => {
      console.log(error.response);
    });

  return note;
};

//payment create
export const paymentCreateApi = async (
  firstName,
  lastName,
  email,
  phone,
  note,
  amount,
) => {
  let message = [];
  await axios
    .post(
      createPaymentUrl,

      {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: phone,
        note: note,
        amount: amount,
        vendor: process.env.RAZZLE_PAYME_ACCOUNT_NUMBER,
      },
      {
        headers: {
          Authorization: `Token ${process.env.RAZZLE_PAYMEE_TOKEN}`,
        },
      },
    )
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [{ status: res.status, token: res.data.data.token }];
      }
    })
    .catch((error) => {
      console.log(error.response);
      //message=[{"status":res.status}];
      //message=[{"status":error.status,"message":"Please try later"}];
    });
  return message;
};

//payment check
export const paymentCheckApi = async (token) => {
  let message = [];
  await axios
    .get(publicPaymentUrl + token + '/check', {
      headers: {
        Authorization: `Token ${process.env.RAZZLE_PAYMEE_TOKEN}`,
      },
    })
    .then((res) => {
      // console.log(res)
      if (res.status === 200) {
        //console.log("testou",token)
        message = [{ status: res.status, message: res.data.data }];
      }
    })
    .catch((error) => {
      // console.log(error.response);
      //message=[{"status":res.status}];
      //message=[{"status":error.status,"message":"Please try later"}];
    });
  return message;
};

//post note
export const postNoteApi = async (id, remarque, token) => {
  let message = [];
  await axios
    .post(
      baseURL + publicURL + postNoteURL + id,

      {
        remarque: remarque,
      },
      {
        headers: {
          'x-auth-token': token,
        },
      },
    )

    .then((res) => {
      console.log(res.data);
      if (res.status === 200) {
        message = [{ status: res.status, message: 'Note sent with success!' }];
      }
    })
    .catch((error) => {
      console.log(error.response);
      //message=[{"status":res.status}];
      message = [{ status: error.status, message: 'Please try later' }];
    });
  return message;
};

//repairList
export const repairApi = async (tokenUser) => {
  let datarepair = [];
  await axios
    .get(baseURL + clientURL + repairURL, {
      headers: {
        'x-auth-token': tokenUser,
      },
    })

    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        datarepair = res.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return datarepair;
};

//blog count

export const blogCount = async (countryId) => {
  let count = 0;
  await axios
    .get(
      baseURL + publicURL + blogCountUrl + '?country=632ad7fe45bdfb0943ecc1c3',
    )

    .then((res) => {
      //console.log(res)
      if (res.status === 200) {
        count = res.data.totalCount;
      }
    })
    .catch((error) => {
      // console.log(error);
    });
  return count;
};

//blogList
export const blogApi = async (nbCard, nbPage, countryId) => {
  let blogList = [];
  await axios
    .get(baseURL + publicURL + blogURL, {
      params: {
        count: nbCard,
        page: nbPage,
        country: countryId,
      },
    })

    .then((res) => {
      // console.log(res)
      if (res.status === 200) {
        blogList = res.data;
      }
    })
    .catch((error) => {
      //console.log(error.response);
    });
  return blogList;
};

//blog info

export const blogInfoApi = async (slug) => {
  let blogInfo = [];
  await axios
    .get(baseURL + publicURL + bloginfoURL + slug)

    .then((res) => {
      console.log('bloginfo', res);
      if (res.status === 200) {
        blogInfo = res.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return blogInfo;
};

//b2b api

export const b2bApi = async (phone, lang) => {
  let message = [];
  await axios
    .post(baseURL + publicURL + callbackURL, {
      phone: phone,
      lang: lang,
    })
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [{ status: res.status, message: 'Successfully sent' }];
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response.status === 404)
        message = [
          {
            status: error.response.status,
            message: error.response.data.message,
          },
        ];
    });
  return message;
};

//callback api

export const callbackApi = async (phone, lang) => {
  let message = [];
  await axios
    .post(baseURL + publicURL + callbackURL, {
      numberPhone: phone,
      lang: lang,
    })
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [{ status: res.status, message: 'Successfully sent' }];
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response.status === 404)
        message = [
          { status: error.response.status, message: 'Please try later' },
        ];
    });
  return message;
};

//codeSearch
export const codeSearchApi = async (codeInput) => {
  let message = [];
  await axios
    .get(baseURL + publicURL + codeURL + codeInput)

    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        message = [{ status: res.status, message: res.data }];
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response.status === 404)
        message = [
          {
            status: error.response.status,
            message: error.response.data.message,
          },
        ];
    });
  return message;
};

//DeviceApi
export const deviceApi = async (country_id) => {
  let device = [];
  await axios
    .get(baseURL + publicURL + deviceURL + '?country=' + country_id)

    .then((res) => {
      //console.log(res.data)
      if (res.status === 200) {
        device = res.data.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return device;
};

// country api

export const countryApi = async () => {
  let country = [];
  await axios
    .get(baseURL + publicURL + countryURL)
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        country = res.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return country;
};

//region api

export const regionApi = async () => {
  let region = [];
  await axios
    .get(baseURL + publicURL + regionURL)
    .then((res) => {
      if (res.status === 200) {
        region = res.data.data;
      }
    })
    .catch((error) => {
      //console.log(error.response);
    });
  return region;
};

//city api

export const cityApi = async (idRegion) => {
  let city = [];
  await axios
    .get(baseURL + publicURL + cityURL + idRegion)
    .then((res) => {
      if (res.status === 200) {
        city = res.data;
      }
    })
    .catch((error) => {
      //console.log(error.response);
    });
  return city;
};

//brandApi
export const brandApi = async (id, country_id) => {
  let brand = [];
  await axios
    .get(baseURL + publicURL + brandURL + id + '?country=' + country_id)

    .then((res) => {
      //console.log(res.data)
      if (res.status === 200) {
        brand = res.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return brand;
};

//gammbeApi
export const gammeApi = async (id_device, id_brand, country_id) => {
  let brand = [];
  await axios
    .get(baseURL + publicURL + gammeURL, {
      params: {
        device: id_device,
        brand: id_brand,
        country: country_id,
      },
    })

    .then((res) => {
      //console.log("gamme ",res.data)
      if (res.status === 200) {
        brand = res.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return brand;
};

//modelApi
export const modelApi = async (id_device, id_brand, id_gamme, country_id) => {
  let brand = [];
  await axios
    .get(baseURL + publicURL + modelURL, {
      params: {
        device: id_device,
        brand: id_brand,
        gamme: id_gamme,
        country: country_id,
      },
    })

    .then((res) => {
      // console.log(res.data)
      if (res.status === 200) {
        brand = res.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return brand;
};

//product repair Api
export const productRepairApi = async (
  id_device,
  id_brand,
  id_gamme,
  id_model,
  id_country,
) => {
  let product = [];
  await axios
    .get(baseURL + publicURL + panneURL, {
      params: {
        device: id_device,
        brand: id_brand,
        gamme: id_gamme,
        model: id_model,
        country: id_country,
      },
    })

    .then((res) => {
      console.log(res.data);
      if (res.status === 200) {
        product = res.data;
      }
    })
    .catch((error) => {
      //console.log(error.response);
    });
  return product;
};

//categoryApi
export const categoryApi = async () => {
  let category = [];
  await axios
    .get(baseURL + publicURL + categoryURL)

    .then((res) => {
      // console.log("data"+res)
      if (res.status === 200) {
        category = res.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return category;
};

//categoryChildApi
export const categoryChildApi = async (id) => {
  let categoryChild = [];
  await axios
    .get(baseURL + publicURL + categoryChildrenURL + id)

    .then((res) => {
      // console.log("data"+res)
      if (res.status === 200) {
        categoryChild = res.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return categoryChild;
};

export const categorySearchApi = async (name) => {
  let categorysSearch = [];
  await axios
    .get(baseURL + publicURL + categorySearchURL + '?name=' + name)

    .then((res) => {
      // console.log("data"+res)
      if (res.status === 200) {
        categorysSearch = res.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return categorysSearch;
};

//gouvernorat list
export const gouvernoratApi = async () => {
  let gouvernoratList = [];
  await axios
    .get(baseURL + publicURL + locationURL + gouvernoratURL)

    .then((res) => {
      // console.log("data"+res)
      if (res.status === 200) {
        gouvernoratList = res.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return gouvernoratList;
};

//délégation list
export const delegationApi = async (gouvernorat) => {
  let delegationList = [];
  await axios
    .get(baseURL + publicURL + locationURL + delegationURL + gouvernorat)

    .then((res) => {
      // console.log("data"+res)
      if (res.status === 200) {
        delegationList = res.data;
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return delegationList;
};

//boutique list
export const boutiqueApi = async (country) => {
  let boutiqueList = [];
  await axios
    .get(baseURL + publicURL + boutiqueURL, {
      params: {
        country: country,
      },
    })
    .then((res) => {
      // console.log(res)
      if (res.status === 200) {
        for (let data in res.data) {
          boutiqueList.push({
            value: res.data[data]._id,
            label: res.data[data].name,
          });
        }
      }
    })
    .catch((error) => {
      console.log(error.response);
    });
  return boutiqueList;
};

export const newreparationApi = async (
  email,
  nom,
  prenom,
  phone,
  couleurAppareil,
  imei,
  detailPanne,
  centreDepot,
  centreRecuperation,
  categoruId,
  hasAccount,
  coupon,
  recupHome,
  country,
  typeDepot,
) => {
  let message = [];
  await axios
    .post(baseURL + publicURL + reparationURL, {
      email: email,
      nom: nom,
      prenom: prenom,
      phone: phone,
      couleurAppareil: couleurAppareil,
      numeroSerie: imei,
      detailPanne: detailPanne,
      centreDepot: centreDepot,
      centreRecuperation: centreRecuperation,
      reparationProduct: categoruId,
      hasAccount: hasAccount,
      coupon: coupon,
      //extraData:{adress:{localite:recupHome}},
      country: country,
      recupHome: recupHome,
      typeDepot: typeDepot,
    })

    .then((res) => {
      // console.log(res)
      if (res.status === 200) {
        message = [{ status: res.status, code: res.data.reparation.code }];
      }
    })
    .catch((error) => {
      // console.log(error.response);
      message = [
        { status: error.response.status, message: error.response.data.message },
      ];
    });
  return message;
};

//pack and devis api

export const packdApi = async (
  firstName,
  lastName,
  email,
  position,
  autreEmail,
  nomEntreprise,
  identifientUnique,
  besoin,
  typeDevis,
  phone,
  cupon,
  detailsBesoin,
  country,
) => {
  let message = [{ status: '', message: '' }];
  await axios
    .post(baseURL + publicURL + packdURL, {
      nom: firstName,
      prenom: lastName,
      email: email,
      position: position,
      autreEmail: autreEmail,
      nomEntreprise: nomEntreprise,
      identifientUnique: identifientUnique,
      besoin: besoin,
      typeDevis: typeDevis,
      phone: phone,
      cupon: cupon,
      detailsBesoin: detailsBesoin,
      country: country,
    })
    .then((res) => {
      // console.log(res)
      if (res.status === 200) {
        message = [{ status: res.status, message: 'Successfully sent' }];
      }
    })
    .catch((error) => {
      if (error.response.status != 200) console.log(error.response);

      message = [
        { status: error.response.status, message: 'Please try later' },
      ];
    });
  return message;
};

//subscribe

export const subscribeApi = async (email, lang) => {
  let message = [{ status: '', message: '' }];
  await axios
    .post(baseURL + publicURL + subscribeURL, {
      email: email,
      lang: lang,
    })
    .then((res) => {
      // console.log(res)
      if (res.status === 200) {
        message = [{ status: res.status, message: 'Successfully subscribed' }];
      }
    })
    .catch((error) => {
      if (error.response.status === 409) console.log(error.response);

      message = [
        {
          status: error.response.status,
          message: 'You have been already subscribed',
        },
      ];
    });
  return message;
};

//googe login

export const googleLogin = async (credinal, client_id, country, lang) => {
  let message = [{ status: '', message: '' }];
  await axios
    .post(baseURL + clientURL + googleURL, {
      idgoogle: credinal,
      GOOGLE_CLIENT: client_id,
      country: country,
      lang: lang,
    })
    .then((res) => {
      console.log('test', res.data);
      if (res.status === 200) {
        message = [
          { status: res.status, message: 'You are logged in successfully' },
        ];
        //setLocalStorage("token",res.data.token);
        //setLocalStorage("user",res.data);
        setCookie('tokenTrustiT', res.data.token);
        setCookie('userTrustiT', res.data);
      }
    })
    .catch((error) => {
      console.log(error.response);
      message = [{ status: error.status, message: 'Please try later' }];
    });
  return message;
};

// country detection

export const countryDetection = async () => {
  let data = [];
  await axios
    .get(detectionURL)

    .then((res) => {
      // console.log(res)
      if (res.status === 200) {
        data = res.data;
      }
    })
    .catch((error) => {
      //console.log("error detection",error);
    });
  return data;
};
