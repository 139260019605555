import React from "react";
import Select from 'react-select';

function SelectSearch(props){

return(
	<Select isSearchable={props.isSearchable} components={props.components} styles={props.customStyles}
	className={"select-search"} name={props.name} onChange={props.change} value={props.value} placeholder={props.placeholder} options={props.option}/>
);
}
export default SelectSearch;