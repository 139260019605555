import React from "react";
import {Link} from "react-router-dom";

function CodeGenerated(props){

  // reload page 
    const reloadPage=()=>{
        window.location.replace(document.location.origin);
      }

      // go back to dashboard
      const goToDashboard=()=>{
        window.location.replace(document.location.origin+`/${props.lang}/dashboard/reparation`);
      }

    return(
        
        <div className="code-card card card-box" style={{cursor:"default"}}>
        <div className="card-body">
          <h5 className="card-title">{props.translate('step.YOUR TRACKING CODE IS')}<h3 className="mt-1 btn-primary" style={{cursor:"default !important",display:"inline-block",verticalAlign:"middle"}}>{props.code}</h3></h5>
          <p className="card-text">
          <p><span style={{color:"red"}}>{props.translate('step.Your code is only valid for 72 hours from registration')}</span>{props.translate('step.after this date your code will be archived and if you are still interested, you can contact us directly by phone when depositing your device and your code will be activated again‍‍‍')}</p>           
            <p>{props.translate('step.Our delivery will contact you for the recovery process of your device')}</p>
            <div className="code-generated row mt-2">
             
             
            <div className="col">
              
            <Link className="btn btn-primary" to="" onClick={goToDashboard}>{props.translate('public.My profile')}</Link>
            <Link className="btn btn-primary" onClick={reloadPage} to="">{props.translate('public.BackCode')}</Link>
           </div>
            </div>

          </p>
        </div>
        

      </div>
    );
}

export default CodeGenerated;