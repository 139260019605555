import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import p1 from "../assets/image/partner/p1.png";
import p2 from "../assets/image/partner/p2.png";
import p3 from "../assets/image/partner/p3.png";
import p4 from "../assets/image/partner/p4.png";
import p5 from "../assets/image/partner/p5.png";

import w1 from "../assets/image/worked-withus/w1s.svg";
import w2 from "../assets/image/worked-withus/w2s.svg";
import w3 from "../assets/image/worked-withus/w3s.svg";
import w4 from "../assets/image/worked-withus/w4s.svg";
import w5 from "../assets/image/worked-withus/w5s.svg";


const settings = {
    dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 2000,
      cssEase: "linear",

  };

  


function SliderBottom(props){
  let images=[];
  {props.changeImage?
   images=[

    {path:w1,title:"Tunis business school"},
    {path:w2,title:"Mass analytics"},
    {path:w3,title:"The next women tunisie"},
    {path:w4,title:"GOMYCODE"},
    {path:w5,title:"SAGEMCOM"}

  ]
  :
  images=[

    {path:p1,title:"TrustiT service"},
    {path:p2,title:"Flat 6 Labs"},
    {path:p3,title:"Aurax"},
    {path:p4,title:"Aramex"},
    {path:p5,title:"Pixelj"}

  ]
}


    return(
        <>
        {!props.hide?
        <div className="row">
        <div className="col-lg-12 text-center justify-content-center mt-5">
        <h4>{props.translate('offre.They trust us')}</h4>
        </div>
        </div>
        :""}

        <div style={{width:"90%",margin:"auto"}} className="slider-partner mt-5 mb-5">

        <Slider  {...settings}>

        {images.map((item, i) => (
          <div className="mb-3 slider-image" key={i}>
            <img style={{transform:props.changeImage ?"scale(2.5,2.5)":"",width:"125px",height:"70px",margin:'auto'}} src={item.path} alt={item.title}/>
          </div>
        ))}
        
        </Slider>
        
        </div>
       
        </>

    );
}

export default SliderBottom;