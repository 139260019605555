import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

function SpinnerCirculer(props){

    // spinner style 
    const override= {
        textAlign:"center",
        marginLeft:-5,
        marginRight:5,
        verticalAlign:"text-bottom"
       };

    return(
        <ClipLoader 
        color="white"
        size={20}
        cssOverride={override}
        />
    );
}
export default SpinnerCirculer;