import React from "react";
import StaticCard from "../../components/StaticCard";
import { useTranslation } from 'react-i18next';
import SEO from "../../components/SEO";
import logo from "../../assets/image/logo/logoNav.svg";
import leftImg from "../../assets/image/static-page/whyus.svg";

function OurMission(){

    const {t} = useTranslation("common");
    const name=process.env.RAZZLE_REACT_APP_NAME+" ";

    const missionCard=[
        {"text1":"is","text2":"the 1st network","text":"is the 1st network of trusted repairers","img":true,name:true},
        {"text":"is a service company for the general public using electronic devices that ensures an optimal repair experience thanks to its large network of trusted repairers"},
        {"text":"provides you with approved service centers to accept and diagnose your electronic devices free of charge and ensure that they are repaired with finesse, speed and confidentiality"},
        {"text":"allows you to deposit your repair online from a hundred choices while choosing the deposit service center and the recovery service center closest to you"},
        {"text":"allows you to receive alerts by email and follow the progress of your repair in real time without having to move with a unique tracking code"},
        {"text":"gives you the opportunity to report a service center you had a bad experience with so that we can take action to protect you in the future"},

    ]

    const whyUsData=[
        {"icon":"proximity-icon","text":"PROXIMITY AND TRUST","text2":"Our network of trust covers all the territories. Your Device and your data are in safe hands"},
        {"icon":"alert-icon","text":"FOLLOW-UP & SUPPORT","text2":"Track the status of your repair in real time with your unique code. You will be notified when your device is ready"},
        {"icon":"expertise-icon","text":"TECHNICAL EXPERTISE","text2":"Thanks to the combined expertise of our network, your device has every chance of being repaired with the best methods and parts available!"},
        {"icon":"relation-icon","text":"CUSTOMER RELATIONSHIP","text2":"You are our first partner! Your opinion is important to help us have the best network of repairers"}

    ]
    
    const textReturn=()=>{
        return(
    <>  
    <div className="mission-row">
     <div className="block-top row text-center justify-content-center">
        <p><span>{name}</span> {t('ourMission.is a service company for the general public using electronic devices that ensures an optimal repair experience thanks to its large network of trusted repairers')}</p>
     </div>

     <div className="block-nbclient row text-center justify-content-center mt-4">
        <p><span className="d-block">+70</span><span className="d-block">{t('ourMission.Stores')}</span></p>
        <p><span className="d-block">+8000</span><span className="d-block">{t('ourMission.Customers')}</span></p>
        <p><span className="d-block">+200</span><span className="d-block">{t('ourMission.Companies')}</span></p>

     </div>

        <div className="mt-4 card-deck">
                {missionCard.map((missionCards,index)=>
              <div key={index} className="col-md-6 col-lg-4 col-sm-12 col-xs-6">
          <div className="card mission-card">
            <div className="card-body">
              <h5 className="card-title">
                {missionCards.img?
                <div className="row justify-content-center mb-3 row-img">
                <img width="180px" src={logo} alt=""/>
                </div>
                :""}
                {!missionCards.name?
                <span>{name}</span>
                :""}
                <span>{missionCards.text1 ?t('ourMission.'+missionCards.text1):""} <span style={{color:"#35A0EE",fontWeight:"bold"}}>{missionCards.text2? t('ourMission.'+missionCards.text2) :""}</span> {t('ourMission.'+missionCards.text)}</span></h5>
            </div>
          </div>
          </div>
        )}

        </div>

        <div className="row row-whyus mt-5">
            <div className="col-lg-6">
            <img src={leftImg}/>
            </div>
            <div className="col-lg-6">
            <h2 className="mt-5 mb-5"><strong>{t('ourMission.Why',{name:process.env.RAZZLE_REACT_APP_NAME})}</strong></h2>

            <div className="row">
            {whyUsData.map((whyUsDatas,index)=>
            <div key={index} className="col-lg-6 mb-4">
                <div >
            <i className={"mission-logo"+" "+whyUsDatas.icon}></i>
            </div>
            <div>
            <p><strong>{t('ourMission.'+whyUsDatas.text)}</strong></p>
            <p>{t('ourMission.'+whyUsDatas.text2)}</p>
            </div>
        </div>
            )}
            
    </div>

            </div>
        </div>

     </div>
 </>
        );

    }
    

    return(
        <>
        <SEO 
        title={t('seoContent.title mission',{name:process.env.RAZZLE_REACT_APP_NAME})}
        description={t('seoContent.description mission')}
        keywords={t('seoContent.keyword mission')}
           />
        <h1 className="text-header">{t('SeoBody.mission h1',{name:process.env.RAZZLE_REACT_APP_NAME})}</h1>
        <StaticCard title="Our mission"
        text={textReturn()}/>
        </>
    );
}
export default OurMission;