const userReducer = (state = [] , action) => {

    switch(action.type) {
        case 'FETCH_USER':
            return {...state,userData:action.payload.user}

        default:
            return state;
    }
}

export default userReducer