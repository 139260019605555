import React from "react";
import { useSelector } from "react-redux";
import { getFromLocalStorage } from "../../utils/auth";
import { detailsWebsite } from "../../utils/repairState";

function ContactAdress(props){

    const detection= useSelector((state) => state.countryDetected)

    return(
        <div className="mb-2 row mt-5" id={props.name}>
        {detection && detection.detectionData && detection.detectionData.code!="SA"?
        <>
        <div className="col-lg-3 col-md-6 col-xs-6 col-sm-6 mb-5">
        <div className="block-contact">

        <p className="ml-4 mb-1 d-block">{props.translate('contact.Commercial service')}</p>
        <span className="d-block"><i className="contact-logo phone-logo"></i><span><a className="remove-link" href="tel:99 959 797">99 959 797</a> / <a className="remove-link" href="tel:29 772 776">29 772 776</a></span>
        </span>
        <span className="d-block"><i className="contact-logo email-logo"></i><a className="remove-link" href="mailto:commercial@trustit.tn">commercial@trustit.tn</a>
        </span>

        </div>

        <div className="block-contact">
        <p className="mt-4 ml-4 mb-1 d-block" href="#">{props.translate('contact.Business service')}</p>
        <span className="d-block"><i className="contact-logo phone-logo"></i><span><a className="remove-link" href="tel:25 888 874">25 888 874</a> / <a href="tel:29 772 776" className="remove-link">29 772 776</a></span></span>
        <span className="d-block"><i className="contact-logo email-logo"></i><a className="remove-link" href="mailto:sales@trustit.tn">sales@trustit.tn</a></span>

        </div>

        <div className="block-contact">
        <p className="mt-4 ml-4 mb-1 d-block" href="#">{props.translate('contact.Customer service')}</p>
        <span className="d-block"><i className="contact-logo phone-logo"></i><span><a className="remove-link" href="tel:27 000 140">27 000 140</a> / <a className="remove-link" href="tel:23 588 288">23 588 288</a></span></span>
        <span className="d-block"><i className="contact-logo email-logo"></i><a className="remove-link" href="mailto:contact@trustit.tn">contact@trustit.tn</a></span>

        </div>


        </div>

        <div className="col-lg-3 col-md-6 col-xs-6 col-sm-6 mb-5">
        <div className="block-contact">
        <p  className="ml-4 mb-1 d-block" href="#">{props.translate('contact.Reclamation service')}</p>
        <span className="d-block"><i className="contact-logo phone-logo"></i><span><a className="remove-link" href="tel:24 077 799">24 077 799</a></span></span>
        <span className="d-block"><i className="contact-logo email-logo"></i><a className="remove-link" href="mailto:support@trustit.tn">support@trustit.tn</a></span>

        </div>

        <div className="block-contact">

        <p className="mt-4 ml-4 mb-1 d-block" href="#">{props.translate('contact.Technical service')}</p>
        <span className="d-block"><i className="contact-logo phone-logo"></i><span><a className="remove-link" href="tel:20 809 992">20 809 992</a></span></span>
        <span className="d-block"><i className="contact-logo email-logo"></i><a className="remove-link" href="mailto:support@trustit.tn">support@trustit.tn</a></span>

        </div>

        <div className="block-contact">
        <p className="mt-4 ml-4 mb-1 d-block" href="#">{props.translate('contact.Administrative/Financial service')}</p>
        <span className="d-block"><i className="contact-logo phone-logo"></i><span><a className="remove-link" href="tel:29 530 222">29 530 222</a></span></span>
        <span className="d-block"><i className="contact-logo email-logo"></i><a className="remove-link" href="mailto:finance@trustit.tn">finance@trustit.tn</a></span>

        </div>



        </div>
        </>
        :
        <div className="col-lg-6 col-md-6 col-xs-6 col-sm-6 mb-5">
        <div className="block-contact">

        <p className="ml-4 mb-1 d-block">{props.translate('contact.Commercial service')}</p>
        <span className="d-block"><i className="contact-logo phone-logo"></i><span><a className="remove-link" href="tel:+966 0539379573">+966 0539379573</a></span>
        </span>
        <span className="d-block"><i className="contact-logo email-logo"></i><a className="remove-link" href="mailto:contact@trustit.app">contact@trustit.app</a>
        </span>

        </div>
        </div>
        }
        <div className="col-lg-6">
        <iframe src=
        {detection && detection.detectionData.code!="SA"?
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51084.040391434915!2d10.156956929592832!3d36.84840254478863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd35fa41b73cf3%3A0x40651c0b73193dec!2sTrustiT!5e0!3m2!1sen!2stn!4v1654004200903!5m2!1sen!2stn" 
        :"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3715.0595489696266!2d39.7033424!3d21.387559799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c21764b375d91d%3A0xad68565a8d1b75f3!2sMakkah%20Chamber%20of%20Commerce!5e0!3m2!1sen!2stn!4v1668676022242!5m2!1sen!2stn"}
        width="500" height="250" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        <div className="row">

            <div className="map-adress mt-2 col">
        <span className="d-block"><i className="align-text-top contact-logo position-logo"></i> {props.translate('public.Address')} : {props.translate("contact."+detailsWebsite(detection,"","","address"))}</span>

        </div>
        </div>
        </div>
        </div>
    );
}

export default ContactAdress;