import { Steps} from 'rsuite';
import React from "react";


function StepperTbas (props){

  const styles = {
    verticalAlign: 'top',

  };

    let steperItem=[];

    // set the number of the step for the steper
    if(!props.typePayment){
      steperItem=[...Array(2)]
    }

    else {
      steperItem=[...Array(2)]
    }

    return(
        <>
        <Steps className="steper-payment" current={props.stepCurrentVal} style={styles}>
          
          {steperItem.map((steperItems,index)=>
             <Steps.Item  key={index}/>
        )}
          
        </Steps>

      </>
    );
}

export default StepperTbas;