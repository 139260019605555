import { components } from "react-select";
import { toast } from "react-toastify";
import { getFromLocalStorage } from "./auth";
import { isValidPhoneNumber } from 'react-phone-number-input'
import React from "react"
import { parsePhoneNumber } from "react-phone-number-input";

// handle reparation statut logic by code 
export const handleEtat=(code)=>{
    let codetext=""
    switch (code){
        case "R100":
        codetext="New repair";
        break;
        case "R101":
        codetext="Dropped repair";
        break;
        case "R105":
        codetext="Under diagnostic";
        break;
        case "R108T":
        codetext="Diagnostic completed";
        break;
        case "R109":
        codetext="Repair refused";
        break;
        case "R110":
        codetext="Repair approved";
        break;
        case "R111":
        codetext="Repair in progress";
        break;
        case "R112":
        codetext="Repair completed";
        break;
        case "R113":
        codetext="Ready for pick up";
        break;
        case "R114":
        codetext="Repair picked";
        break;
        case "R116":
        codetext="Repair picked";
        break;
        default:
         codetext="Under progress";
    }
 
    return codetext;

}

export const handleTranName=(resolvedlang,data,fromRepair,fromMobile)=>{
    let name="";
    if(resolvedlang==="en"){
        if(!fromRepair && !fromMobile){
        name=data.name
        }
        else if(fromRepair && !fromMobile) {
        name="ReparationProduct.typeReparation.name"

        }
        else {
          name=data["ReparationProduct"]["typeReparation"]["name"]
        }
    }
    else if(resolvedlang==="fr"){
        if(!fromRepair && !fromMobile){
            name=data.nameFrench;

        }
        else if(fromRepair && !fromMobile) {
          name="ReparationProduct.typeReparation.nameFrench"
  
          }
          else {
            name=data["ReparationProduct"]["typeReparation"]["nameFrench"]
          }
    }
    else if(resolvedlang==="ar"){
        if(!fromRepair && !fromMobile){
            name=data.nameArabe;

        }
        else if(fromRepair && !fromMobile) {
          name="ReparationProduct.typeReparation.nameArabe"
  
          }
          else {
            name=data["ReparationProduct"]["typeReparation"]["nameArabe"]
          }
}
    return name;
}


// filter curency bassed on country id 

export const filterCurency=(country,rowVal)=>{
    let currency="";
    let newRowVal="";
    if(rowVal.hasOwnProperty('country')){
        newRowVal=rowVal.country._id;
    }
    else if(rowVal.centreDepot.hasOwnProperty('country')){
        newRowVal=rowVal.centreDepot.country;
    }
    else if(rowVal.centreRecuperation.hasOwnProperty('country')){
        newRowVal=rowVal.centreRecuperation.country;

    }
    let newArray=country.filter(countrys=>countrys.value==newRowVal);
    if(newArray.length>0 && newArray[0].hasOwnProperty('currency')){
        currency=newArray[0].currency;
    }
    console.log("currency",currency)
 
    return currency;

}
const { Option } = components;

// set country icon in react select

export const countryIcon=(lang)=>{
 return  props => (
    <Option {...props}>
      <img
        src={process.env.RAZZLE_REACT_APP_BASE_URL+ props.data.icon}
        style={{ width: "24px",marginRight:"8px",marginLeft:lang=="ar"?"8px":"" }}
        alt=""
      />
      {props.data.label}
    </Option>
  );
 }

 // set custom style for react select 

 export const customStyles=()=>{
    const customStyles = {
        control: (base) => ({
          ...base,
          borderRadius:"12px",
          
        }),
        
        };
        return customStyles;
 }
  
// show different website data like phone,email, address based on country

export const detailsWebsite=(country,phone,email,address)=>{
    let text="";
    const countryCode=country && country.detectionData?country.detectionData.code:"";
        if(phone && countryCode==="TN")
        text=process.env.RAZZLE_REACT_APP_NB_TN;
        else if(phone && countryCode!="TN")
        text=process.env.RAZZLE_REACT_APP_NB_KSA;
        if(email && countryCode==="TN")
        text=process.env.RAZZLE_REACT_APP_CONTACT_TN;
        else if(email && countryCode!="TN")
        text=process.env.RAZZLE_REACT_APP_CONTACT_KSA;
        if(address && countryCode=="TN"){
         text=process.env.RAZZLE_REACT_APP_ADDRESS_TN;
        }
        else if(address && countryCode!="TN"){
            text=process.env.RAZZLE_REACT_APP_ADDRESS_KSA;
        }

    return text;
}

// clean the phone number that contain country code and remove it 

export const removeCountryCodeFromPhone=(phone)=>{
  const phoneNumber = parsePhoneNumber(phone)
  if(phoneNumber)
  return phoneNumber.nationalNumber
  
}

// change handler for phone to detect if phone valid or not 

export const handlePhoneChange=(e,setInputValue,setPhoneEtat)=>{  
    setInputValue("phone", e)
    if(isValidPhoneNumber(String(e))){
      setPhoneEtat(true)
    }
    else {
      setPhoneEtat(false)
    }
    
  }

  
  export const handleCountryNamePayment=()=>{
    let country="test";
    return country
  

  }

  // get price for old and new data for repair list 

  export const handlePrice=(cell,row,country,translate)=>{
    let price="";
    const etat=row.etat;
    const etatCode=etat.substr(1,3);

    if(row.prix && row.prix >0 && !row.hasOwnProperty('ReparationProduct')){
      price=row.prix+" "+filterCurency(country,row)
    }
    else if(row.prix && row.prix >0 && etat=="R108T"){
      price=row.prix+" "+filterCurency(country,row)

    }

    else if(cell && cell >0 && Number(etatCode)<=108){
      price=cell+" "+filterCurency(country,row)
    }
 

    else if(row.prix && row.prix >0 && Number(etatCode)>108 && etatCode<=116) {
      price=row.prix+" "+filterCurency(country,row)
    }
   
    else {
      price=translate("repair.Price not available");
    }
    return price;

  }

  // translate domicile text 

  export const domicileTranslate=(text,translate)=>{
    let newText=text;
    if(text=="domicile")
    newText=translate("public.domicile");
    return newText
  }

  export const translateCurency=(categorys,lang)=>{
    let currency="";
    if(categorys.hasOwnProperty("country")){
      const currencyObject=categorys.country.currency;
      if(lang=="fr"){
        currency=currencyObject.abreviationFrancais
      }
      else if(lang=="ar"){
        currency=currencyObject.abreviationArabe
      }
      else {
      currency=currencyObject.abreviationEnglish;
      }
    }

    return currency;

  }
  
  // dialog alert callback
export const notifyError = (text) => toast.error(text);
export const notifySuccess = (text) => toast.success(text);
