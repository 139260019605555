import { useDeferredValue } from 'react';
import Cookies from 'universal-cookie';
import { createStore } from '../store';

const cookies = new Cookies();
//add to localstorage
export const setLocalStorage = (key, value) => {
    if (typeof window !== "undefiend") {
        window.localStorage.setItem(key, JSON.stringify(value));
    }
  };

  //add to cookie

  export const setCookie = (key, value) => {
      const date=new Date();
      cookies.set(key, value,{path: '/',expires:new Date(new Date().setFullYear(new Date().getFullYear() + 2))});
    
  };

  // remove from localstorage
export const removeLocalStorage = (key) => {
    if (typeof window !== "undefined") {
            localStorage.removeItem(key);

    }
  };

    // remove from cookie
export const removeCookie = (key) => {
      cookies.remove(key, {path: "/"})

  
};


  export const getFromLocalStorage = (key) => {
    if (typeof  window !== "undefined") {
      return JSON.parse(localStorage.getItem(key));
    }
  };

  export const getFromCookie = (key) => {
      return cookies.get(key);
    
  };


// Access user info from localstorage
export const isAuth = (user) => {
  

      if (user) {
        return true
        } else {
          return false;
        }
    
  };
  
  // Sign out
  export const signout = () => {
    //removeLocalStorage("token");
    //removeLocalStorage("user");
    removeCookie("tokenTrustiT");
    removeCookie("userTrustiT");
  };

export const redirectUtil=(req)=>{
  const cookies = new Cookies(req.headers.cookie);
  if(cookies.get("tokenTrustiT") && cookies.get("userTrustiT"))
  return true;
  else 
  return false
}
