import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.on('languageChanged', (codeLang) => {
 
  /*if (typeof window !== 'undefined') {
  const supportedLngs=["fr","ar",'en']
  let lang=window.location.pathname.split("/")[1];
  let code=codeLang;



  if(!supportedLngs.includes(`${lang}`)){
    lang=""
  }
  
    if(code.indexOf("-")>-1){
      let index=code.indexOf("-")
      let newLang=code.substring(0,index);
      code=newLang
      }

      let url =window.location.pathname;
      if(code!=lang){
      if(lang && code!="en"){
      url=url.replace(`/${lang}`, `/${code}`)
      window.location.replace(url);
      }
      else if(lang && code==="en"){
        url=url.replace(`/${lang}`, ``)
        window.location.replace(url);

      }
      
      else if(!lang && code!="en") {
        url = [`/${code}`,url].join('');
        console.log(url)
        window.location.replace(url);
      }
      
    }}*/
    
});


const data=
  {
    order: ['path','cookie','navigator'],
    lookupCookie: 'i18nextLng',
    lookupHeader: 'accept-language',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    caches: [],
    cookieOptions: { path: '/'},
  
};
const options = {
  detection:data,
  fallbackLng: 'en',
  supportedLngs: ['en','fr','ar'],
  load: 'languageOnly', 
  ns: ['common',"input"],
  defaultNS: 'common',
  saveMissing: true,
  debug: false,
  
  caches: [],

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: (value, format, lng) => {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
  },
  useSuspense: process && !process.release,

};

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
  i18n.use(Backend).use(initReactI18next).use(LanguageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;
