import React from "react";
import imgdevis from "../../assets/image/contact-devis/image-devis.png";
import { useTranslation } from 'react-i18next';
import PackForm from "../../components/PackForm";
import packfr from "../../assets/image/static-page/pack-fr.png";
import packen from "../../assets/image/static-page/pack-en.png";

function PackDevis() {

    const {t,i18n} = useTranslation("common");

    return(
    <div className="inscrption-container container form-pack">
        <div className="row">
        <div className="col-lg-5 mb-5">
            <figure>
            <img style={{width:"100%"}} src={i18n.resolvedLanguage==="fr" ?packfr :packen} alt={t('public.TrustiT Pack')}/>
        </figure>
         <div className="col-lg-12 btn-class">
         <h4>{t('devis.Choosing is',{name:'TrustiT'})}</h4>
            </div>
            
        <div className="col-lg-12 btn-class">
            <span>{t('devis.The pack is a set of turnkey services, intended for all types of companies, adapted to all budgets and composed of 3 levels according to the needs of each client.')}</span>
        </div>
        </div>

        <div className="col-lg-7">
        <PackForm translate={t}/>
        </div>


        </div>
        </div>


    );

}

export default PackDevis;