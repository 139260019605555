import {blogInfoApi} from "../../api/axios"
const fetchBlogSlugSuccess = blogSlug => ({
    type: 'FETCH_ALL_BLOG_SLUG',
    payload: { blogSlug }
})


// fetch blog by slug 

export const fetchBlogSlug =  (slug) => {
    return async dispatch => {
        try {
         
            let blogSlug = await blogInfoApi(slug).then(res=>res);
            dispatch(fetchBlogSlugSuccess(blogSlug))
        }
        catch(e){
            console.log(e)
        }
    }
}