import React,{useState} from "react";
import ReactCrop from 'react-image-crop'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'react-image-crop/dist/ReactCrop.css'
import { pictureApi } from "../../api/axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {fetchUserSuccess} from "../../store/Actions/userAction";
import { setCookie } from "../../utils/auth";
function ImageModel(props){
  
  const user= useSelector((state) => state.user)
  const [crop, setCrop] = useState({ unit: "%",  x: 13,y: 20,width: 75,height: 60 });
  const dispatch=useDispatch();


// convert image to binary and save it to database 
  const handleSubmit=()=>{

    const canvas = document.createElement('canvas');
  let image=new Image();
  image.src=props.imgPath;

  if(image.width && image.height && props.imgPath){
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );


  
  canvas.toBlob(function(blob) {
    
      const formData= new FormData();
      formData.append('file', blob,"file");
      const uploadFile=pictureApi(formData,user.userData.token);
    uploadFile.then(result=>{
      if(result[0].status===200){
       props.hide();
       let updateUser=Object.assign({}, user.userData)
       updateUser.picture=result[0].picture;
       dispatch(fetchUserSuccess(updateUser))
       setCookie("userTrustiT",updateUser)
      }
      else {
        props.hide();
      }
    })
  });
  }

}



    return(

    <Modal show={props.show} onHide={props.hide}>
        <Modal.Header closeButton>
          <Modal.Title>Select the image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="justify-content-center text-center">

        <ReactCrop crop={crop} onChange={setCrop}>
      <img src={props.imgPath ? props.imgPath :''} />
      
    </ReactCrop>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} >
            Save Image
          
          </Button>
        </Modal.Footer>
      </Modal>

    );
}
export default ImageModel;