import React from "react";
import BlogCard from "../../components/BlogCard.js";
import { useTranslation } from 'react-i18next';
import SEO from "../../components/SEO";
import { fetchBlog } from "../../store/Actions/blogAction.js";
import SpinnerLoad from "../../components/SpinnerLoad.js";
import { Pagination } from "rsuite";

const Blog=(props)=>{

    const {t,i18n} = useTranslation("common");
    const { isLoading } =props;

    return(
        isLoading===false?
        
        <div className="mt-4 blog-container container">
        <SEO 
            title={t('seoContent.title blog',{name:process.RAZZLE_REACT_APP_NAME})}
            description={t('seoContent.description blog',{name:process.RAZZLE_REACT_APP_NAME})}
            keywords={t('seoContent.keyword blog')}

           />
            <h1 className="text-header">{t('SeoBody.blog h1',{name:process.RAZZLE_REACT_APP_NAME})}</h1>

        <div className="row">
        <div className=" col-lg-12">
        <h3 className="title-form text-center justify-content-center">{t('public.Our blog')}</h3>
        </div>
        </div>
            <BlogCard lang={i18n.resolvedLanguage}/>
        </div>
        :<SpinnerLoad/>
    );
}


Blog.getInitialProps = async ({store,match}) => {
    const page=match.params.page;
    return store.dispatch(fetchBlog(page));

  };


export default Blog;