import React from "react";
import appLogo from "../assets/icon/appLogo.svg"
function Loader(){

    return(
    <>
    <img className="loaderLogo" src={appLogo}/>
    </>
    );

}
export default Loader;