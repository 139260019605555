
const disableVIewReducer = (etat = false, action) => {
    switch (action.type) {
        case "disableView":
          return action.payload

      default:
        return etat;
    }
  };
  export default disableVIewReducer;