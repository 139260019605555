import React from "react";
import { useTranslation } from 'react-i18next';

function StaticCard(props){

    const {t} = useTranslation("common");

    return(
         <div style={{cursor:"default"}} className="condition-card card">
        <div className="card-body" style={{padding:props.paddingBody,paddingTop:props.paddingBodyTop}}>
            {props.title ? <h5 className="card-title text-center title-form">{t("footer." +props.title)}</h5>:''}
            <p style={{marginTop:"40px"}} className="card-text">{props.text}</p>
        </div>
    </div>
    );
}

export default StaticCard;