import React from "react";
import { useTranslation } from 'react-i18next';
import imgauth from "../assets/image/logo/logoNav.svg";
import { Link } from "react-router-dom";
import { Route } from 'react-router-dom';

function PageNotFound(){

  // redirect to home 
const redirectFn=()=>{
  window.location.replace(document.location.origin);
}
    const {t,i18n} = useTranslation("common");
    return(
            <Route
              render={({ staticContext }) => {
                if (staticContext) staticContext.statusCode = 404;
                return <div className="mt-5 container not-found">
                <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-4 justify-content-center text-center">
               <img width={200} src={imgauth}/>
                </div>
        
                <div className="col-lg-5 justify-content-center text-center">
               <h4>404</h4>
               <h4>{t('notFound.This page is not available')}</h4>
               <p>{t('notFound.The link you followed may be broken, or the page may have been deleted.')}</p>
               <Link to={{pathname: `/${i18n.resolvedLanguage}/`,state: {"reset":"homeReset","noscroll":true} }}>{t('public.Go back to home')}</Link>
        
                </div>
                </div>
                </div>
              }}
            />
          );
        }

export default PageNotFound;