const countryReducer = (state = [] , action) => {

    switch(action.type) {
        case 'FETCH_ALL_COUNTRY':
            return {...state,countryData:action.payload.country}

        default:
            return state
    }
}

export default countryReducer