import React, { useCallback, useState } from "react";
import { devisValidation } from "../schema/formValid";
import { useFormik } from "formik";
import { packdApi } from "../api/axios";
import { FocusError } from "focus-formik-error";
import { useLocation } from "react-router-dom";
import PhoneComponent from "../components/PhoneComponent";
import { handlePhoneChange } from "../utils/repairState";
import { useSelector } from "react-redux";

function DevisForm(props) {
  const [message, setMessage] = useState([]);
  const [phoneEtat, setPhoneEtat] = useState(true);
  const detection = useSelector((state) => state.countryDetected);

  const location = useLocation();

  // handle devis submit
  const handleOnSubmit = (values) => {
    const detectedCountryId = detection.detectionData.value;
    let typeDevisData = location.state && location.state.typeDevis;
    const {
      firstName,
      lastName,
      email,
      position,
      autreEmail,
      nomEntreprise,
      identifientUnique,
      besoin,
      phone,
      cupon,
      detailsBesoin,
    } = values;
    const data = packdApi(
      firstName,
      lastName,
      email,
      position,
      autreEmail,
      nomEntreprise,
      identifientUnique,
      besoin,
      typeDevisData,
      phone,
      cupon,
      detailsBesoin,
      detectedCountryId
    );
    data.then((result) => {
      setMessage(result);
      if (result[0].status === 200) {
        formik.resetForm();
        setTimeout(() => {
          setMessage([]);
        }, 10000);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      lastName: "",
      firstName: "",
      email: "",
      phone: "",
      besoin: "",
      nomEntreprise: "",
      autreEmail: "",
      identifientUnique: "",
      detailsBesoin: "",
      typeDevis: "",
      cupon: "",
    },
    validationSchema: devisValidation(props.translate, phoneEtat),
    onSubmit: handleOnSubmit,
    validateOnChange: false,
  });

  const setInputValue = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <FocusError formik={formik} />
      <div className="form-row">
        <div className="form-group col-md-6">
          <input
            name="lastName"
            value={formik.values.lastName}
            type="text"
            className={
              formik.errors.lastName
                ? "form-control is-invalid"
                : "form-control"
            }
            placeholder={props.translate("input:placeholder.lastName")}
            onChange={(e) => setInputValue("lastName", e.target.value)}
          />
          <small className="text-error">{formik.errors.lastName}</small>
        </div>

        <div className="form-group col-md-6">
          <input
            name="firstName"
            value={formik.values.firstName}
            type="text"
            className={
              formik.errors.firstName
                ? "form-control is-invalid"
                : "form-control"
            }
            placeholder={props.translate("input:placeholder.firstName")}
            onChange={(e) => setInputValue("firstName", e.target.value)}
          />
          <small className="text-error">{formik.errors.firstName}</small>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <input
            name="email"
            value={formik.values.email}
            type="email"
            className={
              formik.errors.email ? "form-control is-invalid" : "form-control"
            }
            placeholder={props.translate("input:placeholder.Email")}
            onChange={(e) => setInputValue("email", e.target.value)}
          />
          <small className="text-error">{formik.errors.email}</small>
        </div>
        <div className="form-group col-md-6">
          <PhoneComponent
            formikError={formik.errors.phone}
            value={formik.values.phone}
            change={(e) => handlePhoneChange(e, setInputValue, setPhoneEtat)}
            translate={props.translate}
          />
          <small className="text-error">{formik.errors.phone}</small>
        </div>
      </div>

      <div className="form-group">
        <textarea
          name="besoin"
          value={formik.values.besoin}
          className={
            formik.errors.besoin ? "form-control is-invalid" : "form-control"
          }
          rows="3"
          placeholder={props.translate("input:placeholder.Need")}
          onChange={(e) => setInputValue("besoin", e.target.value)}
        ></textarea>
        <small className="text-error">{formik.errors.besoin}</small>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <input
            name="nomEntreprise"
            value={formik.values.nomEntreprise}
            type="text"
            className={
              formik.errors.nomEntreprise
                ? "form-control is-invalid"
                : "form-control"
            }
            placeholder={props.translate("input:placeholder.Company Name")}
            onChange={(e) => setInputValue("nomEntreprise", e.target.value)}
          />
          <small className="text-error">{formik.errors.nomEntreprise}</small>
        </div>
        <div className="form-group col-md-6">
          <input
            name="identifientUnique"
            value={formik.values.identifientUnique}
            type="text"
            className="form-control"
            placeholder={props.translate("input:placeholder.Identifier")}
            onChange={(e) => setInputValue("identifientUnique", e.target.value)}
          />
          <small className="text-error">
            {formik.errors.identifientUnique}
          </small>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <input
            name="autreEmail"
            value={formik.values.autreEmail}
            type="email"
            className="form-control"
            placeholder={props.translate(
              "input:placeholder.Professional email"
            )}
            onChange={(e) => setInputValue("autreEmail", e.target.value)}
          />
          <small className="text-error">{formik.errors.autreEmail}</small>
        </div>
        <div className="form-group col-md-6">
          <input
            name="cupon"
            value={formik.values.cupon}
            type="text"
            className="form-control"
            placeholder={props.translate("input:placeholder.Discount coupon")}
            onChange={(e) => setInputValue("cupon", e.target.value)}
          />
          <small className="text-error">{formik.errors.cupon}</small>
        </div>
      </div>

      <div className="form-group">
        <textarea
          name="detailsBesoin"
          value={formik.values.detailsBesoin}
          className="form-control"
          rows="4"
          placeholder={props.translate("input:placeholder.Need Details")}
          onChange={(e) => setInputValue("detailsBesoin", e.target.value)}
        ></textarea>
        <small className="text-error">{formik.errors.detailsBesoin}</small>
      </div>

      <div className="row">
        <div className="col">
          <small className="text-success">
            {message && message.length > 0 && message[0].status === 200
              ? props.translate("home." + message[0].message)
              : ""}
          </small>
        </div>
      </div>

      <button type="submit" className="mt-3 mb-5 btn btn-primary">
        {props.translate("public.Send")}
      </button>
    </form>
  );
}
export default DevisForm;
