import React,{useCallback, useState} from "react";
import ButtonLink from "../../components/ButtonLink.js";
import {resetValidation} from "../../schema/formValid";
import { useFormik } from "formik";
import {forgetApi} from "../../api/axios.js";
import { useTranslation } from 'react-i18next';
import { redirectUtil } from "../../utils/auth.js";

const ForgetPassword=()=>{

  const {t,i18n} = useTranslation("common");
  const [count,setCount]=useState(0);
    const [message,setMessage]=useState([]);
    const [emailold,setEmail]=useState(0);

    const handleOnSubmit =(values) => {
        const {email}=values;
        
        if(count<5){
        const data=forgetApi(email,i18n.resolvedLanguage);       
        data.then(result =>{
          setMessage(result)
          if(result[0].status===500 && email!=emailold){
            setCount(count+1);
            setEmail(email)
          } 

        });
      }
  
        //props.viewDisable();
      
            
       }

       const formik = useFormik({
        initialValues: {
          email: "",
        },
        validationSchema: resetValidation(t),
        onSubmit: handleOnSubmit,
        validateOnChange:false,

      });

      const setInputValue = useCallback(
        (key, value) =>
          formik.setValues({
            ...formik.values,
            [key]: value,
          }),
        [formik],
      );

    return(
    <div className="reset-container container">

        <div className="row">
        <div className="col-lg-12">
          
        <h3 className="title-form">{t('login.Find your account')}</h3>
        {count<5 && message.length===0 || count <5 && message && message.length>0 && message[0].status!=200?

        <p>{t('login.Please enter your email address to search for your account.')}</p>
          :''}
            <form noValidate onSubmit={formik.handleSubmit}>
            {count <5 && message.length===0  || count <5 && message && message.length>0 && message[0].status!=200?
            <div className="form-group">
                <input type="text" className={formik.errors.email?"form-control is-invalid":"form-control"} name="email" placeholder={t('input:placeholder.Email')} onChange={(e) => setInputValue("email", e.target.value)}/>
                <small className="text-error">{formik.errors.email}</small>            

            </div>
            :''}
            <div className="row">

            <div className="col text-center">
              {count<5 ?
              <>
            <small className="text-success">{!formik.errors.email && message && message.length>0 && message[0].status===200 ?t("login."+message[0].message) :''}</small>         
            <small className="text-error">{!formik.errors.email  && message && message.length>0 && message[0].status!=200 ?t("public."+message[0].message) :''}</small>         
             </> :<small className="text-error">{t('public.Please try later')}</small>}
            </div>
            </div>
            
            <div className="row float-right">
            <div className="mt-3 col text-center float-right">
            {count <5 && message.length===0  || count <5 && message && message.length>0 && message[0].status!=200?
            <><ButtonLink path="/login" name={t('public.Cancel')}/>
            <button type="submit" className="mb-3 btn btn-primary">{t('input:placeholder.Search')}</button>
              </>:<ButtonLink path="/login" name={t('public.Back')}/>}
            </div>
            </div>      
           
   
            </form>

            </div>

                <div className="col-lg-2">

                </div>

             



</div>
</div>

    );

}

ForgetPassword.getInitialProps = async ({req}) => {
  if(req){
  if(redirectUtil(req))
  return { redirectTo: '/dashboard/reparation' };
  }

};

export default ForgetPassword;