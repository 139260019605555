import React from "react";
import taptic1 from "../assets/image/static-page/taptic1.svg";
import taptic2 from "../assets/image/static-page/taptic2.svg";
import taptic3 from "../assets/image/static-page/taptic3.svg";

function OffreImage(props){

    
    const arrayImage=[

        {path:taptic1,title:props.translate('offre.Help your business grow')},
        {path:taptic2,title:props.translate('offre.Control your costs')},
        {path:taptic3,title:props.translate('offre.Save time and effort')},
        

    ]
    return(

        <div className="row text-center image-block image-block-engine justify-content-center margin-space">
        {arrayImage.map(arrayImages=>(
              <div className="col-lg-4">
              <figure>
              <img src={arrayImages.path} alt={arrayImages.title}/>
      
              <figcaption>{arrayImages.title}</figcaption>
      
              </figure>
              </div>

        ))}
        

        </div>
    );
}

export default OffreImage;