import React from "react";
import StaticCard from "../../components/StaticCard";
import { useTranslation } from 'react-i18next';
import networkFrImg from "../../assets/image/static-page/networkFr.svg";
import networkEnImg from "../../assets/image/static-page/networkEn.svg";

function Network(){
    const {t,i18n} = useTranslation("common");

    const textReturn=()=>{
        return(
            <div  className="justify-content-center mission-row row">
            <div className="rectangle-class">
            <img src={i18n.resolvedLanguage==="fr" ?networkFrImg :networkEnImg} alt={t('footer.Our Network')}/>
            </div>
            </div>
        );
    }

    return(
        <div className="network-container mt-5">
        <StaticCard from="footer" title="Our Network"
        text={textReturn()}
        />
        </div>
    );
}
export default Network;