const initState={
    infoPayment:{}
}

const paymentReducer = (state = {} , action) => {

    switch(action.type) {
        case 'FETCH_ALL_PAYMENT':
            return {...state,infoPayment:action.payload.paymentData}
        default:
            return state;
    }
}

export default paymentReducer