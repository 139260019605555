import React,{useCallback, useEffect, useState} from "react";
import { noteApi, postNoteApi } from "../../api/axios";
import moment from "moment";
import appLogo from "../../assets/icon/appLogo.svg"
import { useFormik } from "formik";
import {remarqueValidation } from "../../schema/formValid";
import { getFromLocalStorage } from "../../utils/auth";
import { useSelector } from "react-redux";

function Note(props) {

    const [noteList,setNoteList]=useState([]);
    const [message,setMessage]=useState([]);
    const user= useSelector((state) => state.user)


    useEffect(()=>{
        getNoteListe();

      
    },[]);

    // get all note by user and team
    const getNoteListe=()=>{
        let data=noteApi(props.code,user.userData.token)
        data.then(result=>{
            const sortedNote = result.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            setNoteList(sortedNote)
            
        });
    }

    const handleOnSubmit =(values) => {
        const {remarque}=values;
        // save all note to database and refresh the list
        const data=postNoteApi(props.id,remarque,user.userData.token);
        data.then(result=>{
            setMessage(result)
            if(result[0].status===200){
                formik.resetForm();
                setMessage([]);
                getNoteListe();
                
               /* setTimeout(()=>{
                setMessage([]);
                },3000)*/
            }
        })
    }

    const formik = useFormik({
        initialValues: {
          remarque: "",
        },
        validationSchema: remarqueValidation(props.translate),
        onSubmit: handleOnSubmit,
        onClick: handleOnSubmit,
        validateOnChange:false,

      });

      const setInputValue = useCallback(
        (key, value) =>
          formik.setValues({
            ...formik.values,
            [key]: value,
          }),
        [formik],
      );
    
      // check image of note howner
      const handelId=(noteListfull)=>{
        if(noteListfull.owner==user.userData._id){
            return true;
        }
        else return false;
      }

      const handleEnterPress=(e)=>{
        e.preventDefault();
        if(e.code==="Enter"){
          formik.handleSubmit()
        }

      }
    return(
        <div className="container chat-container">
        <div className="content">
        {noteList && noteList.length>0 ?

        noteList.map(noteListfull=>(
               noteListfull._id &&  noteListfull.description && noteListfull.created_at ?
               <div key={noteListfull._id} className="row">
               <div className="chat-content" key={noteListfull._id}>
               <img src={
                handelId(noteListfull)===true?
                process.env.RAZZLE_REACT_APP_BASE_URL+user.userData.picture
                :appLogo
            }
                
                />
                  
                  {/*<img src={defaultLogo}/>*/}
                           <span className={handelId(noteListfull)===false?"team-color margin-direction":"margin-direction"}>{noteListfull.description}</span>
                           <span>{moment(noteListfull.created_at).locale(props.lang!="ar" ? props.lang :'ar-tn').fromNow()}</span>
                       </div>
                       </div>
                           :""
        ))
        :<p style={{paddingTop:"25px"}} className="text-center">{props.translate('public.Message list is empty')}</p>}
        
        <div class="input-group input-group-chat mt-3 mb-3">
        <form className="w-100" noValidate onSubmit={formik.handleSubmit}>

           <textarea onKeyDown={(e)=>handleEnterPress(e)} value={formik.values.remarque} className={formik.errors.remarque?"input-send form-control is-invalid":"form-control input-send"}  rows="3" placeholder={props.translate('input:placeholder.Type your note here')} onChange={(e) => setInputValue("remarque", e.target.value)}/>
           <i onClick={formik.handleSubmit} className="send-icon"></i>
           </form>
           <small className="mt-2 text-error">{formik.errors.remarque}</small>            

           </div>
           
        
        </div>
        
        </div>
    );
}
export default Note;