import { Steps} from 'rsuite';
import React from "react";
import step1 from "../../assets/icon/step1.svg";
import step2 from "../../assets/icon/step2.svg";
import step3 from "../../assets/icon/rangeIcon.svg";
import step4 from "../../assets/icon/step3.svg";
import step5 from "../../assets/icon/step4.svg";
import step6 from "../../assets/icon/step5.svg";
import { useDispatch, useSelector } from 'react-redux';
import { stepCurrent } from '../../store/Actions/stepCounterAction';
import { add } from '../../store/Actions/counterAction';


function Stepper(props){

  const dispatch = useDispatch()
  const stepCurrentVal= useSelector((state) => state.stepcounter)
  const steperData= useSelector((state) => state.stepData.steperData)

    const styles = {
        width: '200px',
        display: 'inline-table',
        verticalAlign: 'top'
      };

      // handle steper left click to set current step 
      const handleClick=(step)=>{
        
        if(props.noSubCategory===true && step===6){
          dispatch(stepCurrent(6))
        }
        else if(steperData["step"+step] && step!=6) {
          dispatch(stepCurrent(step))
          dispatch(add(step))
      }}
      
      // steper item data
      const steperItem=[
        {"title":props.translate('home.Device Type'),"icon":<img style={{width:"50%"}} src={step1}/>},
        {"title":props.translate('home.Mark'),"icon":<img style={{width:"50%"}} src={step2}/>},
        {"title":props.translate('home.Range'),"icon":<img style={{width:"50%"}} src={step3}/>},
        {"title":props.translate('home.Model'),"icon":<img style={{width:"50%"}} src={step4}/>},
        {"title":props.translate('home.Panne type'),"icon":<img style={{width:"50%"}} src={step5}/>},
        {"title":props.translate('home.Depose Repair'),"icon":<img style={{width:"50%"}} src={step6}/>}
      ]

    return(
        <>
        <Steps className="stepper-desktop" current={stepCurrentVal} vertical style={styles}>
          {steperItem.map((steperItems,index)=>
             <Steps.Item  key={index} onClick={()=>handleClick(index+1)} title={steperItems.title} icon={steperItems.icon}/>

        )}
          
        </Steps>

      </>
    );
}

export default Stepper;