import React, {useEffect, useState } from "react";
import googleplay from "../assets/image/other/google-play.svg";
import istore from "../assets/image/other/istore.svg";
import {Link, useLocation} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Newsletter from "../components/Newsletter";
import SelectSearch from "../components/SelectSearch";
import { getFromCookie, getFromLocalStorage, setCookie, setLocalStorage} from "../utils/auth";
import { components } from "react-select";
import { countryIcon, detailsWebsite } from "../utils/repairState";
import {useSelector } from 'react-redux';


// social icon list

  const socialList=[
    
      {name:"facebook","link":process.env.RAZZLE_REACT_APP_FACEBOOK_TN,"image":"facebook-white"},
      {name:"instagram","link":process.env.RAZZLE_REACT_APP_INSTAGRAM_TN,"image":"instagram-white"},
      {name:"linkedin","link":process.env.RAZZLE_REACT_APP_LINKEDIN_TN,"image":"linkedin-white"},
      {name:"youtube","link":process.env.RAZZLE_REACT_APP_YOUTUBE_TN,"image":"youtube-white"},
  ]


const Footer=()=>{

  const {t,i18n} = useTranslation("common");
  const [selectValue,setSelectValue]=useState([]);
  const [imgPath,setImgPath]=useState("");
  const country= useSelector((state) => state.country.countryData)
  const detection= useSelector((state) => state.countryDetected)

  // handle country change 
  const handleChange=(e)=>{
  setSelectValue(e);
  setCookie('country',e);
  //setLocalStorage("country",e);

  window.location.reload();
}

// custom style for country select

const customStyles = {

  control: (base) => ({
    ...base,
    background: "#495057",
    borderRadius:"12px",
    border:"none",
    color:"white",

  }), 
  
  menu: base => ({
    ...base,
    background: "#495057",
    borderRadius:"12px",
    border:"none",

  }),

  menuList: base => ({
    ...base,
    background: "#495057",
    borderRadius:"12px",
    border:"none",
    
  }),
  singleValue: provided => ({
    ...provided,
    color: '#ABB5BE'
  }),

  option: (styles, {isFocused, isSelected}) => ({
    ...styles,
    background: isFocused && !isSelected
        ? "#495057 !important "
        : isSelected
            ? "#2684FF !important"
            : undefined,
    zIndex: 1
}),

  
  valueContainer: base => ({
    ...base,
    paddingLeft: imgPath?43:"",
    paddingRight: imgPath?43:"",

  })
  };



const ValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && (
          imgPath?<img className="flag-img" style={{position:"absolute",right:i18n.resolvedLanguage=="ar"?"8px":"",left:"8px",width:"28px"}} src={process.env.RAZZLE_REACT_APP_BASE_URL+imgPath}/>
          :""
          )}
        {children}
      </components.ValueContainer>
    )
  );
};

// get select country and set it 
useEffect(()=>{
setSelectValue(detection.detectionData);
setImgPath(detection.detectionData ?detection.detectionData.icon:"");
},[]);

const lang=i18n.resolvedLanguage;

    return(
        <>
<footer style={{marginTop: "85px"}} className="page-footer font-small blue pt-4 pb-4">
  <div className="container-fluid text-center text-md-left">
    <div style={{marginTop:"50px"}} className="ml-5 row">
      <div className="col-md-3 mt-md-0 mt-3 mb-3">
        <h5> {t('footer.your trusted partner throughout')}</h5>
        <p className="phone-fotter" style={{marginBottom:"15px",marginTop:"23px"}}><i className="contact-white phone-white"></i></p><p className="phone-fotter"><a href={"tel:"+detailsWebsite(detection,"phone")}><strong>{detailsWebsite(detection,"phone")}</strong></a></p>
        <p><i className="contact-white mail-white"></i><a href={"mailto:"+detailsWebsite(detection,"","email")}><strong>{detailsWebsite(detection,"","email")}</strong></a></p>
        <SelectSearch isSearchable={false} components={{ValueContainer,Option:countryIcon(i18n.resolvedLanguage) }} customStyles={customStyles} change={handleChange} value={selectValue} placeholder={t('input:placeholder.Select country')} option={country!=null && country.length>0 ? country :[]} name="country"/>
      </div>
      <div className="col-md-3 mb-md-0 mb-3">

        <ul className="list-unstyled">
          <li>
            <Link to={{pathname: `/${lang}/`,state: {"reset":"homeReset"} }} name={t('home.Depose your Repair')}>{t('public.Depose a Repair')}</Link>

          </li>
          <li>
            <Link to={`/${lang}/how-to`}>{t('footer.How it works')}</Link>
          </li>
          
          <li>
            <Link to={`/${lang}/mission`}>{t('footer.Our mission')}</Link>

          </li>
          <li>
            <Link to={`/${lang}/network`}>{t('footer.Our Network')}</Link>

          </li>

          <li>
            <Link to={`/${lang}/partner`}>{t('footer.Partners')}</Link>

          </li>

        </ul>

      </div>

      <div className="col-md-3 mb-md-0 mb-3">

        <ul className="list-unstyled">
         
          <li>
            <Link to={`/${lang}/contact`}>{t('footer.Join our network')}</Link>

          </li>

          <li>
      <Link to={`/${lang}/about-us`}>{t('footer.About us')}</Link>

        </li>

        <li>
            <Link to={`/${lang}/contact`}>{t('public.Contact us')}</Link>

          </li>

          <li>
            <a href={require('../assets/file/cgs_trustit_fr.pdf')} target="_blank">{t('footer.Legal Notice')}</a>
             
          </li>

          <li>
            <Link to={`/${lang}/business-offer`}>{t('public.Business offer')}</Link>
          </li>

         
       
         
        </ul>

      </div>


      
      <div className="col-md-3 mb-md-0 mb-3 newslatter-class">
        <Newsletter translate={t} resolvedlang={i18n.resolvedLanguage}/>
        <div className="row mt-4">
        <div className="col-lg-5 col-sm-6 col-6">
        <a target="_blank" href={process.env.RAZZLE_REACT_APP_GOOGLE_PLAY}><img className="store-img" style={{width:"100%"}} src={googleplay}/>
        </a>
        </div>
            <div className="col-lg-5 col-sm-6 col-6">
           <a target="_blank" href={process.env.RAZZLE_REACT_APP_APPLE_STORE}><img className="store-img" style={{width:"100%"}} src={istore}/>
           </a>
            </div>

        </div>

      </div>

    </div>

  </div>

  <div className="social-white text-center py-3 mt-3">
    {socialList.map(socialLists=>
    <a key={socialLists.name} href={socialLists.link} target="_blank"><i className={socialLists.image}></i></a>
    )}

  </div>

  <div className="footer-copyright text-center">
    <p>{t('footer.Copyright',{year:new Date().getFullYear()})}</p>
  </div>

</footer>
        </>
    );
}

Footer.getInitialProps = async ({store}) => {
  //const country=await fetchCountryData();

   //return store.dispatch(country)

 };

export default Footer;