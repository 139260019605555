import { Steps} from 'rsuite';
import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { stepCurrent } from '../../store/Actions/stepCounterAction';
import { add } from '../../store/Actions/counterAction';

function StepperTop(){

    const styles = {
        verticalAlign: 'top',
      };

      const steperData= useSelector((state) => state.stepData.steperData)
      const stepCurrentVal= useSelector((state) => state.stepcounter)
      const dispatch = useDispatch()

 
      // handle steper top click to set current step

      const handleClick=(key)=>{
        let step=Number(key.slice(-1));
        if(steperData["step"+step]){
          dispatch(stepCurrent(step))
          dispatch(add(step))
        }
      }

    return(
        <>
        <Steps className="stepperTop" current={stepCurrentVal} style={styles}>
        {Object.keys(steperData).map((keyName, i) => (
          steperData[keyName].name && steperData[keyName].name!="-" && steperData[keyName].picture?
          <OverlayTrigger
          key={i}
          placement="bottom"
          overlay={
            <Tooltip id={i}>
            <strong>{steperData[keyName].name}</strong>
            </Tooltip>}>
          <Steps.Item key={i} onClick={()=>handleClick(keyName)} icon={<img style={{width:"auto",maxHeight:"21px",maxWidth:"30px"}} src={process.env.RAZZLE_REACT_APP_BASE_URL+steperData[keyName].picture}/>}/>
        </OverlayTrigger>        
          :''
          ))}
        </Steps>

      </>
    );
}

export default StepperTop;