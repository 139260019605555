import React,{useState,useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { filterCurency, handleEtat, handleTranName,domicileTranslate } from "../../utils/repairState";
import { useValueEffect } from "@react-aria/utils";
import { useSelector } from "react-redux";

function ModelCodeVerif(props){

    const [show, setShow] = useState(props.handleShow);

  const handleClose = () =>{setShow(false);}
  const handleShow = () => setShow(props.handleShow);
  const country= useSelector((state) => state.country.countryData)


  // get reparation address bassed of reparation typedepot
  const handleAdress=(list,type)=>{
    let adress="";
    if(list.typeDepot!="c3"){
      adress=" ("+list.recupHome+")";
    }
    else if(list.typeDepot=="c3" && type=="Recuperation") {
      if(list.centreRecuperation.adress)
      adress=" ("+list.centreRecuperation.adress+")";

    }

    else if(list.typeDepot=="c3" && type=="Deposite"){
      if(list.centreRecuperation.adress)
      adress=" ("+list.centreDepot.adress+")";

     
    }
    return adress;
  }


    return(
        <>

        <Modal className="codeVerif" show={show} onHide={handleClose}>

          <Modal.Body>
          <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
        <Accordion.Header>{handleTranName(props.resolvedlang,props.verifCode.ReparationProduct.typeReparation)}</Accordion.Header>
        <Accordion.Body>
        <p>{props.translate("home.Code")} : {props.verifCode.code}</p>
        <p>{props.translate("repair.Deposit Center")} : {domicileTranslate(props.verifCode.centreDepot.name,props.translate)+handleAdress(props.verifCode,"Deposite")} </p>
        <p>{props.translate("repair.Recovery center")} : {domicileTranslate(props.verifCode.centreRecuperation.name,props.translate)+handleAdress(props.verifCode,"Recuperation")}</p>
        <p>{props.translate("public.Pricenb",{price:props.verifCode.prix})+" "+filterCurency(country,props.verifCode)}</p>
        <p>{props.translate("repair.State")} : {props.translate("etat."+handleEtat(props.verifCode.etat))}</p>

    </Accordion.Body>
      </Accordion.Item>
    
    </Accordion>

   

          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
           {props.translate("public.Close")}
          </Button>
          
        </Modal.Footer>
        </Modal>
      </>
    );
}
export default ModelCodeVerif;