import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleLogin } from '../api/axios';
import { notifyError, notifySuccess } from '../utils/repairState';
import { useSelector } from 'react-redux';


const clientId="871589543552-g0d9ia3qmhoo75d12qicduc15p5nj6aq.apps.googleusercontent.com";



function GoogleSignIn(props){
  const detection= useSelector((state) => state.countryDetected)


  // get google login repsponse and login or register the user
  const responseGoogle = (response) => {
    console.log(response);
    //const userObject = jwt_decode(response.credential);
    apiCall(response.credential,response.client_id);
    //console.log(JSON.stringify(userObject));
    //console.log(response)
  }
  

  // login or register the user 
  const apiCall=(token,clientId)=>{
    
    const data=googleLogin(token,clientId,detection.detectionData.value,props.lang);
    data.then(result=>{
      console.log("result",result)
      if(result[0].status===200){
      notifySuccess(props.translate('login.'+result[0].message));
      setTimeout(()=>{

        window.location.reload();
        },1800)
    }
    else {
      notifyError(props.translate('public.'+result[0].message));
    }
   

})

  }
  
    return(
      <GoogleOAuthProvider clientId={clientId}>        
      <GoogleLogin
        type="icon"
        onSuccess={responseGoogle}
        onError={() => {
          console.log('Login Failed');
        }}
        />
        </GoogleOAuthProvider>        

    );
}
export default GoogleSignIn;