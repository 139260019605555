import React,{useCallback,useState} from "react";
import {contactValidation} from "../schema/formValid";
import { useFormik } from "formik";
import {contactApi} from "../api/axios.js";
import { FocusError } from 'focus-formik-error'
import PhoneComponent from "./PhoneComponent";
import {handlePhoneChange} from "../utils/repairState";

function ContactForm(props){

  const [message,setMessage]=useState([]);
  const [phoneEtat, setPhoneEtat] = useState(true)

  const handleOnSubmit =(values,{resetForm}) => {
    const {firstName,lastName,email,phone,msgbody}=values;
    const data=contactApi(firstName,lastName,email,phone,msgbody);       
    data.then(result =>{
      setMessage(result)
      if(result[0].status===200){
        formik.resetForm();
        setTimeout(()=>{
          setMessage([]);
        },10000)
      } 
    });

     }

   const formik = useFormik({
    initialValues: {
      lastName: "",
      firstName: "",
      email:"",
      phone:"",
      msgbody:""

    },
    validationSchema: contactValidation(props.translate,phoneEtat),
    onSubmit: handleOnSubmit,
    validateOnChange:false,

  });

  const setInputValue = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik],
  );

    return (

<form noValidate onSubmit={formik.handleSubmit}>
<FocusError formik={formik} />
  <div className="form-row">
    <div className="form-group col-md-6">
      <input value={formik.values.lastName} type="text" className={formik.errors.lastName?"form-control is-invalid":"form-control"} name="lastName" placeholder={props.translate('input:placeholder.lastName')} onChange={(e) => setInputValue("lastName", e.target.value)}/>
      <small className="text-error">{formik.errors.lastName}</small>            
    </div>
    <div className="form-group col-md-6">
      <input value={formik.values.firstName} type="text" className={formik.errors.firstName?"form-control is-invalid":"form-control"} name="firstName" placeholder={props.translate('input:placeholder.firstName')} onChange={(e) => setInputValue("firstName", e.target.value)}/>
      <small className="text-error">{formik.errors.firstName}</small>            

    </div>
  </div>
   <div className="form-row">
    <div className="form-group col-md-6">
      <input value={formik.values.email} type="email" className={formik.errors.email?"form-control is-invalid":"form-control"} name="email" placeholder={props.translate('input:placeholder.Email')} onChange={(e) => setInputValue("email", e.target.value)}/>
      <small className="text-error">{formik.errors.email}</small>            
    </div>
    <div className="form-group col-md-6">
      <PhoneComponent formikError={formik.errors.phone} value={formik.values.phone} change={(e)=>handlePhoneChange(e,setInputValue,setPhoneEtat)} translate={props.translate}/>

      <small className="text-error">{formik.errors.phone}</small>            
    </div>
  </div>
  
  <div className="form-group">
      <textarea value={formik.values.msgbody} className={formik.errors.msgbody?"form-control is-invalid":"form-control"} name="msgbody" rows="3" placeholder={props.translate('input:placeholder.Message')} onChange={(e) => setInputValue("msgbody", e.target.value)}></textarea>
      <small className="text-error">{formik.errors.msgbody}</small>            
  </div>

  <div className="row">

            <div className="col">
            <small className="text-success">{message && message.length>0 && message[0].status===200 ?props.translate('contact.'+message[0].message) :''}</small>         
            <small className="text-error">{message && message.length>0 && message[0].status===404 ?props.translate('contact.'+message[0].message) :''}</small>         
            </div>
            </div>
 

  <button type="submit" className="mt-3 mb-5 btn btn-primary">{props.translate('public.Send')}</button>
</form>

    );

}

export default ContactForm;