import React from "react";
import { Helmet } from 'react-helmet';


function SEO(props){


//header component for title and meta
    return(
        <>
        <Helmet>
            <title >{props.title}</title>
            <meta name="title" content={props.title}/>
            <meta name='description' content={props.description}/>
            <meta name="keywords" content={props.keywords}/>
            </Helmet>
            
        </>
    );
}
export default SEO;