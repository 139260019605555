import React, { useEffect, useState,useRef, useContext} from "react";
import {brandApi, categoryApi,categoryChildApi, deviceApi, gammeApi, modeApi, modelApi, productRepairApi} from "../../api/axios.js";
import {useHistory,useLocation} from "react-router-dom";
import FormRepair from "./formRepair.jsx";
import FormFinal from "./formFinal.jsx";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { add, increment } from "../../store/Actions/counterAction.js";

import { stepCurrent, stepincrement } from "../../store/Actions/stepCounterAction.js";
import { categoryPush, handleTranslatedName } from "../../utils/repairFn.js";
import { fetchDeviceSuccess } from "../../store/Actions/deviceAction.js";
import { translateCurency } from "../../utils/repairState.js";

const RepairCategory=(props)=>{

    const dispatch = useDispatch()
    const ref = useRef(true);
    const myRef = useRef(null);
    const location = useLocation();
    const { step1,step2,step3,step4} = useParams();
    const [search,setSearch]=useState("");
    
    let removeAccents = require('remove-accents');

    let history=useHistory();

    //const [count,setCount]=useState(1);

    const [submitForm1, setsubmitForm1] = useState(false);
    const [color, setColor] = useState("#EBF6FD");
    const device= useSelector((state) => state.device.deviceData);
    const detection= useSelector((state) => state.countryDetected)
    const count= useSelector((state) => state.counter)
    const stepCurrentVal= useSelector((state) => state.stepcounter)
    const steperData= useSelector((state) => state.stepData.steperData)
    const [category,setCategory]=useState(device);
    const [filtredCategory,setFiltredCategory]=useState(device);
    const [firstRendred,setfirstRendred]=useState(false);
    const country_id=detection?detection.detectionData.value:"";
    const [gammeEmpty,setGammeEmpty]=useState(false);
    // client side reparation change handler steper and data
    useEffect(() => { 
        if(firstRendred==false){
         setfirstRendred(true)   
         
        }
        else {
          
       if(stepCurrentVal==1){
        let data=deviceApi(country_id);
        data.then(result=>{

            dispatch(fetchDeviceSuccess(result))
            setFiltredCategory(result);    
        })
        
        }
        if(stepCurrentVal==4 && gammeEmpty==true){
            //console.log("testou")
            categoryPush({id:"",name:"-",picture:[]},3,steperData,dispatch);
            stepRouteHandler("-",3);
            const data=getApi(3,"");
            data.then(result=>{
                dispatch(fetchDeviceSuccess(result))
                dispatch(add(4))
                setFiltredCategory(result); 
            })

        }

        else if(stepCurrentVal>1 && stepCurrentVal<=5 && steperData["step"+stepCurrentVal]){
            let newcount=stepCurrentVal-1;
            //console.log(steperData);
            if(steperData["step"+newcount]){
            let data=getApi(stepCurrentVal,steperData["step"+newcount].id,true)
            data.then(result=>{
                dispatch(fetchDeviceSuccess(result))
                setFiltredCategory(result);    
            });
            
            }}
  
        else {
            dispatch(fetchDeviceSuccess([]));
            setFiltredCategory([]);    
        }
        
    }
        
        
    }, [stepCurrentVal]);


    const submitForm1State=(value)=>{
        setsubmitForm1(value);
    }

    // get data device or brand or model or product based on step 
    
    const getApi=(steps,id,stepIncreamte,nogamme)=>{
        //console.log(steperData)
        let data=null;
        let step=steps+1;
        if(stepIncreamte===true){
            step=steps
        }
        if(step===2){
            data=brandApi(id,country_id);
        }
        
        else if(step===3){
           // console.log("gamme test",step)
            data=gammeApi(steperData["step1"].id,id,country_id);
        }
        else if(step===4){
            //console.log("model")
            if(!nogamme){
            data=modelApi(steperData["step1"].id,steperData["step2"].id,id,country_id);
            }
            else{
            //categoryPush({id:"",name:"-",picture:""},3,steperData,dispatch);
            data=modelApi(steperData["step1"].id,id,"",country_id);
            
            }

        }

        else if(step===5){
            //let country_id=detection?detection.detectionData.value:"";
            data=productRepairApi(steperData["step1"].id,steperData["step2"].id,steperData["step3"].id,id,country_id);
        }
     
        
        return data;
    }

    const stepRouteHandler=(routeName,count)=>{
        let oldurlData=[step1,step2,step3,step4];
        
        if(routeName.indexOf(" ")){
            routeName=routeName.split(' ').join('-');
        }

        if(oldurlData[count-1]){
            if(oldurlData[count-1]!=routeName){
             oldurlData[count-1]=routeName;
             oldurlData=oldurlData.slice(0,count);
             routeName="";
            }
            else {
                routeName="";
            }
        }
            
            let oldurl=oldurlData.filter(Boolean).join("/");
            oldurl=removeAccents(oldurl);
            routeName=removeAccents(routeName);

        if(step1){
            history.push(`/${props.resolvedlang}`+"/category/"+oldurl+"/"+routeName);
        }
        
        else {
            history.push(`/${props.resolvedlang}`+"/category/"+routeName);
        }
        
        window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop+330})

    }

    // handle category when clicked 
    const handleCategory=(id,name,picture,colorData)=>{   
        let routeName=name && name.toLowerCase();
        const data=getApi(count,id);
        if(count<5){
        data.then(result=>{
            dispatch(fetchDeviceSuccess(result))
            setFiltredCategory(result); 
            setSearch("");   

            if(result.length>0){  
            setGammeEmpty(false)
            dispatch(increment())
            props.noSubCategory(false)

            }
            else if(result.length==0 && count==2){
            setGammeEmpty(true)
            dispatch(stepCurrent(4))
            //dispatch(add(4))
            }
          
        });}
        else {
            dispatch(fetchDeviceSuccess([]))
            setFiltredCategory([]);    
           props.noSubCategory(true);
        }

        if(!colorData){
            categoryPush({id:id,name:name,picture:picture},count,steperData,dispatch);

        }
        else {
            let newColor=[];
            if(colorData.length>0){
                for(let data in colorData){

                    newColor.push({value:colorData[data]._id,label:handleColorTran(colorData[data]),icon:colorData[data].codeColor})
                }
            }

            categoryPush({id:id,name:name,picture:picture,color:newColor},count,steperData,dispatch);

        }
    
        
        stepRouteHandler(routeName,count);

        //window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop+330})
        dispatch(stepincrement())

 
    }

    // get different translated color

    const handleColorTran=(color)=>{
        let name="";
        if(props.resolvedlang==="fr"){
            name=color.nameColorFrancais;
        }
        else if(props.resolvedlang==="en"){
            name=color.nameColorEnglish;

        }
        else if(props.resolvedlang==="ar"){
            name=color.nameColorArabe;

        }
        return name;
    }

    // function to handle the fliter when searching for specefic device model or product

const handleChange=(event)=>{
    setSearch(event.target.value);
    dispatch(fetchDeviceSuccess(filtredCategory.filter(categorys=>handleTranslatedName(categorys,count,props.resolvedlang).toLowerCase().includes(event.target.value.toLowerCase()))));
    
}

const renderPrice=(categorys)=>{
    if(Number(categorys.prix)>0){
        return(
            <>
        {categorys.prix+" "+translateCurency(categorys,props.resolvedlang)}
        </>
        );
    }
    else if(categorys.prix==0){
        return(
            <>
            {props.translate('home.Free')}
        </>
        );
    }
    else {
        return(
        <>
        {props.translate('home.After diagnostic')}
        </>
        );
    }

}
    return (
        <>
        {stepCurrentVal<6?<input onChange={(e)=>handleChange(e)} value={search} type="text" className="form-control" placeholder={props.translate('input:placeholder.Search')}/>
        :""}
        <div ref={myRef} key={stepCurrentVal} className={stepCurrentVal<6? "row mt-5 mb-3 card-deck home-card":'row mt-2 mb-3 card-deck home-card'}>
        {device && device.length>0?
        device.map((categorys,index)=>(
            <div  key={categorys._id} className=" mb-3 col-lg-4 col-6 col-md-4 col-sm-4">
            <div style={{background:stepCurrentVal===count && steperData["step"+stepCurrentVal] && steperData["step"+stepCurrentVal].id===categorys._id? color:"",border:stepCurrentVal===count && steperData["step"+stepCurrentVal] && steperData["step"+stepCurrentVal].id===categorys._id? "2px solid #35A0EE":""}} className="card" onClick={()=>handleCategory(categorys._id,handleTranslatedName(categorys,count,props.resolvedlang),!categorys.typeReparation ?categorys.picture :categorys.typeReparation.picture,categorys.hasOwnProperty('colorList') && categorys.colorList.length>0 ?categorys.colorList :"" )}>
                <img className="mx-auto card-img-top" src={categorys.country && categorys.typeReparation ?process.env.RAZZLE_REACT_APP_BASE_URL+categorys.typeReparation.picture : process.env.RAZZLE_REACT_APP_BASE_URL+categorys.picture} alt={handleTranslatedName(categorys,count,props.resolvedlang)}/>
                <div className="card-body">
                {/* <h5 className="card-title">{handleTranslatedName(categorys,count,props.resolvedlang)}</h5> */}
                {categorys.prix && categorys.country?
                <p className="text-center card-text"><small className="price-category text-muted">
                    {renderPrice(categorys)}</small></p>
                :''}

                </div>
            </div>
            <h5 className="card-title">{handleTranslatedName(categorys,count,props.resolvedlang)}</h5>

            </div>
           
        )).reverse()
        :''} 
     
        </div>
        {device && device.length===0 && stepCurrentVal==6 ?<FormRepair translate={props.translate} submitForm1={submitForm1State}/>:''}
        {device && device.length===0 && stepCurrentVal==7 && submitForm1===true ?<FormFinal noSubCategory={props.noSubCategory} lang={props.resolvedlang} translate={props.translate} codeGeneratedState={props.codeGeneratedState} codeGeneratedProps={props.codeGeneratedProps}/>:''}
       
      </>
        
    );
}



export default RepairCategory;