import React, { useState, useRef, useEffect } from "react";
import { Profile, RepairList, Point } from "../index.js";
import Nav from "react-bootstrap/Nav";
import { NavLink, Redirect } from "react-router-dom";
import { getFromCookie, getFromLocalStorage, signout } from "../../utils/auth";
import { useTranslation } from "react-i18next";
import ImageModel from "./ImageModel.jsx";
import { ToastContainer} from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { notifyError } from "../../utils/repairState.js";

import { useSelector } from "react-redux";
import Cookies from 'universal-cookie';
import SEO from "../../components/SEO.js";

const HomeUser=() =>{

  const { t } = useTranslation("common");
  const myRef = useRef(null);

  const [activeKey, setActiveKey] = useState("repair");

  const [show, setShow] = useState(false);
  const [imgPath, setImgPath] = useState("");
  const user= useSelector((state) => state.user)

  // close dialog model 
  const handleClose = () => {
    setShow(false);
    setImgPath("");
    myRef.current.value = null;
  };
  const handleShow = () => setShow(true);

  const handlePicture = () => {
    myRef.current.click();
  };

  // function called when user is logout
  const logout = () => {
    signout();
    window.location.reload();
  };

  // change and upload profile picture 
  const handleChange = (event) => {
    let windowurl = window.URL || window.webkitURL;
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let img = new Image();
      let objectUrl = windowurl.createObjectURL(file);
      if (objectUrl) {
        img.onload = function () {
          if (this.width > 100 && this.height > 100) {
            setImgPath(windowurl.createObjectURL(file));
            handleShow();
          } else {
            // alert("img to small chose another")
            notifyError(t('public.Image to small chose another'));
          }
          windowurl.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl;
      }
    }
  };

  // component switcher profile,repairlist,point
  
const handleComponentChange=()=>{
  switch(activeKey){
    case "profile":
      return (<Profile/>);

    case "repair":
      return (<RepairList/>);

    case "profile":
      return (<Profile/>);

    case "point":
      return (<Point/>);
  }
}
  return (
      <div className="mt-5 container blockInscription-left user-home">
        <SEO 
            title={t('public.Profile',{name:process.env.RAZZLE_REACT_APP_NAME})} 
           />
           
          {user && user.userData ?
        <div className="row">
          
          <div className="col-lg-3 col-md-5 profile-block">
            <div className="col-lg-3 user-picture">
              <img
                style={{ width: "155px", height: "155px" }}
                src={
                  user.userData && user.userData.picture.includes("http")?
                  user.userData.picture:
                  process.env.RAZZLE_REACT_APP_BASE_URL + user.userData.picture
                }
               
              />
              <i onClick={handlePicture} className="upload-icon"></i>
              <input
                type="file"
                id="file"
                ref={myRef}
                style={{ display: "none" }}
                onChange={handleChange}
              />
            </div>

            <div className="col-lg-3"></div>
            <div className="navigation-menu">
              <Nav
                defaultActiveKey={activeKey}
                className="flex-column"
                onSelect={(e) => setActiveKey(e)}>
                <Nav.Link
                  eventKey="profile"
                  to="/dashboard/profile">
                  {" "}
                  <i className="mr-2 home-menu profile-logo"></i>
                  <p>{t("public.My profile")}</p>
                  <div className="vertical-line"></div>
                </Nav.Link>
                <Nav.Link
                  eventKey="repair"
                  to="/dashboard/reparation">
                  <i className="mr-2 home-menu reparation-logo"></i>
                  <p>{t("public.My repairs")}</p>
                  <div className="vertical-line"></div>
                </Nav.Link>

                
               <Nav.Link eventKey="point" as={NavLink} to="/dashboard/score">
                  <i className="mr-2 home-menu point-logo"></i>
                  <p>{t("point.My Points", { name: "TrustY" })}</p>
                  <div className="vertical-line"></div>
              </Nav.Link>
              
                <Nav.Link onClick={logout} eventKey="logout">
                  <i className="mr-2 home-menu logout-logo"></i>
                  <p>{t("public.Logout")}</p>
                </Nav.Link>
              </Nav>
            </div>
           
          </div>
          <ImageModel imgPath={imgPath} show={show} hide={handleClose} />

          <>
        {handleComponentChange()}
           
          </>
        </div>
          :""}
      </div>
  );
}

HomeUser.getInitialProps = async ({req}) => {
  if(req){
  const cookies = new Cookies(req.headers.cookie);
  if(!cookies.get("tokenTrustiT") && !cookies.get("userTrustiT"))
  return { redirectTo: '/login' };
  }
};

export default HomeUser;
