import React,{ useCallback,useContext,useEffect,useState} from "react";

import { useFormik } from "formik";
import {phoneValidationByCountry, registreValidation} from "../../schema/formValid";
import {loginApi, registerApi, smsApi, verifPhone} from "../../api/axios.js"
import { useHistory} from "react-router";
import { useTranslation } from 'react-i18next';
import ImageRegister from "./imageRegister";
import { FocusError } from 'focus-formik-error'
import { Link } from "react-router-dom";
import SelectSearch from "../../components/SelectSearch";
import { countryIcon, customStyles, handlePhoneChange, removeCountryCodeFromPhone } from "../../utils/repairState";
import { redirectUtil, setLocalStorage } from "../../utils/auth";
import { GoogleLogin } from "@react-oauth/google";
import GoogleSignIn from "../../components/GoogleSignIn";
import SEO from "../../components/SEO";
import { useSelector } from "react-redux";
import PhoneComponent from "../../components/PhoneComponent";
import PhoneRegister from "./phoneRegister";
import StepperComponent from "../../components/stepperComponent";
import SpinnerCirculer from "../../components/SpinnerCirculer";
import { useDispatch } from "react-redux";
import { EnableOrDisable } from "../../store/Actions/disabeViewAction";


const Register=()=>{

  const detection= useSelector((state) => state.countryDetected)
  const {t,i18n} = useTranslation("common");
  const [message,setMessage]=useState([]);
  const [countryValue,setCountryValue]=useState(detection.detectionData.hasOwnProperty("value")?detection.detectionData:{});
  const country= useSelector((state) => state.country.countryData)
  const [registerType,setRegisterType]=useState("byemail");
  const [phoneEtat, setPhoneEtat] = useState(false)
  const [phoneubmited, setPhoneSubmited] = useState(false)
  const [stepCurrentVal,setStepCurrentVal]=useState(0);
  const [phoneCountry,setPhoneCountry]=useState({});
  const [codeVerif,setCodeVerif]=useState();
  const [phoneExistMessage,setPhoneExistMessage]=useState();
  const disableViewEtat= useSelector((state) => state.disableViewEtat)
  const dispatch=useDispatch();


  // function called in register by phone that verif the phone number  

  const handleSubmitRegister=()=>{
    const phoneNumber=formik.values.phone;
    const verifPhoneNumber=verifPhone(removeCountryCodeFromPhone(phoneNumber));
    verifPhoneNumber.then(res=>{
      console.log(res)
      if(res[0] && res[0].status==200){
      if(res[0].phoneExist==true)
      setPhoneExistMessage("Phone number already exist")
      else {
        setPhoneExistMessage("")
        setPhoneSubmited(true);
        setStepCurrentVal(1);
        const data=smsApi(phoneNumber);
        data.then(res=>{
          if(res[0] && res[0].status==200){
            setCodeVerif(res[0].message.code)
          }
        })
      }
      
      }})

}


  //register and login user if success
    const handleOnSubmit =(values) => {
      dispatch(EnableOrDisable(true));

       const {firstName,lastName,email,password,phone}=values;
       let phoneNb=phone;
        if(phone){
          phoneNb=removeCountryCodeFromPhone(phoneNb);
        }
        const data=registerApi(firstName,lastName,email,password,countryValue.value,i18n.resolvedLanguage,phoneNb);
        data.then(result =>{
          setMessage(result)
          if(result[0].status===200){
            setTimeout(()=>{
              //history.push("/login");
            //  props.enableView();
              let dataLogin=null;
              if(phoneubmited==false){   
                dataLogin=loginApi(email,password);
              }
              else {

                dataLogin=loginApi(phoneNb,password);

              }
              dataLogin.then(result=>{
                if(result[0].status===200){
                 window.location.reload();
                 //setLocalStorage("country",countryValue);
                }
                else {
                  dispatch(EnableOrDisable(false))
                }

              });

            },2500)
          }
          else {
            dispatch(EnableOrDisable(false))
          }
        });

       // history.push("/login")

      };
    
    const formik = useFormik({
        initialValues: {
          lastName: "",
          firstName: "",
          email:"",
          password:"",
          confirmpassword:"",
          phone:"",

        },
        validationSchema: registerType=="byemail"?registreValidation(t,phoneEtat):phoneValidationByCountry(t,phoneEtat),
        onSubmit: registerType=="byemail"?handleOnSubmit:handleSubmitRegister,
        validateOnChange:false,

      });

      const setInputValue = useCallback(
        (key, value) =>
          formik.setValues({
            ...formik.values,
            [key]: value,
          }),
        [formik]
      );

      const handleChange=(e)=>{
        setCountryValue(e);
      
      }

      //register type switcher (by email or phone)
      const handlePhoneRegister=()=>{
        if(registerType==="byemail"){
          formik.resetForm();
          if(stepCurrentVal!=2){
          setRegisterType("byphone")
          }
          setMessage([]);
          setStepCurrentVal(0)
          if(stepCurrentVal==2){
            setPhoneEtat(false)
            setPhoneSubmited(false)
          }
        }
        else{
        formik.resetForm();
        setRegisterType("byemail")
        setPhoneSubmited(false)
        setPhoneEtat(false)
        setStepCurrentVal(0)
        
      }
      }

      useEffect(()=>{
        if(phoneCountry && phoneCountry.hasOwnProperty("value") && phoneCountry.hasOwnProperty("label")){
          setCountryValue(phoneCountry)
        }
      },[phoneCountry])

    return(

        <div className="inscrption-container container">
            <SEO 
            title={t('public.register',{name:process.env.RAZZLE_REACT_APP_NAME})}/>
            <h1 className="text-header">{t('SeoBody.register h1')}</h1>

            <div className="row">
            <ImageRegister translate={t} name="imaged"/>

        <div className="col-lg-6 col-md-6">
            {registerType=="byphone" || phoneubmited==true?
            <h3 className="title-form">{t('register.Register by phone')}</h3>         
            :
            <h3 className="title-form">{t('register.Create a new account')}</h3>
            }
            <div className="topheader-register ml-2 mr-2 social-icon d-flex">
            {registerType=="byemail" && phoneubmited==false?
            <GoogleSignIn lang={i18n.resolvedLanguage} translate={t}/>
            :""}
            <div className="ml-2 mr-2 register-switch">
            <i onClick={handlePhoneRegister} className=" phone-register"></i>
            </div>
            {registerType=="byphone" || phoneubmited==true?            
            <StepperComponent stepCurrentVal={stepCurrentVal}/>
            :""}
            </div>

            <form noValidate onSubmit={formik.handleSubmit}>
            <FocusError formik={formik} />
            {registerType==="byphone"?
            <>
            <PhoneRegister phoneExistMessage={phoneExistMessage} codeVerif={codeVerif} setCodeVerif={setCodeVerif} phoneNumber={formik.values.phone} setPhoneCountry={setPhoneCountry} setStepCurrentVal={setStepCurrentVal} setRegisterType={setRegisterType} phonesubmited={phoneubmited} setInputValue={setInputValue} setPhoneEtat={setPhoneEtat} phoneEtat={phoneEtat} formik={formik} translate={t}/>
          </>
            :
            <>
            <div className="form-group">
                <input  type="text" className={formik.errors.lastName?"form-control is-invalid":"form-control"} name="lastName" placeholder={t('input:placeholder.lastName')} onChange={(e) => setInputValue("lastName", e.target.value)}/>
                <small className="text-error">{formik.errors.lastName}</small>            
            </div>
            <div className="form-group">
                <input type="text" className={formik.errors.firstName?"form-control is-invalid":"form-control"} name="firstName" placeholder={t('input:placeholder.firstName')} onChange={(e) => setInputValue("firstName", e.target.value)}/>
                <small className="text-error">{formik.errors.firstName}</small>            

            </div>
        
            <div className="form-group">
                <input type="text" className={formik.errors.email ||!formik.errors.email&& message && message.length>0 && message[0].status===409 ?"form-control is-invalid":"form-control"} name="email" placeholder={t('input:placeholder.Email')} onChange={(e) => setInputValue("email", e.target.value)}/>
                <small className="text-error">{formik.errors.email}</small>
                <small className="text-error">{!formik.errors.email && message && message.length>0 && message[0].status===409 ?t('register.'+message[0].message):""}</small>

            </div>
            <div className="form-group">

            <SelectSearch isSearchable={false} customStyles={customStyles()} components={{ Option: countryIcon(i18n.resolvedLanguage) }} change={handleChange} value={countryValue} placeholder={t('input:placeholder.Select country')} option={country!=null && country.length>0 ? country :[]} name="country"/>
            </div>


            <div className="form-group">
                <input type="password" className={formik.errors.password?"form-control is-invalid":"form-control"} name="password" placeholder={t('input:placeholder.Password')} onChange={(e) => setInputValue("password", e.target.value)}/>
                <small className="text-error">{formik.errors.password}</small>            

            </div>
            
            
                <div className="form-group">
                <input type="password" className={formik.errors.confirmpassword?"form-control is-invalid":"form-control"} name="confirmpassword" placeholder={t('input:placeholder.Confirm new password')} onChange={(e) => setInputValue("confirmpassword", e.target.value)}/>
                <small className="text-error">{formik.errors.confirmpassword}</small>            

            </div>
            
                <p className="mt-4 mb-2">
                {t('register.By clicking on “Create an account”')}
                <a href={require('../../assets/file/cgs_trustit_fr.pdf')} target="_blank">{t('public.I accept the terms of use and privacy policy')}</a>
             
            </p>
          

            <div className="row">

            <div className="mt-2 col text-center">
            <small className="text-success">{message && message.length>0 && message[0].status===200 ? t('register.'+message[0].message) :''}</small>         
            </div>

            </div>

            <div className="row">

            <div className="mt-3 col text-center">
            <button style={{opacity:disableViewEtat==true?"55%":""}} type="submit" className="save-profile btn btn-primary">
            {disableViewEtat==true && message && message.length>0 && message[0].status===200?
            <SpinnerCirculer authSpinner={true}/>
            :""}
              {t('register.Create an account')}

              </button>
            
            </div>
            

            
            </div>

            </>
            }



            </form>

                </div>
                <ImageRegister translate={t} name="imagem"/>

            </div>
            </div>

    );

}

Register.getInitialProps = async ({req}) => {
  if(req){
  if(redirectUtil(req))
  return { redirectTo: '/dashboard/reparation' };
  }
};



export default Register;