import React,{useState} from "react";

import { useTranslation } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Information from "./Information";
import Changepassword from "./changePassword";

function Profile(){

  const {t,i18n} = useTranslation("common");
  const [dkey,setKey] = useState("Information");

  // panel key changer
  const handleSelect=(key)=>{
    setKey(key)
  }

  // return panel to initial key 
  const keyChangeProps=()=>{
    setKey("Information");
  }

    return(
 
        <div className="col-lg-6 col-md-7">

            <h3 className="title-form">{t('public.My profile')}</h3>

              <Tabs onSelect={(key)=>handleSelect(key)} activeKey={dkey} defaultactiveKey="Information" className="mb-4">
               <Tab eventKey="Information" title={t('public.Information')}>
                <Information translate={t}/>
              </Tab>
              {/*<Tab eventKey="Address" title={t('public.Address')}>
               <ProfileAddress resetTab={keyChangeProps} resolvedLanguage={i18n.resolvedLanguage} translate={t}/>
             </Tab>
    */}

              <Tab eventKey="passwordChange" title={t('input:placeholder.Password')}>
               <Changepassword resetTab={keyChangeProps} translate={t}/>
              </Tab>
      
            </Tabs>
            

            </div>
         
    );
}

export default Profile;