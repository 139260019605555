import React from "react";
import Slider from "../../components/Slider.js";
import StaticCard from "../../components/StaticCard";

function Partner(){

    return(
        <StaticCard title="Partners"
        text={<Slider hide="true"/>}
        />
    );
}
export default Partner;