import React, { useCallback, useState } from "react";
import { packValidation } from "../schema/formValid";
import { useFormik } from "formik";
import { packdApi } from "../api/axios";
import { FocusError } from "focus-formik-error";
import PhoneComponent from "./PhoneComponent";
import { handlePhoneChange } from "../utils/repairState";
import { useSelector } from "react-redux";

function PackForm(props) {
  const [message, setMessage] = useState([]);
  const [phoneEtat, setPhoneEtat] = useState(true);
  const detection = useSelector((state) => state.countryDetected);

  const handleOnSubmit = (values) => {
    const detectedCountryId = detection.detectionData.value;
    const {
      firstName,
      lastName,
      email,
      position,
      nomEntreprise,
      typeDevis,
      phone,
    } = values;

    // save devis for pack trustiT

    const data = packdApi(
      firstName,
      lastName,
      email,
      position,
      "",
      nomEntreprise,
      "",
      "",
      typeDevis,
      phone,
      "",
      detectedCountryId
    );
    data.then((result) => {
      setMessage(result);
      if (result[0].status === 200) {
        formik.resetForm();
        setTimeout(() => {
          setMessage([]);
        }, 10000);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      lastName: "",
      firstName: "",
      nomEntreprise: "",
      position: "",
      phone: "",
      email: "",
      typeDevis: "",
    },
    validationSchema: packValidation(props.translate, phoneEtat),
    onSubmit: handleOnSubmit,
    validateOnChange: false,
  });

  const setInputValue = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <FocusError formik={formik} />
      <div className="form-row">
        <div className="form-group col-md-6">
          <input
            value={formik.values.lastName}
            type="text"
            className={
              formik.errors.lastName
                ? "form-control is-invalid"
                : "form-control"
            }
            name="lastName"
            placeholder={props.translate("input:placeholder.lastName")}
            onChange={(e) => setInputValue("lastName", e.target.value)}
          />
          <small className="text-error">{formik.errors.lastName}</small>
        </div>

        <div className="form-group col-md-6">
          <input
            value={formik.values.firstName}
            type="text"
            className={
              formik.errors.firstName
                ? "form-control is-invalid"
                : "form-control"
            }
            name="firstName"
            placeholder={props.translate("input:placeholder.firstName")}
            onChange={(e) => setInputValue("firstName", e.target.value)}
          />
          <small className="text-error">{formik.errors.firstName}</small>
        </div>

        <div className="form-group col-md-12">
          <input
            value={formik.values.nomEntreprise}
            type="text"
            className={
              formik.errors.nomEntreprise
                ? "form-control is-invalid"
                : "form-control"
            }
            name="nomEntreprise"
            placeholder={props.translate("input:placeholder.Company Name")}
            onChange={(e) => setInputValue("nomEntreprise", e.target.value)}
          />
          <small className="text-error">{formik.errors.nomEntreprise}</small>
        </div>
        <div className="form-group col-md-12">
          <input
            value={formik.values.position}
            type="text"
            className={
              formik.errors.position
                ? "form-control is-invalid"
                : "form-control"
            }
            name="position"
            placeholder={props.translate("input:placeholder.Poste")}
            onChange={(e) => setInputValue("position", e.target.value)}
          />
          <small className="text-error">{formik.errors.position}</small>
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <PhoneComponent
            formikError={formik.errors.phone}
            value={formik.values.phone}
            change={(e) => handlePhoneChange(e, setInputValue, setPhoneEtat)}
            translate={props.translate}
          />
          <small className="text-error">{formik.errors.phone}</small>
        </div>

        <div className="form-group col-md-6">
          <input
            value={formik.values.email}
            type="email"
            className={
              formik.errors.email ? "form-control is-invalid" : "form-control"
            }
            name="email"
            placeholder={props.translate("input:placeholder.Email")}
            onChange={(e) => setInputValue("email", e.target.value)}
          />
          <small className="text-error">{formik.errors.email}</small>
        </div>
      </div>

      <p>{props.translate("devis.Type of offer")}</p>

      <div className="form-check mb-1">
        <label className="form-check-label">
          <input
            type="radio"
            className="form-check-input"
            name="typeDevis"
            value="Plution pack  a 1500"
            onChange={(e) => setInputValue("typeDevis", e.target.value)}
          />
          Plution pack a 1500
        </label>
      </div>

      <div className="form-check mb-1">
        <label className="form-check-label">
          <input
            type="radio"
            className="form-check-input"
            name="typeDevis"
            value="Uranus pack a 2200"
            onChange={(e) => setInputValue("typeDevis", e.target.value)}
          />
          Uranus pack a 2200
        </label>
      </div>

      <div className="form-check mb-1">
        <label className="form-check-label">
          <input
            type="radio"
            className="form-check-input"
            value="Juptier pack a 3000"
            name="typeDevis"
            onChange={(e) => setInputValue("typeDevis", e.target.value)}
          />
          Juptier pack a 3000
        </label>
      </div>
      <div className="row">
        <div className="col">
          <small className="w-100 text-error">{formik.errors.typeDevis}</small>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <small className="text-success">
            {message && message.length > 0 && message[0].status === 200
              ? props.translate("home." + message[0].message)
              : ""}
          </small>
        </div>
      </div>

      <button type="submit" className="mt-3 mb-5 btn btn-primary">
        {props.translate("public.Send")}
      </button>
    </form>
  );
}
export default PackForm;
