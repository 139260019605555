import React,{useCallback, useState} from "react";
import imgauth from "../../assets/image/authentification/authentifcation-img.png";
import ButtonLink from "../../components/ButtonLink.js";
import {loginValidation} from "../../schema/formValid";
import { useFormik } from "formik";
import {loginApi} from "../../api/axios.js";
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import { trackPromise} from 'react-promise-tracker';
import { FocusError } from 'focus-formik-error'
import GoogleSignIn from "../../components/GoogleSignIn";
import SEO from "../../components/SEO";
import { redirectUtil } from "../../utils/auth";
import PhoneComponent from "../../components/PhoneComponent";
import { handlePhoneChange, removeCountryCodeFromPhone } from "../../utils/repairState";
import { useDispatch } from "react-redux";
import { EnableOrDisable } from "../../store/Actions/disabeViewAction";
import { useSelector } from "react-redux";
import SpinnerCirculer from "../../components/SpinnerCirculer";

const Login = () => {

  const {t,i18n} = useTranslation("common");

    const [message,setMessage]=useState([]);
    const [loginType,setLoginType]=useState("byemail");
    const [phoneEtat, setPhoneEtat] = useState(false)
    const dispatch=useDispatch();
    const disableViewEtat= useSelector((state) => state.disableViewEtat)

    
    // submit form 
    const handleOnSubmit =(values) => {
        const {email,phone,password}=values;
        let username=email;
        dispatch(EnableOrDisable(true))
        if(loginType=="byphone"){
          username=removeCountryCodeFromPhone(phone);
        }
        let data=loginApi(username,password) 
        data.then(result =>{
          
          setMessage(result)
          if(result[0].status===200){
            setTimeout(() => {
              window.location.reload();

            }, 900);
          } 
          else {
            dispatch(EnableOrDisable(false))


          }
        });
      
            
       }

       const formik = useFormik({
        initialValues: {
          email: "",
          phone:"",
          password: "",
        },
        validationSchema: loginValidation(t,phoneEtat,loginType),
        onSubmit: handleOnSubmit,
        validateOnChange:false,

      });

      const setInputValue = useCallback(
        (key, value) =>
          formik.setValues({
            ...formik.values,
            [key]: value,
          }),
        [formik],
      );

 // login type switcher

const handlePhoneRegister=()=>{
  if(loginType=="byemail"){
    setLoginType("byphone")
  }
  else {
    setLoginType("byemail")
  }
  setPhoneEtat(false)
  formik.resetForm();

}


    return(
    <div className="login-container container">
          <SEO 
            title={t('public.Connection',{name:process.env.RAZZLE_REACT_APP_NAME})}
            
           />
        <div className="row">
        <div className="col-lg-5">
        <h3 className="title-form">{t('public.Already have an account')}</h3>
       
        <div className="mb-4 social-icon d-flex">
            <GoogleSignIn lang={i18n.resolvedLanguage} translate={t}/>
            <div className="ml-2 mr-2 register-switch">
            <i onClick={handlePhoneRegister} className="phone-register"></i>
            </div>
            </div>

        {/*t('input:validation.Email is required')*/}
            <form noValidate onSubmit={formik.handleSubmit}>
            <FocusError formik={formik} />
            {loginType=="byemail"?
            <div className="form-group">
                <input type="text" className={formik.errors.email?"form-control is-invalid":"form-control"} name="email" placeholder={t('input:placeholder.Email')} onChange={(e) => setInputValue("email", e.target.value)}/>
                <small className="text-error">{formik.errors.email}</small>            

            </div>
            :
            <div className="login-phone form-group">
            <PhoneComponent change={(e)=>handlePhoneChange(e,setInputValue,setPhoneEtat)} formikError={formik.errors.phone} value={formik.values.phone} translate={t}/>
            <small className="text-error">{formik.errors.phone}</small>            
            </div>
            }
            <div className="form-group">
                <input type="password" className={formik.errors.password?"form-control is-invalid":"form-control"} name="password" placeholder={t('input:placeholder.Password')} onChange={(e) => setInputValue("password", e.target.value)}/>
                <small className="text-error">{formik.errors.password}</small>            

            </div>
            
            <div className="row">

            <div className="col text-center">
            <small className="text-success">{!formik.errors.email && !formik.errors.password && message && message.length>0 && message[0].status===200 ?t('login.'+message[0].message) :''}</small>         
            <small className="text-error">{!formik.errors.email && !formik.errors.password && message && message.length>0 && message[0].status===404 ?t("input:validation."+message[0].message) :''}</small>         
            </div>
            </div>

            <div className="row">

            <div className="mt-3 col text-center">
           
            <button style={{opacity:disableViewEtat==true?"55%":""}} type="submit" className="mb-3 btn btn-primary">
            {disableViewEtat==true && message && message.length>0 && message[0].status===200?
            <SpinnerCirculer authSpinner={true}/>
            :""}
              {t('public.Connection')}

              </button>

            </div>

            
            </div>      
            <div className="row">

                <div className="mb-3 col text-center">
                <Link to="login/reset">{t('login.Forgot your password')}</Link>
                </div>
                </div>
   
            </form>

            </div>

                <div className="col-lg-2">

                </div>

                <div className="col-lg-5">
                <figure className="text-center justify-content-center">
                <img src={imgauth}/>
                <figcaption>
                {t('login.Enter your details and start an adventure with us!')}
                </figcaption>
                </figure>

                <div className="col-lg-12 text-center btn-class justify-content-center">
                <ButtonLink  path="/register" name={t('public.Register')}/>
                </div>             
                </div>

</div>
</div>

    );

}

Login.getInitialProps = async ({req,res}) => {
  if(req){
    if(redirectUtil(req))
    return { redirectTo: '/dashboard/reparation' };
  }
};

export default Login;
