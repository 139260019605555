import React from "react";
import btnimg from "../assets/icon/input-button.svg";

function PrependInput(props){
    
    const handleOnKeyDown=(e)=>{
        if(e.key==="Enter"){
        e.preventDefault()
        if(props.formik)
        props.formik.handleSubmit()
        }

    }
    return(

    <form noValidate>
        <div className="mb-4 form-group">
        <div className="input-group-prepend">
            <input value={props.value} onKeyDown={(e) => handleOnKeyDown(e)} type="text" className="form-control" placeholder={props.placeholderText} onChange={props.change}/>
            <span onClick={props.fname} className="input-group-text button-input"><img src={btnimg}></img></span>
        </div>
        <small className="text-error">{props.errorFormik}</small>  
        <small className="text-error">{props.backendMessage}</small>         
        <small style={{color:"green"}} className="">{props.backendMessage2}</small>         

        </div>        
    </form>

    );
}

export default PrependInput;