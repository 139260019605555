import React from "react";
import moment from "moment";
import 'moment/locale/fr';
import appLogo from "../../assets/icon/appLogo.svg"
import { fetchBlogSlug } from "../../store/Actions/blogSlugAction";
import { useSelector } from "react-redux";
import SpinnerLoad from "../../components/SpinnerLoad";
import SEO from "../../components/SEO";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

const BlogInfo=(props)=>{

    const blogInfoLists= useSelector((state) => state.blogSlug.blogSlugData)
    const { isLoading } =props;
    const {t,i18n} = useTranslation("common");
    const history=useHistory();

    const blogSugesttion=()=>{
        if(blogInfoLists && blogInfoLists.posts && blogInfoLists.posts.length>0){
            return(
                <>
                <div className="row container-fluid">
                <div className="mt-5 col-lg-12">
                <p className="text-center" >{t('public.We suggest you also read')}</p>
                </div>
                </div>

                <div className="blog-container row">
                <div className="mt-4 mb-4 col-lg-12">
                <div className="justify-content-center card-deck">
                
                {blogInfoLists.posts.slice(0,3).map((blogInfoListsData,index)=>
                    <div key={index} className="col-md-6 col-lg-4 col-sm-6 col-xs-6">
                
                  <div className="mb-3 card" onClick={()=>handleSlug(blogInfoListsData.slug)}>
                    <img className="card-img-top" src={process.env.RAZZLE_REACT_APP_BASE_URL+"/"+blogInfoListsData.cover}/>
                    <div className="card-body">
                      <h5 className="card-title">{blogInfoListsData.title}</h5>
                      <p className="card-text"><small className="text-muted">{blogInfoListsData.description}</small></p>
                    </div>
                  </div>
                  </div>  
                )}              
                 
                  </div>
                  </div>
                </div>
                </> 
                
            )
        }
    }

    // go to blog article detail
    const handleSlug=(title)=>{
        history.push(`/${i18n.resolvedLanguage}/blog/detail/`+title);
        
    }

  
    return(
        isLoading===false?
        <div className="blog-info container">

            <SEO 
            title={t('seoContent.title blog',{name:process.RAZZLE_REACT_APP_NAME})}
            description=""
   />
        <div className="margin-space row">
        <div className="col-lg-6">
        <img width="100%" height="340px" src={process.env.RAZZLE_REACT_APP_BASE_URL+blogInfoLists.cover}/>
        </div>
        <div className="col-lg-5">
        <h3 className="title-form">{blogInfoLists.title}</h3>
        <img width="50px" height="50px" src={appLogo}/>
        <p className="mt-4">par TrustiT- {moment(blogInfoLists.created_at).format("DD MMMM YYYY")}
        </p>
        </div>


        <div className="row container blog-details-desc">
        <div className="col-lg-12 mt-3 article-content">
        <div dangerouslySetInnerHTML={{ __html:blogInfoLists.content }} />
        </div>

            </div>
            </div>
            {blogSugesttion()}
            </div>

            
        :<SpinnerLoad/>
    );
}

BlogInfo.getInitialProps = async ({store,match}) => {
    return store.dispatch(fetchBlogSlug(match.params.slug));

  };


export default BlogInfo;