  import React,{useCallback, useEffect, useState} from "react";
import { loginValidation, paymentValidation } from "../../schema/formValid";
import { useFormik } from "formik";
import { useTranslation } from 'react-i18next';
import { FocusError } from 'focus-formik-error'
import PhoneComponent from "../../components/PhoneComponent";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { handlePhoneChange } from "../../utils/repairState";
import { useSelector } from "react-redux";
import { approveApi, backendApiPaymentCheck, invoicePayment, paymentCheckApi, paymentCreateApi ,payTabsFrom} from "../../api/axios";
import Stepper from "./stepper";
import StepperTbas from "./stepperTabs";

import { useHistory, useLocation } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import axios  from "axios";


 

const Payment=()=>{

 // payTabsFrom.redirect_url
 const [redirectUrl,setRedirectUrl]=useState()
    const {t} = useTranslation("common");
    const [message,setMessage]=useState([]);
    const [phoneEtat, setPhoneEtat] = useState(false);
    const [token,setToken] = useState();
    const [stepCurrentVal,setstepCurrentVal] = useState(0);
    const [check,setcheck] = useState(false);
    const paymentData= useSelector((state) => state.paymentApp.infoPayment)
    const user= useSelector((state) => state.user)
    const detection = useSelector((state) => state.countryDetected);
console.log(detection.code);

// event lister for payment when event_id became complete the steper will go to next step and another useffect bellow will be called
useEffect(()=>{
  window.addEventListener('message', function(event) {
    
    if(event.data.event_id === 'paymee.complete') {
        //Execute Step 3
        setstepCurrentVal(3);
        setcheck(true)
    }
}, false);

},[token])

// declanched if check is true and after first useffect it check payment ok or note and approve the reparation and send the invoice
useEffect(()=>{
  if(check==true){
    //window.history.replaceState({}, document.title)
    window.scrollTo(0,0)
    const data=paymentCheckApi(token);
  data.then(res=>{
    if(res[0] && res[0].message.payment_status==true){
      backendApiPaymentCheck(token,user.userData.token,paymentData.id,res[0].message.transaction_id).then(res=>{
        if(res[0].status===200){
          //console.log(paymentData.codeEtat)
          approveApi(paymentData.id,paymentData.codeEtat,user.userData.token);
          invoicePayment(user.userData.token,user.userData.email,user.userData.fname,user.userData.lname,user.userData.phone,paymentData.name,paymentData.codeRepair,paymentData.total,true)
        }
      })
    }

    else{
    setTimeout(() => {
      window.location.replace(document.location.origin+"/dashboard/profil");
    }, 2000);
    }

   
   setMessage(res)
  })}

},[check])


    const handleOnSubmit =async (values) => {
        const {firstName,lastName,email,phone,note}=values;
        const amount=paymentData.total;
        console.log(user.userData.token);
        //useStat for URL PayTabs
        const res = await payTabsFrom(user.userData.token,paymentData.id)
        setRedirectUrl(res.data.redirect_url)
        // if payment in delivry approve directly the reparation
        if(paymentData.typePayment){
          setstepCurrentVal(3)

          const data=approveApi(paymentData.id,paymentData.codeEtat,user.userData.token);
          data.then(res=>{
            setMessage(res);
            invoicePayment(user.userData.token,user.userData.email,user.userData.fname,user.userData.lname,user.userData.phone,paymentData.name,paymentData.codeRepair,paymentData.total,false)
          })

        }
        else {

          // if payment online create payment token for checkout
        const data=paymentCreateApi(firstName,lastName,email,phone,note,amount)
        data.then(res=>{
            if(res[0]&& res[0].token){
            let token=res[0].token;
            if(token){
            setToken(token)
            setstepCurrentVal(1)
            }}
        })  
        }
    
    }

    useEffect(()=>{
        if(user && user.userData.phone){
            setPhoneEtat(true)
        }
    },[])

    // get initial data from user
    const formik = useFormik({
        initialValues: {
            firstName: user.userData.fname,
            lastName: user.userData.lname,
            email: user.userData.email,
            phone: user.userData.phone?user.userData.phone:"",
            note:"",
                
        },
        validationSchema: paymentValidation(t,phoneEtat),
        onSubmit: handleOnSubmit,
        validateOnChange:false,

      });

      const setInputValue = useCallback(
        (key, value) =>
          formik.setValues({
            ...formik.values,
            [key]: value,
          }),
        [formik],
      );

      // render Tunisia view by steper
      const renderByStep=()=>{

        if(stepCurrentVal==0){
          return(
      <div className="row">
        <div className="col-lg-12">
        <form noValidate onSubmit={formik.handleSubmit}>
        <div className="form-row">
      <div className="form-group col-md-6">
      <input name="lastName" value={formik.values.lastName}  type="text" className={formik.errors.lastName?"form-control is-invalid":"form-control"} placeholder={t('input:placeholder.lastName')} onChange={(e) => setInputValue("lastName", e.target.value)}/>
      <small className="text-error">{formik.errors.lastName}</small>

    </div>

    <div className="form-group col-md-6">
      <input  name="firstName" value={formik.values.firstName} type="text" className={formik.errors.firstName?"form-control is-invalid":"form-control"} placeholder={t('input:placeholder.firstName')} onChange={(e) => setInputValue("firstName", e.target.value)}/>
      <small className="text-error">{formik.errors.firstName}</small>

    </div>
   
  </div>
   <div className="form-row">
    <div className="form-group col-md-6">
      <input name="email" value={formik.values.email} type="email" className={formik.errors.email?"form-control is-invalid":"form-control"}  placeholder={t('input:placeholder.Email')} onChange={(e) => setInputValue("email", e.target.value)}/>
      <small className="text-error">{formik.errors.email}</small>
    </div>
    <div className="form-group col-md-6">
    <PhoneComponent formikError={formik.errors.phone} value={formik.values.phone} change={(e)=>handlePhoneChange(e,setInputValue,setPhoneEtat)} translate={t}/>
      <small className="text-error">{formik.errors.phone}</small>            
      </div>
  </div>
  
  <div className="form-group">
      <textarea name="note" value={formik.values.note} className={formik.errors.note?"form-control is-invalid":"form-control"} rows="3" placeholder={t('public.Notes')} onChange={(e) => setInputValue("note",e.target.value)}></textarea>
      <small className="text-error">{formik.errors.note}</small>
      <small className="text-error">{formik.errors.note}</small>
  </div>
    

<div class="d-flex justify-content-end">
<div class="mb-3"><strong>{paymentData.name}</strong></div>
</div>

<div class="d-flex justify-content-end total-row">
  <div class="mb-4"><strong>{t('public.Total')} : {paymentData.total+" "+paymentData.curency}</strong></div>
</div>


<button type="submit" className="btn btn-primary mt-3">
  {!paymentData.typePayment?
  t('public.Send')
  :t('public.Pay on delivery')}
  </button>

</form>
    </div>
    </div>
    
          )
        }
        else if(stepCurrentVal==1 && !paymentData.typePayment){
          return(
          <div className="row">
        <iframe scrolling="no" style={{border:"0",width:"100%",height:"700px"}} src={"https://sandbox.paymee.tn/gateway/"+token} title="W3Schools Free Online Web Tutorials"></iframe>
        </div>
        );
        }
        else if(stepCurrentVal==3) {
          return(
            <>
            
          <div className="justify-content-center text-center row">
        <div className="col">
        {
        message && message.length>0 && message[0].status===200 && message[0].message.payment_status==true ||message && message.length>0 && message[0].status===200 && paymentData.typePayment  ? 
        <Alert variant="success">
        {!paymentData.typePayment?
        <>
        <p className="mt-3">{t('public.Your payment is successful')}</p>
        <p>{t('public.The order invoice has been sent to your email')}</p>
        </>
        :
        t('etat.'+message[0].message)    
        }   
        </Alert>
        :""}
        {message && message.length>0 && message[0].status===200 && message[0].message.payment_status==false  ?
        <Alert variant="danger">
          {t('public.Your payment is cancelled')}       
   
        </Alert>
        :""}
        </div>
        </div>
            </>
          )
        }

      }
      // render Saudi aribia view by step 
      const renderKsa=()=>{
        if(stepCurrentVal==0){
          return(
      <div className="row">
        <div className="col-lg-12">
        <form noValidate onSubmit={
       formik.handleSubmit} >
        <div className="form-row">
      <div className="form-group col-md-6">
      <input name="lastName" value={formik.values.lastName}  type="text" className={formik.errors.lastName?"form-control is-invalid":"form-control"} placeholder={t('input:placeholder.lastName')} onChange={(e) => setInputValue("lastName", e.target.value)}/>
      <small className="text-error">{formik.errors.lastName}</small>

    </div>

    <div className="form-group col-md-6">
      <input  name="firstName" value={formik.values.firstName} type="text" className={formik.errors.firstName?"form-control is-invalid":"form-control"} placeholder={t('input:placeholder.firstName')} onChange={(e) => setInputValue("firstName", e.target.value)}/>
      <small className="text-error">{formik.errors.firstName}</small>

    </div>
   
  </div>
   <div className="form-row">
    <div className="form-group col-md-6">
      <input name="email" value={formik.values.email} type="email" className={formik.errors.email?"form-control is-invalid":"form-control"}  placeholder={t('input:placeholder.Email')} onChange={(e) => setInputValue("email", e.target.value)}/>
      <small className="text-error">{formik.errors.email}</small>
    </div>
    <div className="form-group col-md-6">
    <PhoneComponent formikError={formik.errors.phone} value={formik.values.phone} change={(e)=>handlePhoneChange(e,setInputValue,setPhoneEtat)} translate={t}/>
      <small className="text-error">{formik.errors.phone}</small>            
      </div>
  </div>
  
  <div className="form-group">
      <textarea name="note" value={formik.values.note} className={formik.errors.note?"form-control is-invalid":"form-control"} rows="3" placeholder={t('public.Notes')} onChange={(e) => setInputValue("note",e.target.value)}></textarea>
      <small className="text-error">{formik.errors.note}</small>
      <small className="text-error">{formik.errors.note}</small>
  </div>
    

<div class="d-flex justify-content-end">
<div class="mb-3"><strong>{paymentData.name}</strong></div>
</div>

<div class="d-flex justify-content-end total-row">
  <div class="mb-4"><strong>{t('public.Total')} : {paymentData.total+" "+paymentData.curency}</strong></div>
</div>


<button type="submit" className="btn btn-primary mt-3" >
  {!paymentData.typePayment?
  t('public.Send')
  :t('public.Pay on delivery')}
  </button>

</form>
    </div>
    </div>
    
          )
        }
        else if(stepCurrentVal==1 && !paymentData.typePayment){
          return(
          <>
          <iframe scrolling="no" style={{border:"hidden",width:"100%",height:"1000px",}} src={redirectUrl} title="PaytabsFormulaire"></iframe>
          
          </>
        );
        }

    

      }



    return(
 
    <>
    
    {/* payment Saudi aribia */}
    {paymentData && paymentData.total && paymentData.curency && detection.detectionData.code =='SA'?
        <div className="container payment-checkout">
            <h2 className="title-form mb-0">
            {!paymentData.typePayment?
              t('public.Payment Checkout')
              :t('public.Payment on delivery')} 
              {" "+paymentData.codeRepair}</h2>
            <div className="mb-5 mt-5 row justify-content-center">
            <StepperTbas typePayment={paymentData.typePayment} stepCurrentVal={stepCurrentVal} translate={t}/>
            
            </div>
              {renderKsa()}
    </div>
:""}

{/* // payment tunsia */}
    {paymentData && paymentData.total && paymentData.curency && detection.detectionData.code =='TN'?
        <div className="container payment-checkout">
            <h2 className="title-form mb-0">
            {!paymentData.typePayment?
              t('public.Payment Checkout')
              :t('public.Payment on delivery')} 
              {" "+paymentData.codeRepair}</h2>
            <div className="mb-5 mt-5 row justify-content-center">
            <Stepper typePayment={paymentData.typePayment} stepCurrentVal={stepCurrentVal} translate={t}/>
            
            </div>
              {renderByStep()}
    </div>
:""}

{/* // payment united arab emirates */}
    {paymentData && paymentData.total && paymentData.curency && detection.detectionData.code =='UE'?
        <div className="container payment-checkout">
            <h2 className="title-form mb-0">
            {!paymentData.typePayment?
              t('public.Payment Checkout')
              :t('public.Payment on delivery')} 
              {" "+paymentData.codeRepair}</h2>
            <div className="mb-5 mt-5 row justify-content-center">
            <Stepper typePayment={paymentData.typePayment} stepCurrentVal={stepCurrentVal} translate={t}/>
            
            </div>
              {renderByStep()}
    </div>
:""}
      </>
    
    );
}
Payment.getInitialProps = async ({req,res,store}) => {
  if(req){
    if(store.getState().paymentApp){
      return { redirectTo: '/dashboard/reparation'};

    }
  }
};
export default Payment;