import React,{useCallback, useContext, useEffect, useState} from "react";
import { useFormik } from "formik";
import {repairLastValidation2} from "../../schema/formValid";
import {gouvernoratApi,delegationApi,boutiqueApi,newreparationApi} from "../../api/axios.js";
import SelectSearch from "../../components/SelectSearch";
import CodeGenerated from "./codeGenerated";
import { FocusError } from 'focus-formik-error'
import {getFromLocalStorage, isAuth} from "../../utils/auth";
import PhoneComponent from "../../components/PhoneComponent";
import {handlePhoneChange, removeCountryCodeFromPhone} from "../../utils/repairState";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchSteperData } from "../../store/Actions/steperDataAction";
import { stepCurrent } from "../../store/Actions/stepCounterAction";
import { add } from "../../store/Actions/counterAction";

function FormFinal(props){

  const [phoneEtat, setPhoneEtat] = useState(true)
  const [delegation,setDelegation]=useState([]);
  const [boutique,setBoutique]=useState([]);
  const [message,setMessage]=useState([]);
  const [hasAccount,setHasAccount]=useState(false);
  const [typeDepot,setType]=useState("");
  const [boutique2,setBoutique2]=useState([]);
  const detection= useSelector((state) => state.countryDetected)
  const steperData= useSelector((state) => state.stepData.steperData)
  const user= useSelector((state) => state.user)
  let country=detection?detection.detectionData.value:"";
  const dispatch=useDispatch();

  const callBoutique=()=>{
      
      
    // call all shop by country
    const boutique=boutiqueApi(country);
    boutique.then(result=>{
      
      setBoutique(result.filter(boutiques => boutiques.label !="domicile"))
      setBoutique2(result.filter(boutiques => boutiques.label =="domicile"));
     console.log("result",result) 
    });
  }

  useEffect(() => {
    callBoutique();
    setHasAccount(false);

  },[]);

  // submit final form of reparation step

  const handleOnSubmit =(values) => {
      const {firstName,lastName,email,phone,depot,recup,recupHome}=values;
      let finalPhone=phone;
      if(finalPhone){
        finalPhone=removeCountryCodeFromPhone(finalPhone)
      }
      let newdepot=depot;
      let newrecup=recup;
      let newrecuphome=recupHome;
      console.log("data final",steperData);
      let catId="";
      if(steperData["step5"]){
        catId=steperData["step5"].id;
      }
 
      if(typeDepot!="c3"){
        newdepot=boutique2[0].value;
        newrecup=boutique2[0].value;
        console.log("centre depot",depot);
        console.log("centre recup",recup);
        console.log("new depot",newdepot);
      }
      else{
        newrecuphome="";
      }
     

      let data=newreparationApi(email,lastName,firstName,finalPhone,steperData["step6"].couleur,
      steperData["step6"].reference,steperData["step6"].detailPanne,newdepot,newrecup,catId,hasAccount,"",newrecuphome,country,typeDepot)
      data.then(result=>{
        setMessage(result);
        if(result[0].status===200){
        props.codeGeneratedProps();
        window.scrollTo(0,0);

        }
        });
    };

  
  const formik = useFormik({
      initialValues: {
        lastName: isAuth(user.userData) && user.userData.lname ? user.userData.lname:'' ,
        firstName: isAuth(user.userData) && user.userData.fname ? user.userData.fname  :"",
        email: isAuth(user.userData) && user.userData.email  ? user.userData.email:'',
        phone:isAuth(user.userData) && user.userData.phone  ? user.userData.phone:'',
        gouvernorat:"",
        delegation:"",
        recup:"",
        depot:"",
        recupHome:"",
      },

      validationSchema: repairLastValidation2(hasAccount,props.translate,typeDepot,phoneEtat),
      onSubmit: handleOnSubmit,
      validateOnChange:false,

    });

    const setInputValue = useCallback((key, value) =>

        formik.setValues({
          ...formik.values,
          [key]: value,
        }),
       
      [formik],
    );

// method to chose if user has account or no
    const handleHasAccount=()=>{
      setHasAccount(!hasAccount);
    }

    // handle the type of repation (home in shop ..)
    const handleCheck=(type)=>{
    
      setType(type)
     /* if(type!="c3"){
      setInputValue("recup",boutique2[0].value);  
      
    }*/
  
  }
   
    return(

        <div className="mt-2 col-lg-12 form-step">
            {props.codeGeneratedState===false?
            <>
            <h4 className="mb-3">{props.translate('step.Fill in your details')}</h4>
            {!isAuth(user.userData)?
            <>
            <p className="d-inline mt-3">{props.translate('step.Do you already have an account ?')}</p>   
            <p onClick={handleHasAccount} style={{cursor:"pointer",color:"#3498FF"}} className="ml-2 d-inline">{props.translate('step.Click here')}</p>   
            </>
            :""}
            <form className="mt-5" noValidate onSubmit={formik.handleSubmit}>
            <FocusError formik={formik} />
            {hasAccount===false ?<div className="form-row">
                <div className="form-group col-md-6">
                <input value={formik.values.lastName} type="text" className={formik.errors.lastName?"form-control is-invalid":"form-control"} name="lastName" placeholder={props.translate('input:placeholder.lastName')} onChange={(e) => setInputValue("lastName", e.target.value)}/>
                <small className="text-error">{formik.errors.lastName}</small>            
                </div>
                <div className="form-group col-md-6">
                <input value={formik.values.firstName} type="text" className={formik.errors.firstName?"form-control is-invalid":"form-control"}  name="firstName" placeholder={props.translate('input:placeholder.firstName')} onChange={(e) => setInputValue("firstName", e.target.value)}/>
                <small className="text-error">{formik.errors.firstName}</small>            
                </div>
        </div>
        :''}
  
        <div className="form-row">
            <div className="form-group col-md-6">
            <input value={formik.values.email} type="text" className={formik.errors.email?"form-control is-invalid":"form-control"} name="email" placeholder={props.translate('input:placeholder.Email')} onChange={(e) => setInputValue("email", e.target.value)}/>
            <small className="text-error">{formik.errors.email}</small>            
            {message.length>0 && message[0].status===401? 
            <small className="text-error">{message[0].message}</small> 
            :''}           
            </div>
            {hasAccount===false ?
            <div className="form-group col-md-6">
                <PhoneComponent formikError={formik.errors.phone} value={formik.values.phone} change={(e)=>handlePhoneChange(e,setInputValue,setPhoneEtat)} translate={props.translate}/>
                <small className="text-error">{formik.errors.phone}</small>            

            </div>
            :''}
        </div>

        <h4 className="mt-4">{props.translate('step.Method of recuperation')}</h4>

        <div className="form-row method-check">
      <div className="form-group col-md-4">
      <input type="radio" className="form-check-input"  name="typeDepot" onChange={() => handleCheck("c1")}/>{props.translate('step.Home Recovery')}

  </div>
  {detection.detectionData.code=="SA"?
  <div className="form-group col-md-4">
      <input type="radio" className="form-check-input" name="typeDepot" onChange={() =>handleCheck("c2")}/>{props.translate('step.Home Repair')}
  </div>
  :""}

  <div className="form-group col-md-4">
      <input type="radio" className="form-check-input"   name="typeDepot" onChange={() => handleCheck("c3")}/>{props.translate('step.Deposit center')}

</div>
</div>
     
{typeDepot=="c3" ?

<div className="row">
<div className="col-lg-12">
<h4 className="mt-4">{props.translate('step.Choose the deposit center closest to your address of recuperation')}</h4>

<div className="form-group">
<SelectSearch placeholder={props.translate('input:placeholder.Depot center')} option={boutique} name="depot" change={(opt,e) => setInputValue("depot", opt.value)}/>
<small className="text-error">{formik.errors.depot}</small>            


</div>
</div>


</div>
:""}

{typeDepot?
<>
    <h4 className="mt-4">
      {typeDepot!="c2"?
    props.translate('step.Choose your recuperation address')
    :
    props.translate('step.Choose your repair center')

    }</h4>
	
  {typeDepot=="c3"?
<div className="form-group">
  
<SelectSearch placeholder={
  typeDepot!="c2"?
  props.translate('input:placeholder.Recuperation center')
  :
  props.translate('input:placeholder.Repair center')

  } option={boutique} name="recup" change={(opt,e) => setInputValue("recup", opt.value)}/>
<small className="text-error">{formik.errors.recup}</small>            
</div>
:""}
{typeDepot!="c3"?

<div className="form-group">
  
<input type="text" value={formik.values.recupHome} className={formik.errors.recupHome?"form-control is-invalid":"form-control"} placeholder={props.translate('input:placeholder.Exact adress')} name="recupHome" onChange={(e) => setInputValue("recupHome", e.target.value)}/>
<small className="text-error">{formik.errors.recupHome}</small>            
</div>

:""}
<button type="submit" className="mt-2 mb-1 btn btn-primary">{props.translate('step.Validate the repair')}</button>
</>
:""}
</form>
</>
:''}
{message.length>0 && message[0].status===200?
        
<CodeGenerated lang={props.lang} translate={props.translate} code={message[0].code}/>
:''}


</div>

  
    );

}
export default FormFinal;