import React from "react";
import StaticCard from "../../components/StaticCard";
import team from "../../assets/image/static-page/team.svg";
import { useTranslation } from 'react-i18next';

function Equipe(){

    const {t} = useTranslation("common");

    const textReturn=()=>{

        return(
            <>
            <img width="100%" src={team}/>
            <p>{t('about.Our team brings together a set of experienced and complementary skills to manage the repair flow of our valued customers and ensure a lasting relationship with our valued and trusted repairers. A set of values ​​guides us to carry out our mission with complete transparency and honesty.')}</p>
            </>
        );

    }
    return(
        <div className="equipe-container">        
        <StaticCard title="Our team"
        text={textReturn()}
        />
        </div>

    );

}
export default Equipe;