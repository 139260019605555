import React,{useCallback, useState} from "react";
import ButtonLink from "../../components/ButtonLink.js";
import {passwordValidation} from "../../schema/formValid";
import { useFormik } from "formik";
import {resetApi} from "../../api/axios.js";
import { useTranslation } from 'react-i18next';
import { useParams,useHistory} from "react-router-dom";
import { redirectUtil } from "../../utils/auth.js";

const ResetPassword=(props)=>{

  const {t} = useTranslation("common");

    const [message,setMessage]=useState([]);
    const { token,tokenu} = useParams();
    const history = useHistory();


    const handleOnSubmit =(values) => {
        const {password}=values;
        const data=resetApi(tokenu,token,password,props.fromRepairer);       
       data.then(result =>{
        setMessage(result);
          setTimeout(() => {
            if(!props.fromRepairer){
           history.push("/login");
            }
          }, 6000);

      
      
      });
        //props.viewDisable();
      
            
       }

       const formik = useFormik({
        initialValues: {
          password:"",
          confirmpassword:""
        },
        validationSchema: passwordValidation(t),
        onSubmit: handleOnSubmit,
        validateOnChange:false,

      });

      const setInputValue = useCallback(
        (key, value) =>
          formik.setValues({
            ...formik.values,
            [key]: value,
          }),
        [formik],
      );

    return(
    <div className="reset-container container">
        <div className="row">
        <div className="col-lg-12">
          
        <h3 className="title-form">{t('login.Change your password')}</h3>
        {message.length===0 || message && message.length>0 && message[0].status!=200?

        <p>{t('login.Please enter your new password so that you can use the new')}</p>
            :''}

            <form noValidate onSubmit={formik.handleSubmit}>
            {message.length===0 || message && message.length>0 && message[0].status!=200?
            <>
            <div className="form-group">
                <input type="password" className={formik.errors.password?"form-control is-invalid":"form-control"} name="password" placeholder={t('input:placeholder.New Password')} onChange={(e) => setInputValue("password", e.target.value)}/>
                <small className="text-error">{formik.errors.password}</small>            

            </div>

            <div className="form-group">
                <input type="password" className={formik.errors.confirmpassword?"form-control is-invalid":"form-control"} name="rpassword" placeholder={t('input:placeholder.Confirm new password')} onChange={(e) => setInputValue("confirmpassword", e.target.value)}/>
                <small className="text-error">{formik.errors.confirmpassword}</small>            

            </div>

              </>:''}
            <div className="row">

            <div className="col text-center">
            <small className="text-success">{!formik.errors.password  && !formik.errors.confirmpassword && message && message.length>0 && message[0].status===200 ?t("public."+message[0].message) :''}</small>         
            <small className="text-error">{!formik.errors.password && !formik.errors.confirmpassword && message && message.length>0 && message[0].status!=200 ?t("public."+message[0].message) :''}</small>         
            </div>
            </div>
            
            <div className="row float-right">

            <div className="mt-3 col text-center float-right">
            {message.length===0 || message && message.length>0 && message[0].status!=200?
            <>
            <ButtonLink path="/login" name={t('public.Cancel')}/>
            <button type="submit" className="mb-3 btn btn-primary">{t('public.Validate')}</button>
             </> :<ButtonLink path="/login" name={t('public.Back')}/>}
            </div>
            </div>      
           
   
            </form>

            </div>

                <div className="col-lg-2">

                </div>


</div>
</div>



    );

}
ResetPassword.getInitialProps = async ({req}) => {
  if(req){
  if(redirectUtil(req))
  return { redirectTo: '/dashboard/reparation' };
  }

};

export default ResetPassword;