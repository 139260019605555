import React from "react";
import OffreImage from "../../components/OffreImage.js";
import Slider from "../../components/Slider.js";
import mainImage from "../../assets/image/static-page/main-image.png";
import offreInfocagnotte from "../../assets/image/static-page/offreInfocagnotte.svg";
import asavoir from "../../assets/image/static-page/asavoir.png";
import ButtonLink from "../../components/ButtonLink.js";
import { useTranslation } from "react-i18next";
import SEO from "../../components/SEO.js";

function Infocagnotte() {
  const { t,i18n} = useTranslation("common");
  const lang=i18n.resolvedLanguage;

  return (
    <>
      <div className="container containerInfocagnotte containerInfocagnotteg">
      <SEO 
            title={t('seoContent.title infocagnotte offer',{name:process.RAZZLE_REACT_APP_NAME})}
            description={t('seoContent.description infocagnotte offer',{name:process.RAZZLE_REACT_APP_NAME})}
            keywords={t('seoContent.keyword infocagnotte offer')}

           />
            <h1 className="text-header">{t('SeoBody.infocagnotte offer h1',{name:process.RAZZLE_REACT_APP_NAME})}</h1>

        <div className="row">
          <div className="col-lg-4">
            <figure>
              <img width="100%" src={offreInfocagnotte} alt={t("public.Infocagnotte offer")} />
            </figure>
          </div>

          <div className="col-lg-4">
            <h3 className="title-form">{t("public.Infocagnotte offer")}</h3>
            <p>
              {t(
                "offre.Entrust your IT equipment to a trusted IT maintenance contract provider!"
              )}
            </p>
            <ButtonLink fromCustompath={true} path={{pathname:`/${lang}/devis`,state:{"typeDevis":t("public.Infocagnotte offer")}}} name={t('public.Ask for devis')}/>

          </div>

          <div className="col-lg-4">
            <img className="handImage" src={mainImage} alt="" />
          </div>
        </div>

        <div className="row margin-space">
          <div className="col-lg-6 mt-2">
            <h3 className="title-form">
              {t("offre.IT outsourcing what you need to know")}
            </h3>
            <span>
              {t(
                "offre.IT outsourcing, also referred to as IT outsourcing, consists of delegating all or part of the management, operation, etc. of its IT system to an entity outside the company. Outsourcing makes it possible to manage this service remotely and thus take charge of your company's computer system. Any company can choose IT outsourcing, whether you are an SME, a large company, etc., outsourcing will always have its advantages. So, let's take a look together at these advantages that encourage all companies to choose outsourcing!"
              )}
            </span>
          </div>

          <div className="col-lg-1"></div>

          <div className="mt-4 col-lg-4 mb-3">
            <img width="100%" src={asavoir} alt={t("offre.IT outsourcing what you need to know")} />
            <div className="row">
              <div className="mt-4 col text-center">
                <ButtonLink path="/contact" name={t("public.Contact us")} />
              </div>
            </div>
          </div>
        </div>

        <OffreImage translate={t} />
      </div>

      <div className="container-fluid">
        <Slider translate={t} />
      </div>
    </>
  );
}

export default Infocagnotte;
