// offer bussniss dropdown

export const dropdownItem=(lang)=>{
    return [
    { name: "Business offer", link: `/${lang}/business-offer`,current: false},
    { name: "Infocagnotte offer", link: `/${lang}/infocagnotte-offer`,current: false},
    { name: "Educagnotte offer", link: `/${lang}/educagnotte-offer`,current: false},
    { name: "TrustiT Pack", link: `/${lang}/pack`,current: false},
    ]

}
