import React,{useState,useCallback} from "react";
import { useFormik } from "formik";
import {profileValidation} from "../../schema/formValid";
import { profileApi } from "../../api/axios";
import PhoneComponent from "../../components/PhoneComponent";
import {handlePhoneChange, removeCountryCodeFromPhone} from "../../utils/repairState";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchUserSuccess } from "../../store/Actions/userAction";
import { setCookie } from "../../utils/auth";

function Information(props){

    const [message,setMessage]=useState([]);
    const [phoneEtat, setPhoneEtat] = useState(true)
    const user= useSelector((state) => state.user)
    const dispatch=useDispatch();

      const handleOnSubmit =(values) => {
        const {firstName,lastName,phone}=values;
        let finalPhone=phone
        if(phone){
          finalPhone=removeCountryCodeFromPhone(phone)
        }

        // update user profile information 
       const data=profileApi(firstName,lastName,finalPhone,user.userData.token);       
       data.then(result =>{
         setMessage(result)
        if(result[0].status===200){
            let updateUser={...user.userData}
            updateUser.fname=firstName;
            updateUser.lname=lastName;
            updateUser.phone=finalPhone;
            dispatch(fetchUserSuccess(updateUser))
            setCookie("userTrustiT",updateUser)
            console.log(phone)

            setTimeout(()=>{
            setMessage([]);
            },2500)
        }
    })
       }

       const formik = useFormik({
        
        initialValues: {
          firstName: user.userData.fname,
          lastName: user.userData.lname,
          email: user.userData.email,
          phone: user.userData.phone?user.userData.phone :"",
        },
        validationSchema: profileValidation(props.translate,phoneEtat),
        onSubmit: handleOnSubmit,
        validateOnChange:false,

      });

      const setInputValue = useCallback(
        (key, value) =>
          formik.setValues({
            ...formik.values,
            [key]: value,
          }),
        [formik],
      );


    return(
        <>
        <form noValidate onSubmit={formik.handleSubmit}>
            <div className="form-group">
                <input type="text" className={formik.errors.lastName?"form-control is-invalid":"form-control"} name="lastName" placeholder={props.translate('input:placeholder.lastName')} value={formik.values.lastName} onChange={(e) => setInputValue("lastName", e.target.value)}/>
                <small className="text-error">{formik.errors.lastName}</small>            
            </div>
            <div className="form-group">
                <input type="text" className={formik.errors.firstName?"form-control is-invalid":"form-control"} name="firstName" placeholder={props.translate('input:placeholder.firstName')}  value={formik.values.firstName} onChange={(e) => setInputValue("firstName", e.target.value)}/>
                <small className="text-error">{formik.errors.firstName}</small>            
            </div>
            
            <div className="form-group">
                <input style={{pointerEvents:"none"}} type="text" className="form-control" name="email" placeholder={props.translate('input:placeholder.Email')} value={formik.values.email}/>
            </div>
            
            <div className="form-group">
                <PhoneComponent formikError={formik.errors.phone} value={formik.values.phone} change={(e)=>handlePhoneChange(e,setInputValue,setPhoneEtat)} translate={props.translate}/>
                <small className="text-error">{formik.errors.phone}</small>            

            </div>

            <div className="row">
            <div className="col text-center">
            <small className="text-success">{!formik.errors.firstName && !formik.errors.lastName && !formik.errors.phone && message && message.length>0 && message[0].status===200 ?props.translate('public.'+message[0].message) :''}</small>         
            <small className="text-error">{!formik.errors.firstName && !formik.errors.lastName && !formik.errors.phone && message && message.length>0 && message[0].status!=200 ?props.translate('public.'+message[0].message) :''}</small>         
            </div>
            </div>
            
            
            <div className="row">
                <div className="mt-3 col text-center">
            
            <button type="submit" className="save-profile btn btn-primary">{props.translate('public.Save')}</button>

            </div>
            </div>

            
            </form>
        </>
    );  
}

export default Information;