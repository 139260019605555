import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import Footer from './containers/Footer';
import Nav from "./containers/NavMenu.js";

function Layout({ children, title}) {
  const disableViewEtat= useSelector((state) => state.disableViewEtat)
  // layout customize for the view
  
  return (
    <div style={{pointerEvents:disableViewEtat==true?"none":""}}>
      <Helmet>
        <title>{title}</title>
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <header>
      <div className="mt-4 mb-5 container-fluid nav-container">
      <Nav/>
      </div>
      </header>

      <div className="container main-container">
      {children}
    </div>
    
      <div style={{padding:0}} className="container-fluid">
          <Footer/>
        </div>

    </div>
  );
}


export default Layout;