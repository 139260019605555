import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {useHistory, useLocation} from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { domicileTranslate, filterCurency, handleEtat, handleTranName, notifySuccess } from "../../utils/repairState";
import { approveApi } from "../../api/axios";
import Note from "./note";
import { useSelector } from "react-redux";
import { fetchPaymentSuccess } from "../../store/Actions/paymentAction";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useDispatch } from "react-redux";

const RepairDetail=()=>{
    
    const location=useLocation();
    const history=useHistory();
    const {t,i18n} = useTranslation("common");
    const [message,setMessage]=useState([]);
    const [callNote,setcallNote]=useState(false);
    const country= useSelector((state) => state.country.countryData)
    const user= useSelector((state) => state.user)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const dispatch=useDispatch();


// called when reparation is refused 
    const handleApprove=(id,etat)=>{
        const data=approveApi(id,etat,user.userData.token);
        data.then(result=>{
          setMessage(result);
          //console.log("testou",result)
          if(result[0].status===200){
            window.scrollTo(0, 0);
  
            notifySuccess(t('etat.'+result[0].message));
            setTimeout(() => {
              setMessage([]);
            }, 3500);
          } 
        })
      }
  
      const repairs=location.state;

      // save payment necessary data in sotre and push to the payment page

      const handlePayment=(id,codeEtat,total,curency,name,codeRepair,typePayment)=>{
        //setShow(true)
        let paymentData={"id":id,"codeEtat":codeEtat,"total":total,"name":name,"curency":curency,"codeRepair":codeRepair,"typePayment":typePayment}
        dispatch(fetchPaymentSuccess(paymentData))
        history.push("/"+i18n.resolvedLanguage+"/dashboard/reparation/payment");
    
      }

      const handleModel=()=>{
        setShow(true)
      }

    return(
      
             <>   
             
            {repairs?
              <div  style={{width:"100%",padding:"20px"}} className="repairs detailsform-group">

              <Tabs defaultActiveKey="diagnostic" id="repair-tabSub" className="repair-tabSubc mb-3">
              <Tab eventKey="diagnostic" title={t('repair.Diagnostic')}>
              {/*<ChatUser code={repairs.code} call={callNote} lang={i18n.resolvedLanguage}/>
              */}
               {repairs.etat==="R108T"?
              <div class="container row">
              <div class="mb-1 mt-2">
              <p className="mr-4 d-inline">{t('public.The status of your repair is')} {t('etat.'+handleEtat(repairs.etat))} , {t('public.Price')} : {repairs.prix+" "+filterCurency(country,repairs)}
             </p>
             </div>
             <div class="mb-4">
                <button onClick={handleModel} type="submit" class="margin-direction save-profile btn btn-primary">{t('public.Accept')}</button>
                <Modal show={show} onHide={handleClose}>
                <Modal.Header  closeButton>
                  <strong>{t('public.Method of payment')}</strong>
                </Modal.Header>
                <Modal.Body>{repairs.code} {t('public.Choose the payment method that suits you')}</Modal.Body>
                <Modal.Footer>
                  <Button className="btn btn-primary" onClick={()=>handlePayment(repairs._id,"R110",repairs.prix,filterCurency(country,repairs),handleTranName(i18n.resolvedLanguage,repairs,true,true),repairs.code)}>
                  {t('public.Pay online')}
                  </Button>
                  <Button className="btn btn-primary" onClick={()=>handlePayment(repairs._id,"R110",repairs.prix,filterCurency(country,repairs),handleTranName(i18n.resolvedLanguage,repairs,true,true),repairs.code,"cash on delivery")}>
                  {t('public.Pay on delivery')}
                  </Button>
                 {/* <Button className="btn btn-primary" onClick={()=>handleApprove(repairs._id,"R110")}>
                  {t('public.Pay on delivery')}
        </Button>*/}
                </Modal.Footer>
              </Modal>
                {repairs.etat==="R109"?
                <button style={{opacity:"50%"}} type="button" class="ml-2 mr-2 save-profile btn btn-primary">{t('public.Refuse')}</button>
                :
                <button onClick={()=>handleApprove(repairs._id,"R109")} type="submit" class="ml-2 mr-2 save-profile btn btn-primary">{t('public.Refuse')}</button>
                }
                </div>
                </div>
                :""}
              
                  <Note translate={t} id={repairs._id} code={repairs.code} call={callNote} lang={i18n.resolvedLanguage}/>         
              </Tab>
              <Tab eventKey="history" title={t('repair.Logistics')}>
              
              {repairs.typeDepot=="c2"?
              <div className="p-5 row">
              <div className="col-12">
              {t('repair.Repair center')}
              <p className="mt-3">{t('repair.Shop name',{name:repairs.centreRecuperation.name})}</p>
              {repairs.typeDepot=="c3"?
              <p>{t('repair.Address',{name:repairs.centreRecuperation.adress})}</p>
              :
              <p>{t('repair.Address',{name:repairs.recupHome})}</p>
            }
              <p>{t('repair.Phone',{nb:repairs.centreRecuperation.phone})}</p>
  
              </div>
  
              
              </div>
              :""}
  
        {repairs.typeDepot!="c2"?
            <>
              <div className="ml-4 mt-5 row">
              <div className="col-12">
              <span><strong>{t('repair.Deposit Center')}</strong></span>
              {repairs.centreDepot && repairs.centreDepot.name?<p className="mt-3">{t('repair.Shop name',{name:domicileTranslate(repairs.centreDepot.name,t)})}</p>
              :""}
              {repairs.typeDepot=="c3"?
              <p>{t('repair.Address',{name:repairs.centreDepot ?repairs.centreDepot.adress:""})}</p>
              :
              <p>{t('repair.Address',{name:repairs.recupHome})}</p>
              
             }
              <p>{t('repair.Phone',{nb:repairs.centreDepot? repairs.centreDepot.phone:""})}</p>
  
  
              </div>
  
  
              </div>
  
  
              <div className="ml-4 mt-4 row">
              <div className="col-12">
              <span><strong>{t('repair.Recovery center')}</strong></span>
              {repairs.centreRecuperation && repairs.centreRecuperation.name ?
              <p className="mt-3">{t('repair.Shop name',{name:domicileTranslate(repairs.centreRecuperation.name,t)})}</p>
              :""}
              {repairs.typeDepot=="c3"?
              <p>{t('repair.Address',{name:repairs.centreRecuperation?repairs.centreRecuperation.adress:""})}</p>
              :
              <p>{t('repair.Address',{name:repairs.recupHome})}</p>
            }
              <p>{t('repair.Phone',{nb:repairs.centreRecuperation?repairs.centreRecuperation.phone:""})}</p>
  
              </div>
  
          
  
              </div>
              </>
              :""}
              </Tab>
              <Tab eventKey="center" title={t('repair.History')}>
              <div className="mr-2 ml-5 row">
              <div className="history-tab col-12 ml-2">
                {repairs.historique?repairs.historique.map((historiques,index) => 
             <div className="mt-4">
              <p className="history-steper"></p>
              <div style={{minHeight:repairs.historique.length===index+1?"0":""}}className="vertical-line-history"></div>
              <p style={{color:"#2A7DBA",marginBottom:"5px"}}>
                {historiques.codeEtat?
                t('etat.'+handleEtat(historiques.codeEtat))
                :historiques.etat}
                </p>
              
              <p>{historiques.etat || historiques.codeEtat ? moment(historiques.created_at).format(i18n.resolvedLanguage=="ar"? "yy-MM-DD, HH:mm":'DD-MM-yy, HH:mm'):""}</p>
              </div>
          ):""}
              </div>
              </div>
  
              </Tab>
            </Tabs>
             
          </div>
          :""}
          </>
           
    );

}   


RepairDetail.getInitialProps = async ({req}) => {
  if(req){
      return { redirectTo: '/dashboard/reparation'};

    }
};
export default RepairDetail;