const data={
    blogData:{},
    blogAdditionalData:{}
}
const blogReducer = (state = data , action) => {

    switch(action.type) {
        case 'FETCH_ALL_BLOG':
            return {...state,blogData:action.payload.blog,blogAdditionalData:action.payload.blogAdditionalData}
        default:
            return state
    }
}

export default blogReducer