import React from "react";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
function ButtonLink(props){

    const {i18n}=useTranslation();
    const lang=i18n.resolvedLanguage;
    return(
        <>
        <Link onClick={props.closeBtn} to={!props.fromCustompath?`/${lang}`+props.path:props.path} className="link-btn text-center save-profile btn btn-primary">{props.name}</Link>
        </>
    );
}

export default ButtonLink;