import React, { useEffect, useMemo, useState } from "react"
import Countdown from 'react-countdown';
import { smsApi } from "../../api/axios";


function PhoneVerification(props){

    const [finalCode,setFinalCode]=useState(["","","","","",""]);
    const [keyRestart,setKeyRestart]=useState(0);
    const [showCode,setShowCode]=useState(true);
    const [errorMessage,setError]=useState(false);

    // handle code change 
    const handleChange=(e,index)=>{
        let value=e.target.value;
        if(value!=""){
        let listarr=[...finalCode];
        if(value.length>1){
            value=value[value.length-1]   
        }
        let idInput="nb"+Number(index+1);
        if(document.getElementById(idInput)){
        document.getElementById(idInput).focus();
        
    }
    listarr[index]=value;
    if(listarr.indexOf("")==-1){
      if(props.codeVerif==listarr.join("")){
        props.setRegisterType("byemail")
        props.setStepCurrentVal(2)
      }
      else {
        if(errorMessage!=true)
        setError(true)
      }
     
      //props.setPhoneEtat(false)
    }
    
    setFinalCode(listarr)
}
}

useEffect(()=>{
    if(document.getElementById("nb0")){
        document.getElementById("nb0").focus();
        }
},[])

const handleOnkeyPress=(e)=>{
    if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
}

// resend verification code 
const handleResend=()=>{
setKeyRestart(keyRestart+1)
setShowCode(true)
const data=smsApi(props.phoneNumber);
data.then(res=>{
  if(res[0] && res[0].status==200){
    props.setCodeVerif(res[0].message.code)
  }})

}


// function callback when coutdown timer finished
const handleShow=()=>{
  setShowCode(false)
  setFinalCode(["","","","","",""]);
  setError(false)
  props.setCodeVerif("")

}

// custom render method for coutdown

const renderer = ({ formatted: {minutes, seconds }, completed }) =>{
    if (completed) {
      return (
      <>
        <span style={{fontSize:"13px"}}>{props.translate('public.The code have been expired')}&nbsp;</span>  
      <span onClick={handleResend} style={{fontSize:"14px",fontWeight:"bold",cursor:"pointer",textDecoration:"underline"}}>{props.translate('public.send again')}</span>  
      </>    
      );
    } else {
      // Render a countdown
      return <span>{minutes}:{seconds}</span>;
    }
  };

  //counter time 
  const time = useMemo(() => {
    return Date.now() + 60000;
  }, [keyRestart]);


    return(
        <div className="container phone-verification">
        {showCode===true?
        <div className="row ml-3">
        <p className="mt-4">{props.translate('public.Write the code that you have been recived')}</p>
        <div className="mt-2 form-inline">
        {[1,2,3,4,5,6].map((data,index)=>
            <div key={index} className="form-group">
            <input value={finalCode[index]} onKeyPress={handleOnkeyPress} className="form-control" onChange={(e)=>handleChange(e,index)} id={"nb"+index} type="text"/>
            </div>
        )}

        </div>
        </div>
        :""}

      <div className="row mt-4 text-center justify-content-center">
        {errorMessage==true?
          <p className="text-error">Code invalid</p>
          :""}
          </div>

        <div className="row mb-2 text-center justify-content-center">
       
        <Countdown
            date={time}
            key={keyRestart}
            renderer={renderer}
            onComplete={handleShow}
            />
          

        </div>
        </div>
    )
}

export default PhoneVerification;