const blogSlugReducer = (state = [] , action) => {

    switch(action.type) {
        case 'FETCH_ALL_BLOG_SLUG':
            return {...state,blogSlugData:action.payload.blogSlug}
        default:
            return state
    }
}

export default blogSlugReducer