import * as yup from "yup";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;


// register form validations 
 export const registreValidation = (t,phoneEtat)=>yup.object().shape({
    firstName: yup.string()
    .min(3,t('input:validation.must be at least',{name:t('input:placeholder.firstName'),nb:'3'}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.firstName'),nb:'20'}))    
    .required(t('input:validation.required',{name:t('input:placeholder.firstName')})),
    lastName: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.lastName'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.lastName')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.lastName'),nb:'20'})),
    
    email: yup.string().
      required(t('input:validation.required',{name:t('input:placeholder.Email')})).email(t('input:validation.Email is invalid'))
    .max(64,t('input:validation.must be at maximum',{name:t('input:placeholder.Email'),nb:'64'}))
    ,
    
    password: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.Password'),nb:'3'})).required(t('input:validation.required2' ,{name:t('input:placeholder.Password')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.Passowrd'),nb:'20'})),
    confirmpassword: yup.string()
    .required(t('input:validation.You must retype your password.'))
    .oneOf([yup.ref('password')], t('input:validation.Password does not match.'))
  });

// payment form validation 

  export const paymentValidation = (t,phoneEtat)=>yup.object().shape({
    firstName: yup.string()
    .min(3,t('input:validation.must be at least',{name:t('input:placeholder.firstName'),nb:'3'}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.firstName'),nb:'20'}))    
    .required(t('input:validation.required',{name:t('input:placeholder.firstName')})),
    lastName: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.lastName'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.lastName')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.lastName'),nb:'20'})),
    
    email: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Email')})).email(t('input:validation.Email is invalid'))
    .max(64,t('input:validation.must be at maximum',{name:t('input:placeholder.Email'),nb:'64'})),
    phone: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Phone number')}))
      .test('is-correct',t('input:validation.Phone number format is incorrect'), 
      () => phoneEtat===true)
    ,

  });

// login form validation 

  export const loginValidation =(t,phoneEtat,loginType)=> yup.object().shape({
    email: yup.string()
    .when([], {
      is: () => loginType === "byphone",
      then: yup.string().nullable(),
      otherwise: yup.string()
    .required(t('input:validation.required',{name:t('input:placeholder.Email')}))
    .max(64,t('input:validation.must be at maximum',{name:t('input:placeholder.Email'),nb:'64'})),
    }),
    phone: yup.string()
    .when([], {
      is: () => loginType === "byemail",
      then: yup.string().nullable(),
      otherwise: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Phone number')}))
      .test('is-correct',t('input:validation.Phone number format is incorrect'), 
      () => phoneEtat===true)
    }),

    password: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.Password'),nb:'3'})).required(t('input:validation.required2' ,{name:t('input:placeholder.Password')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.Password'),nb:'20'})),

  });

  // note form validation 

  export const remarqueValidation =(t)=> yup.object().shape({
    remarque: yup.string().min(5,t('input:validation.must be at least',{name:t('input:placeholder.Note'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.Note')}))
    .max(400,t('input:validation.must be at maximum',{name:t('input:placeholder.Note'),nb:'400'})),

  });

// devis form validation 

  export const devisValidation=(t,phoneEtat)=>yup.object().shape({
   firstName: yup.string()
    .min(3,t('input:validation.must be at least',{name:t('input:placeholder.firstName'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.firstName')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.firstName'),nb:'20'})),
    lastName: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.lastName'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.lastName')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.lastName'),nb:'20'})),
    phone: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Phone number')}))
      .test('is-correct',t('input:validation.Phone number format is incorrect'), 
      () => phoneEtat===true)
    ,

    email: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Email')})).email(t('input:validation.Email is invalid'))
    .max(64,t('input:validation.must be at maximum',{name:t('input:placeholder.Email'),nb:'64'})),
    besoin: yup.string()
    .min(3,t('input:validation.must be at least',{name:t('input:placeholder.Need'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.Need')}))
    .max(600,t('input:validation.must be at maximum',{name:t('input:placeholder.Need'),nb:'600'})),
    nomEntreprise: yup.string()
    .min(3,t('input:validation.must be at least',{name:t('input:placeholder.Company Name'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.Company Name')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.Company Name'),nb:'20'})),

  });

  // pack form validation 

  export const packValidation=(t,phoneEtat)=>yup.object().shape({
    firstName: yup.string()
     .min(3,t('input:validation.must be at least',{name:t('input:placeholder.firstName'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.firstName')}))
     .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.firstName'),nb:'20'})),
     lastName: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.lastName'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.lastName')}))
     .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.lastName'),nb:'20'})),
     phone: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Phone number')}))
     .test('is-correct',t('input:validation.Phone number format is incorrect'), 
     () => phoneEtat===true)
   ,
     email: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Email')})).email(t('input:validation.Email is invalid'))
     .max(64,t('input:validation.must be at maximum',{name:t('input:placeholder.Email'),nb:'64'})),

     nomEntreprise: yup.string()
     .min(3,t('input:validation.must be at least',{name:t('input:placeholder.Company Name'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.Company Name')}))
     .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.Company Name'),nb:'20'})),

     typeDevis: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Pack type')}))
   });

   // reset form validation 

  export const resetValidation =(t)=> yup.object().shape({
    email: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Email')})).email(t('input:validation.Email is invalid'))
    .max(64,t('input:validation.must be at maximum',{name:t('input:placeholder.Email'),nb:'64'})),

  });

  // landing page form validation 

  export const landingValidation =(t)=> yup.object().shape({
    email: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Email')})).email(t('input:validation.Email is invalid')),
  });

  // password form validation 

  export const passwordValidation =(t)=> yup.object().shape({
    password: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.Password'),nb:'3'})).required(t('input:validation.required2' ,{name:t('input:placeholder.Password')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.Password'),nb:'20'})),

    confirmpassword: yup.string()
    .required(t('input:validation.You must retype your password.'))
    .oneOf([yup.ref('password')], t('input:validation.Password does not match.'))
  });

  // confirm form validation 

  export const confirmPasswordValidation =(t)=> yup.object().shape({
    oldPassword: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.Password'),nb:'3'})).required(t('input:validation.required2' ,{name:t('input:placeholder.Current Password')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.Password'),nb:'20'})),

    newPassword: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.New Password'),nb:'3'})).required(t('input:validation.required2' ,{name:t('input:placeholder.New Password')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.New Password'),nb:'20'})),
    confirmPassword: yup.string()
    .required(t('input:validation.You must retype your password.'))
    .oneOf([yup.ref('newPassword')], t('input:validation.Password does not match.'))
  });

  // contact form validation 

  export const contactValidation =(t,phoneEtat)=>yup.object().shape({
    firstName: yup.string()
    .min(3,t('input:validation.must be at least',{name:t('input:placeholder.firstName'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.firstName')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.firstName'),nb:'20'})),

    lastName: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.lastName'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.lastName')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.lastName'),nb:'20'})),
    email: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Email')})).email(t('input:validation.Email is invalid'))
    .max(64,t('input:validation.must be at maximum',{name:t('input:placeholder.Email'),nb:'64'})),
    phone: yup.string()
    .required(t('input:validation.required',{name:t('input:placeholder.Phone number')}))
    .test('is-correct',t('input:validation.Phone number format is incorrect'), 
    () => phoneEtat===true),
    msgbody: yup.string().min(5,t('input:validation.must be at least',{name:t('input:placeholder.Message'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.Message')}))
    .max(600,t('input:validation.must be at maximum',{name:t('input:placeholder.Message'),nb:'600'})),

  });

// profile form validation 

  export const profileValidation =(t,phoneEtat)=> yup.object().shape({
    firstName: yup.string()
    .min(3,t('input:validation.must be at least',{name:t('input:placeholder.firstName'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.firstName')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.firstName'),nb:'20'})),
    lastName: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.lastName'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.lastName')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.lastName'),nb:'20'})),

  /*  phone: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Phone number')})).matches(phoneRegExp, t('input:validation.Phone number format is incorrect'))
  */

  phone: yup.string()
  .required(t('input:validation.required',{name:t('input:placeholder.Phone number')}))
  .test('is-correct',t('input:validation.Phone number format is incorrect'), 
  () => phoneEtat===true)



  });

  // code foorm validation 

  export const codeValidation = (t)=>yup.object().shape({
    codeInput: yup.string()
    .min(3,t('input:validation.must be at least',{name:t('input:placeholder.Repair code'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.Repair code')}))
    .max(10,t('input:validation.must be at maximum',{name:t('input:placeholder.Repair code'),nb:'10'})),
  
  });

  // phone form validation with regax 

  export const phoneValidation = (t)=>yup.object().shape({
    phone: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Phone number')})).matches(phoneRegExp, t('input:validation.Phone number format is incorrect')),

  });

  // phone validation by country code 
  export const phoneValidationByCountry = (t,phoneEtat)=>yup.object().shape({
    phone: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Phone number')}))
      .test('is-correct',t('input:validation.Phone number format is incorrect'), 
      () => phoneEtat===true)

  });

//repair form before final validation 

  export const repairValidation = (t,hasColor,imei)=>yup.object().shape({
    /*reference: yup.string()
    .required(t('input:validation.required',{name:t('input:placeholder.Reference')})),
    imei: yup.string()
    .required(t('input:validation.required',{name:t('input:placeholder.Serial Number (IMEI)')})),
    */ 
   /*couleur: yup.string()
    .required(t('input:validation.required',{name:t('input:placeholder.Color')})),*/
    
    imei: yup.string()
    .when([], {
      is: () => imei=="",
      then: yup.string().nullable(),
      otherwise:yup.string().nullable()
    .required(t('input:validation.required',{name:t('input:placeholder.Serial Number (IMEI)')}))
    .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.Serial Number (IMEI)'),nb:'20'})),
  }),
    couleur: yup.string()
    .when([], {
         is: () => hasColor === false,
         then: yup.string().nullable(),
         otherwise: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Color')})),


       }),

       detailPanne:yup.string()
       .required(t('input:validation.required',{name:t('input:placeholder.Panne Details')}))
       .min(5,t('input:validation.must be at least',{name:t('input:placeholder.Panne Details'),nb:'5'})).required(t('input:validation.required',{name:t('input:placeholder.Repair code')}))
       .max(250,t('input:validation.must be at maximum',{name:t('input:placeholder.Panne Details'),nb:'250'})),
     
    /*panne:yup.string()
    .required(t('input:validation.required',{name:t('input:placeholder.Panne')})),
    detailPanne:yup.string()
    .required(t('input:validation.required',{name:t('input:placeholder.Panne Details')})),
*/

  });



 /* export const repairLastValidation = yup.object().shape({
    firstName: yup.string()
    .min(3,"Prénom doit étre au minimum 3").required("Prénom obligatoire"),
    lastName: yup.string().min(3,"Nom doit étre au minimum 3").required("Nom obligatoire"),
    email: yup.string().required("Email est obligatoire").email("Email non valide"),
    phone: yup.string().required("Numéro de téléphone est obligatoire").matches(phoneRegExp, 'Numéro non valide'),
    recup: yup.string().required("Centre de récupération est obligatoire"),
    depot: yup.string().required("Centre de dépo est obligatoire"),

  });*/


// reparation form final validation 

  export const repairLastValidation2 = (condition,t,typeDepot,phoneEtat)=>yup.object().shape({

      firstName: yup.string()
       .when([], {
         is: () => condition === true,
         then: yup.string().nullable(),
         otherwise: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.firstName'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.firstName')}))
         .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.firstName'),nb:'20'})),

       }),

       lastName: yup.string()
       .when([], {
         is: () => condition === true,
         then: yup.string().nullable(),
         otherwise: yup.string().min(3,t('input:validation.must be at least',{name:t('input:placeholder.lastName'),nb:'3'})).required(t('input:validation.required',{name:t('input:placeholder.lastName')}))
         .max(20,t('input:validation.must be at maximum',{name:t('input:placeholder.lastName'),nb:'20'})),

        }),

       phone: yup.string()
       .when([], {
         is: () => condition === true,
         then: yup.string().nullable(),
         otherwise: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Phone number')}))
         .test('is-correct',t('input:validation.Phone number format is incorrect'), 
         () => phoneEtat===true)
       }),

       email: yup.string().required(t('input:validation.required',{name:t('input:placeholder.Email')})).email(t('input:validation.Email is invalid'))
       .max(64,t('input:validation.must be at maximum',{name:t('input:placeholder.Email'),nb:'64'})),

       recup: yup.string()
       .when([], {
        is: () => typeDepot !="c3",
        then: yup.string().nullable(),
        otherwise: yup.string()
       .required(t('input:validation.required',{name:t('input:placeholder.Recuperation center')})),
      }),
       depot: yup.string()
       .when([], {
        is: () => typeDepot !="c3",
        then: yup.string().nullable(),
        otherwise: yup.string()
       .required(t('input:validation.required',{name:t('input:placeholder.Depot center')}))
      }),

      recupHome: yup.string()
       .when([], {
        is: () => typeDepot =="c3",
        then: yup.string().nullable(),
        otherwise: yup.string()
       .required(t('input:validation.required',{name:t('input:placeholder.Exact adress')})),
      }),

       
      });


   