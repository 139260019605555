import { useTranslation } from 'react-i18next'
import React from "react";

const Direction = React.lazy(() => import('./DirectionFile.js'));


const FileSelector = ({ children }) => {

    const { i18n } = useTranslation("common");

    return (
      <>
        <React.Suspense fallback={<></>}>
          {(i18n.resolvedLanguage==="ar") && <Direction />}
        </React.Suspense>
        {children}
      </>
    )
  }

export default FileSelector;