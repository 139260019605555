const counterReducer = (counter = 1, action) => {
    switch (action.type) {
      case "INC":
        return counter + 1;
      case "DEC":
        return counter - 1;
        case "ADD":
          return action.payload

      default:
        return counter;
    }
  };
  export default counterReducer;