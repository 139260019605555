import { fetchSteperData } from "../store/Actions/steperDataAction";


// get reparation step data device,brand,model,repair by step
export const getApi=(steps,id,stepIncreamte)=>{
        
    let data=null;
    let step=steps+1;
    if(stepIncreamte===true){
        step=steps
    }
    if(step===2){
        data=brandApi(id);
    }
       //getDataByName().then(result=>alert(result));
    
    else if(step===3){
        data=gammeApi(props.steperData["step1"].id,id);
    }
    else if(step===4){
        data=modelApi(props.steperData["step1"].id,props.steperData["step2"].id,id);
    }

    else if(step===5){
        let country_id=detection?detection.detectionData.value:"";
        data=productRepairApi(props.steperData["step1"].id,props.steperData["step2"].id,props.steperData["step3"].id,id,country_id);
    }
 
    
    return data;
}

// handle category pushed in reparation stepe
export const categoryPush=(data,count,steperData,dispatch)=>{
         
    let stepkey="step"+count;
    let finalKey="";
    let stepIncreamte=null;
    let newdata=Object.assign({}, steperData); 
    
    if(steperData[stepkey] && steperData[stepkey].id !=data.id){
        for(let dataKey in newdata){
            if(Number(dataKey.slice(-1)>count))
            stepIncreamte=Number(dataKey.slice(-1));
             finalKey="step"+stepIncreamte;
             if(newdata[finalKey])
             delete newdata[finalKey]
        }
    }

    newdata["step"+count]=data;
    dispatch(fetchSteperData(newdata))

}


// get device,brand,model,reparation translated name
export const handleTranslatedName=(categorys,step,lang)=>{
    let fname=null;
    if(step>1 && step!=5){
        fname=categorys.name;
    }
    else if(step===5 && lang==="fr"){
        fname=categorys.description;
    }
    else if(step===5 && lang==="en"){
        fname=categorys.descriptionEnglish;
    }

    else if(step===5 && lang==="ar"){
        fname=categorys.descriptionArabe;

    }

   else if(lang==="fr"){
    fname=categorys.nameFrench;
    }
    else if(lang==="en" ){
        fname=categorys.nameEnglish;

    }
    else if(lang==="ar"){
        fname=categorys.nameArabe;
    }


    return fname;
      

}