const detectionReducer = (state = [] , action) => {

    switch(action.type) {
        case 'FETCH_DETECTION':
            return {...state,detectionData:action.payload.countryDetected}
        default:
            return state
    }
}

export default detectionReducer