import React from "react";
import imgauth from "../../assets/image/authentification/authentifcation-img.png";
import ButtonLink from "../../components/ButtonLink.js";

function ImageRegister(props){

    return(

        <div className="col-lg-6 col-md-6 register-left" id={props.name}>
            <figure>
            <img src={imgauth}/>
            <figcaption>{props.translate('public.Already have an account2')+props.translate('register.please enter your login and password !')}</figcaption>
            </figure>
            <div className="mb-4 col-lg-9 text-center btn-class justify-content-center">
            <ButtonLink path="/login" name={props.translate('public.Connection')}/>

            </div>
            
            </div>
    );
}
export default ImageRegister;