import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactStars from "react-rating-stars-component";
import img1 from "../assets/image/review/ayman-guizani.jpg";
import img2 from "../assets/image/review/kamel-cherif.jpg";
import img3 from "../assets/image/review/maram-kh.jpg";
import img4 from "../assets/image/review/issam-ben-ahmed.jpg";
import img5 from "../assets/image/review/med-fires-ajina.jpg";
import img6 from "../assets/image/review/essadek-frioui.jpg";
import img7 from "../assets/image/review/khaled-ben-jedida.jpg";
import { useTranslation } from "react-i18next";


const settings = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "360px",
    slidesToShow: 1,
    speed: 500,
    rtl: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerPadding: '60px',
        }
      },
    ]

  };


function SliderBottom(){

  const {i18n} = useTranslation("common");

  const images =[

    {show:true,name:'Ayman Guizani',"text":"Perfect customer service and well organized team, repair partner as well are polite and explaining stuff to the customers as it should be. Many thanks 🙏 good job for future challenges Cheers",path:img1},
    {show:i18n.resolvedLanguage=="fr"?true:false,name:'Kamel Cherif',"text":"merci pour votre professionnalisme et votre efficacité. bon travail",path:img2},
    {show:i18n.resolvedLanguage=="fr"?true:false,name:'Maram Kh',"text":"Personne ne peut nier que vous êtes professionnels, service rapide et de qualité.Bonne continuation 👌😁",path:img3},
    {show:true,name:'Essadek Frioui',"text":"شخصيا انا منبهر بما تقدمونه للحرفاء بل تجتهدون وتسعون لإرضائهم. اقول هذا عن تجربة شخصية تجاوزت الإصلاح تتابعون وتتواصلون مع الحريف وتسالونه رايه وتلبون مقترحاته بارك الله فيكم وجازاكم الله خيرا بالتوفيق واصلوا بنفس العزيمة والحماس فالنجاح حليفكم وفي الحقيقة أنتم ناجحون وللحرفاء شرف التعامل معكم.",path:img6},
    {show:true,name:'Med Firas Ajina',"text":"I got my Huawei G8 screen repaired and I'm totally satisfied! The assistant is professional and most importantly honest!I recommend it for phone reparation.",path:img5},
    {show:true,name:'Khaled Ben Jedida',"text":"يعطيكم الصحة بصراحة 👏👏👏 ياسر حرفية و جدية و إتقان و لهوة  ، ربي يعينكم 💖💖💖",path:img7},
    {show:true,name:'Issam Ben Ahmed',"text":"I really appreciate your reliability & fiability.I highly recommend for Trusit, they do an excellent work!!!",path:img4},
  ]

  return(
        <>
        <div className="slider-review">

        <Slider  {...settings}>

        {images.map((item, i) => (
          item.show==true?
          <div key={i} className="card">
          <img className="card-img-top" src={item.path} alt=""/>
          <p style={{fontSize:"10px",margin:"0"}}>{item.name}</p>
          <div className="card-body">
            <p className="card-text">{item.text}</p>
            <p className="card-footer card-text">
            <ReactStars
            count={5}
            size={18}
            value={5}
            activeColor="#000000"
            edit={false}
          />
            </p>
          </div>
          
        </div>
        :""
        ))}
        
        </Slider>
        
        </div>
       
        </>

    );
}

export default SliderBottom;