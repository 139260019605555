import React, { useState,useCallback } from "react";
import { subscribeApi } from "../api/axios";
import { useFormik } from "formik";
import { landingValidation } from "../schema/formValid";
import { FocusError } from 'focus-formik-error'

function Newsletters(props){

  const [message,setMessage]=useState([]);
  const [viewDisable,setviewDisable]=useState(false);

    const handleOnSubmit =(values) => {
      const {email}=values;
      setviewDisable(true);
      
      // subscribe to newslater call api
      const data=subscribeApi(email,props.resolvedlang);
      data.then(response=>{
        setMessage(response);
        if(response[0].status===200){
          setTimeout(()=>{
            setMessage([]);
            formik.resetForm();
            setviewDisable(false);

          },3000)
          
      }
      else {
        setviewDisable(false);

      }
    })
  }

    const formik = useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: landingValidation(props.translate),
      onSubmit: handleOnSubmit,
      onClick: handleOnSubmit,
      validateOnChange:false,

    });

    const setInputValue = useCallback(
      (key, value) =>
        formik.setValues({
          ...formik.values,
          [key]: value,
        }),
      [formik],
    );

  
    return(
    <>
      <div className="row col-md-10">
        <p className="ml-1 mr-1">{props.translate('footer.Subscribe to our Newsletter')}</p>
        </div>
        <div className="row col-md-10">
        <form className="w-100" noValidate onSubmit={formik.handleSubmit}>
        <FocusError formik={formik} />
        <div style={{pointerEvents:viewDisable===true ?"none":''}} className="input-group-prepend">
        <input value={formik.values.email} className={formik.errors.email?"form-control is-invalid":"form-control"} name="email" type="text" placeholder={props.translate('input:placeholder.E-mail address')} onChange={(e) => setInputValue("email", e.target.value)} />
        <span onClick={formik.handleSubmit} className="input-group-text news-btn"><span>{props.translate('footer.ok')}</span></span>
        </div>
        </form>
        </div>
        <div className="row mt-1 text-bottom">
          <div className="col ml-1 mr-1 text-left">
          <small className="text-error">{formik.errors.email}</small>
          <small className="text-success">{!formik.errors.email && message && message.length>0 && message[0].status===200 ? props.translate('register.'+message[0].message) :''}</small>         
            <small className="text-error">{!formik.errors.email && message && message.length>0 && message[0].status===409 ?props.translate('home.'+message[0].message) :''}</small>
          </div>
          </div>
    </>
    );

}
export default Newsletters;