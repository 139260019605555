import React from "react";
import { Link } from "react-router-dom";
import StaticCard from "../../components/StaticCard";
import hand from "../../assets/image/static-page/main-image.png";
import btnimg from "../../assets/icon/input-button.svg";
import { useTranslation } from 'react-i18next';
import phonemb from "../../assets/image/static-page/phone_mb.svg";
import CodeVerification from "../Home/codeVerification";
import SEO from "../../components/SEO";

function HowTo(){

    const {t,i18n} = useTranslation("common");

    const textReturn=()=>{

        return(
            <>
           
            <div className="row howto-row mt-5">
                <div className="col-lg-12 mt-3">
                    <p><strong>{t('howitWork.How to request a repair')}</strong></p>
                </div>
                
                <div className="col-lg-8 mt-2">
                    <ul>
                        <li><span>{t('howitWork.Click on')} <Link to={{pathname: `/`,state: {"reset":"homeReset"} }}>{t('home.Request Repair')}</Link></span></li>
                        <li><span>{t('howitWork.Choose the gadget to repair')}</span></li>
                        <li><span>{t('howitWork.Fill in the form and your details')}</span></li>
                        <li><span>{t('howitWork.Choose the Recovery address')}</span></li>
                        <li><span>{t('howitWork.Choose the deposite center closest to your recovery address')}</span></li>
                        <li><span>{t('howitWork.Click on validate the repair')}</span></li>
                    </ul>
                    </div>
                    <div className="col-lg-4">
                        <img className="handImage" src={hand}/>
                    </div>

                <div className="col-lg-12 mt-5 mb-2">
                    <p><strong>{t('howitWork.How to Track your Repair via the Website')}</strong></p>
                </div>
                <div className="col-lg-5 mt-2">
                    <ul >
                        <li><span>{t('howitWork.Insert your repair code')}</span></li>
                        <li><span>{t('howitWork.Click on')}<img className="buttoninput-img" src={btnimg}></img></span></li>
                    </ul>
                    </div>

                <div className="code-rigth col-lg-4">
                <CodeVerification translate={t}/>

                 </div>

                 <div className="col-lg-12 mt-5 mb-2">
                    <p><strong>{t('howitWork.How to Track your Repair via the App',{name:'TrustiT'})}</strong></p>
                    </div>
                    <div  className="col-lg-3">
                    <div className="mt-2 row justify-content-center text-center">
                    <div className="mb-3 col-lg-12 justify-content-center">
                        <img src={phonemb}/>
                        </div>
                    </div>
                    
                </div>
                    <div className="col-lg-8 mt-3">
                    <ul>
                        <li><span>{t('howitWork.Download our Trust iT app via',{name:process.env.RAZZLE_REACT_APP_NAME})} <a target="_blank" href={process.env.RAZZLE_REACT_APP_GOOGLE_PLAY}>{t('howitWork.Google Play store')}</a> {t('howitWork.Or')} <a target="_blank" href={process.env.RAZZLE_REACT_APP_APPLE_STORE}>{t('howitWork.Apple Store')}</a></span></li>
                        <li><span>{t('howitWork.Register and then log in')}</span></li>
                        <li><span>{t('howitWork.Once Accessed to the account, Click on “My repairs”')}</span></li>
                        <li><span>{t('howitWork.Choose the corresponding code')}</span></li>
                    </ul>

                </div>
              
                 </div>

            </>
        );
    }

    return(
        <>
          <SEO 
            title={t('seoContent.title how to',{name:process.env.RAZZLE_REACT_APP_NAME})}
            description={t('seoContent.description how to')}/>
            <h1 className="text-header">{t('SeoBody.how to h1',{name:process.env.RAZZLE_REACT_APP_NAME})}</h1>
        <StaticCard title="How it works" paddingBody={60} paddingBodyTop={30}
        text={textReturn()}
        />
        </>

    );
}
export default HowTo;