import React, { useEffect, useState, useRef } from "react";
import { scoreApi } from "../../api/axios.js";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import SpinnerLoadClient from "../../components/SpinnerLoadCLient";
import { useSelector } from "react-redux";

function Point() {
  const { t,i18n } = useTranslation("common");
  const [score, setScore] = useState([]);
  const { promiseInProgress } = usePromiseTracker();
  const user= useSelector((state) => state.user)

  useEffect(() => {
    let data = [];
//track promise progress and get score list
    trackPromise(data = scoreApi(user.userData.token));
    data.then((result) => setScore(result));
  }, []);

  return (
    <div className="col-lg-9 col-md-7">
      <h3 className="title-form">{t("point.My Points", { name: "TrustY" })}</h3>
      {promiseInProgress===false && score && score.length > 0 ? (
        <>
          <ul className="list-group list-point">
            {score.map((scores) => (
              <div key={scores._id}>
                <li className="list-group-item">{scores.value +" "+t('point.Points')}</li>
                <li className="list-group-item">
                  <span>{t('point.'+scores.reason)}</span>
                  <span className="float-right">
                    {moment(scores.created_at).format(i18n.resolvedLanguage=="ar"? "yy-MM-DD":'DD-MM-yy')}
                  </span>
                </li>
              </div>
            ))}
          </ul>

          <div style={{display:"none"}} className="row">
            <div className="mt-4 col">
              <span>
                {t("point.Convert my loyalty points into a reduction of")} 0,900
                TND.
              </span>
            </div>
          </div>

          <div className="row">
            <div style={{display:"none"}} className="mt-3 col">
              <button type="submit" className="save-profile btn btn-primary">
                {t("public.Convert my points")}
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {!promiseInProgress && score && score.length < 1 ? (
        <p className="text-center" style={{ marginTop: "80px" }}>
          {t("repair.No points available")}
        </p>
      ) : (
        ""
      )}

      <SpinnerLoadClient/>
    </div>
  );
}
export default Point;
