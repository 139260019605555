import {isAuth} from "../utils/auth";


//menu item list 

export const menuItem=(data,lang)=>{
    let itemLogin={};
    let itemLogout={};
    let itemRegister={};
    let itemProfile={};

    if(!isAuth(data)){
        itemLogin={ name: "Connection", link: `/${lang}/login`,icon:"connection-icon"};
        itemRegister={ name: "Register", link: `/${lang}/register`,icon:"register-icon"};
        }
        
        if(isAuth(data)){
        itemLogout={ name: "Logout", link: `/${lang}/login`,icon:"connection-icon"};
        itemProfile={ name: "My profile", link: `/${lang}/dashboard/reparation`,icon:""};
        }

   return [

    
        { name: "Blog", link: `/${lang}/blog`,icon:""},
        /*{ name: "Shop", link: "/boutique",icon:""},*/
        itemRegister,
        itemLogin,
        itemProfile,
        itemLogout
    
    
    ]
}

// rtl menu item 
export const menuItemArab=(data,lang)=>{
    
    let itemLogout={};
    if(isAuth(data)){
        itemLogout={ name: "Logout", link: `/${lang}/login`,icon:"connection-icon"};
        }
   return [itemLogout];
}

