import Home from '../pages/Home/home';
import Login from "../pages/Authentification/login";
import Register from "../pages/Authentification/register";
import ForgetPassword from "../pages/Authentification/forgetPassword";
import ResetPassword from "../pages/Authentification/resetPassword";
import OffreEducagnotte from "../pages/Static/offreEducagnotte";
import OffreInfocagnotte from "../pages/Static/offreInfocagnotte";
import OffreBusiness from "../pages/Static/offreBusiness";
import Pack from "../pages/Static/packTrustIt";
import Contact from "../pages/Contact/contact";
import HowTo   from "../pages/About/howTo";
import OurMission   from "../pages/About/mission";
import Network   from "../pages/About/network.jsx";
import Partner  from "../pages/About/partner";
import AboutUs   from "../pages/Devis/devis";
import PackDevis  from "../pages/Devis/packDevis";
import Devis    from "../pages/Devis/devis";
import Blog    from "../pages/Blog/blog";
import BlogInfo   from "../pages/Blog/blogInfo.jsx";
import PageNotFound from '../containers/PageNotFound';
import HomeUser from "../pages/Profile/homeUser";
import PhoneVerification from '../pages/Authentification/phoneVerification';
import Payment from '../pages/Profile/payment';
import RepairDetail from '../pages/Profile/repairDetail';
import React from "react"
// route file the contain all the route for the project

let fromRepairer=true;
export default [

  {
    path: '/:lang?/login/reset-password/:tokenu/:token',
    component: ResetPassword,

  },

  {
    path: '/:lang?/login/reset-password-repairer/:tokenu/:token',
    component:(props) => <ResetPassword {...props} fromRepairer={true}/>

  },
 

  {
    path: '/:lang?/login/reset',
    component: ForgetPassword,

  },

  {
    path:"/:lang?/login",
    component:Login,
  },

  {
    path: '/:lang?/register/phone-verification',
    component: PhoneVerification,

  },
 

  {
    path: '/:lang?/register',
    component: Register,

  },

 

  {
    path: '/:lang?/educagnotte-offer',
    component: OffreEducagnotte,

  },

  {
    path: '/:lang?/infocagnotte-offer',
    component: OffreInfocagnotte,

  },

  {
    path: '/:lang?/business-offer',
    component: OffreBusiness,

  },

  {
    path: '/:lang?/pack',
    component: Pack,

  },

  {
    path: '/:lang?/contact',
    component: Contact,

  },

  {
    path: '/:lang?/how-to',
    component: HowTo,

  },

  {
    path: '/:lang?/mission',
    component: OurMission,

  },
  {
    path: '/:lang?/about-us',
    component: OurMission,

  },

  {
    path: '/:lang?/network',
    component: Network,

  },

  {
    path: '/:lang?/partner',
    component: Partner,

  },

  {
    path: '/:lang?/about-us',
    component: AboutUs,

  },

  {
    path: '/:lang?/devis',
    component: Devis,

  },

  {
    path: '/:lang?/dashboard/reparation/payment',
    component: Payment,

  },

  {
    path: '/:lang?/dashboard/reparation/:code',
    component:RepairDetail,

  },
 
  {
    path: '/:lang?/dashboard',
    component:HomeUser,

  },

  {
    path: '/:lang?/pack-devis',
    component: PackDevis,

  },

  {
    path: '/:lang?/blog/detail/:slug',
    component: BlogInfo,

  },

  {
    path: '/:lang?/blog/:page?',
    component: Blog,

  },


  {
    path: ['/:lang?','/:lang?/category/:step1/:step2?/:step3?/:step4?/:step5?'],
    component: Home,
    exact: true,

  },

  {
    component: PageNotFound,
  },
];
