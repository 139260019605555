const countryReducer = (state = [] , action) => {

    switch(action.type) {
        case 'FETCH_ALL_DEVICE':
            return {...state,deviceData:action.payload.device}
            
        default:
            return state
    }
}

export default countryReducer