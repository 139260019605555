import React,{useCallback, useState} from "react";
import PrependInput from "../../components/PrependInput";
import {codeValidation} from "../../schema/formValid";
import { useFormik } from "formik";
import {codeSearchApi} from "../../api/axios.js";
import ModelCodeVerif from "./ModelCodeVerif";
import { handleEtat } from "../../utils/repairState";

function CodeVerification(props){

    const [message,setMessage]=useState([]);
    const [dialogShow,setDialogShow]=useState(false);


    // reparation code verification true detail will be showed
    const handleOnSubmit =(values) => {
    const {codeInput}=values;
    const data=codeSearchApi(codeInput);  
    setDialogShow(false);     
    data.then(result =>{
      setMessage(result);
      if(result[0].status===200){
      setDialogShow(true);
      }
    })

  }
       const formik = useFormik({
        initialValues: {
          codeInput: "",
        },
        validationSchema: codeValidation(props.translate),
        onSubmit: handleOnSubmit,
        validateOnChange:false,

      });

      const setInputValue = useCallback(
        (key, value) =>
          formik.setValues({
            ...formik.values,
            [key]: value,
          }),
        [formik],
      );


    return(
    <>
    <h3 className="title-form">{props.translate('home.Track Your Repair')}</h3>
        <PrependInput formik={formik} backendMessage2={!formik.errors.codeInput && message && message.length>0 && message[0].status===200 ? props.translate("etat."+handleEtat(message[0].message.etat)) :''} backendMessage={!formik.errors.codeInput && message && message.length>0 && message[0].status===404 ? props.translate('home.'+message[0].message) :''} errorFormik={formik.errors.codeInput} fname={formik.handleSubmit} placeholderText={props.translate('input:placeholder.Repair code')} change={(e) => setInputValue("codeInput", e.target.value)}/>
        <span className="mb-4">{props.translate('home.Insert your unique code and track your repair with just one click!')}</span>
      {message && message.length>0 && message[0].status===200 && dialogShow===true ?
      <ModelCodeVerif resolvedlang={props.resolvedlang} translate={props.translate} verifCode={message[0].message} handleShow={true}/>
      :''}
    </>
    );
}

export default CodeVerification;