import React,{useState} from "react";
import { NavLink,Link, Redirect} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../assets/image/logo/logoNav.svg";
import {menuItem as MenuItem,menuItemArab as menuItemArab} from "./MenuItem.js";
import {dropdownItem} from "./DropdownOffer.js";
import dropdownLang from "./DropdownLang.js";
import {setCookie,signout} from "../utils/auth";
import { useTranslation } from 'react-i18next';
import ButtonLink from "../components/ButtonLink";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";


function NavMenu(){
  const {t,i18n} = useTranslation("common");

  const navDropdownTitle = (<i className="fr-icon"></i>);
  const [expanded, setExpanded] = useState(false);
  const [dropdownSelected,setDropdownSelected] = useState(false);
  const user= useSelector((state) => state.user)
  const lang=i18n.resolvedLanguage;
  const country= useSelector((state) => state.country.countryData)
  const detection= useSelector((state) => state.countryDetected)


// logout user
  const logout=()=>{
    signout();
    window.location.reload();
  }

  // reload page
  const reloadPage=()=>{
    //window.location.replace(document.location.origin);
  }

  // save language and call lang redirect handler
  const handleSelect=(e)=> {
    setCookie("i18nextLng",e)
    redirect(e,"fromChange");
};


const handleBusiness=(e)=>{
  if(e){
    setDropdownSelected(true)
   // i18n.changeLanguage(e);
  }

}

// lang url changer and redirect

const redirect=(langCode,from)=>{
 
    if (typeof window !== 'undefined') {
    const supportedLngs=["fr","ar",'en']
    let langURL=window.location.pathname.split("/")[1];
    

    let code=null;
    if(from){
    code=langCode;

    }
    else {
      code=lang
    }
  
    if(!supportedLngs.includes(`${langURL}`)){
      langURL=""
    }
    
      if(code.indexOf("-")>-1){
        let index=code.indexOf("-")
        let newLang=code.substring(0,index);
        code=newLang
        }
  
        let url =window.location.pathname;
        let removeCategory=url.split("/");
        let detectCategoryIndex=removeCategory.indexOf("category")

        if(detectCategoryIndex>-1){
          let cleanUrl=removeCategory.slice(0,detectCategoryIndex);
          url=cleanUrl.join("/")
        }
    
        if(code!=langURL){
        if(langURL){
        url=url.replace(`/${langURL}`, `/${code}`)

      }
       /* else if(langURL && code==="en"){
          url=url.replace(`/${langURL}`, ``)
        
        }*/
        
        else if(!langURL) {
          url = [`/${code}`,url].join('');
        }
        if(from)
        window.location.replace(url);
        else 
        return <Redirect to={url}/>

      }

    }
  
}

const handleCountrySelect=(countryData)=>{
  setCookie('country',countryData);
  window.location.reload();
}

    return(
      <>
      <Navbar expanded={expanded}  expand="lg">
        {redirect()}
      <Container>
        <Navbar.Brand className="logoHeader">
        <Link  to={{pathname: `/${lang}/`,state: {"reset":"homeReset","noscroll":true} }} onClick={reloadPage}><img width="120" src={logo} alt="TrustiT logo"/></Link>
        </Navbar.Brand>
                
        <NavDropdown className="ml-auto langDropdown-menu langDropdown langDropdown-mobile" id="nav-dropdown-dark-example" title={<><i className="lang-icon"></i>{lang.toUpperCase()}</>} menuVariant="dark" onSelect={handleSelect}>
          {dropdownLang.map((dropdownItems,index) => (
            lang && lang!=dropdownItems.key  ?
              <NavDropdown.Item  eventKey={dropdownItems.key} key={dropdownItems.key} >{dropdownItems.name}</NavDropdown.Item>
              :''
          ))}
            </NavDropdown>

            <NavDropdown className="countryDropdown langDropdown langDropdown-mobile" id="nav-dropdown-dark-example" title={<img src={process.env.RAZZLE_REACT_APP_BASE_URL+detection.detectionData.icon}/>} menuVariant="dark">
          {country.map((dropdownItems,index) => (
              dropdownItems.value!=detection.detectionData.value?
              
              <NavDropdown.Item  eventKey={dropdownItems.key} key={dropdownItems.key} onClick={()=>handleCountrySelect({"code":dropdownItems.code,"currency":dropdownItems.currency,"icon":dropdownItems.icon,"label":dropdownItems.label,"value":dropdownItems.value})}><img src={process.env.RAZZLE_REACT_APP_BASE_URL+dropdownItems.icon}/>{dropdownItems.code}</NavDropdown.Item>
              :""
              ))}
          </NavDropdown>


        <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse className="justify-content-end" id="nav-top basic-navbar-nav ">
          <Nav className="me-auto">

          <ButtonLink fromCustompath={true} closeBtn={() => setExpanded(false)} className="button-nav" path={{pathname: `/${lang}/`,state: {"reset":"homeReset"} }} name={t('home.Depose your Repair')}/>

            {lang==="ar"?
            menuItemArab(user.userData,lang).map((menuItems,index) => (
              menuItems.name && menuItems.link?
              <Nav.Link onClick={() => setExpanded(false)} as={NavLink} key={menuItems.name} to={menuItems.link}   {...(menuItems.name=="Logout" && { onClick:logout })}>
                <i className={"mr-2"+" "+menuItems.icon}></i>
                {t('public.'+menuItems.name)}

            </Nav.Link>
            :''
            ))
            :''}

          <NavDropdown  onSelect={handleBusiness} title={<span style={{lineHeight:"unset",fontWeight:"500",color:dropdownSelected===true?"#35A0EE" :""}}>{t('public.Business offer')}</span>} id="nav-dropdown-menu">
            {dropdownItem(lang).map((dropdownItems,index)=>(
              <NavDropdown.Item eventKey={dropdownItems.name} onClick={() => setExpanded(false)} key={dropdownItems.name===""} as={NavLink}  to={dropdownItems.link} renderMenuOnMount={true}>
                 {t('public.'+dropdownItems.name)}

                </NavDropdown.Item>
            ))}
              
              <NavDropdown.Divider />
            
            </NavDropdown>

            
            {MenuItem(user.userData,lang).map((menuItems,index) => (
              menuItems.name && menuItems.link ?
              <Nav.Link eventKey={menuItems.name} style={{display:lang==="ar" && menuItems.name==="Logout" ?"none":""}} onClick={() => {setExpanded(false) ;setDropdownSelected(false)}} as={NavLink} key={menuItems.name} to={menuItems.link}   {...(menuItems.name=="Logout" && { onClick:logout })}>
                <i className={"mr-2"+" "+menuItems.icon}></i>
                {t('public.'+menuItems.name)}


            </Nav.Link>
            :''

            ))}

          {/*<NavDropdown className="langDropdown langDropdown-desktop" id="nav-dropdown-dark-example" title={<i className={lang ? lang+"-icon" : "fr-icon"}>{lang}</i>} menuVariant="dark" onSelect={handleSelect}>
          {dropdownLang.map((dropdownItems,index) => (
            lang && lang!=dropdownItems.key  ?
              <NavDropdown.Item  eventKey={dropdownItems.key} key={dropdownItems.key} ><i className={dropdownItems.icon}></i>{dropdownItems.name}</NavDropdown.Item>
              :''
          ))}
          </NavDropdown>*/}

        <NavDropdown className="langDropdown langDropdown-desktop" id="nav-dropdown-dark-example" title={<><i className="lang-icon"></i>{lang.toUpperCase()}</>} menuVariant="dark" onSelect={handleSelect}>
          {dropdownLang.map((dropdownItems,index) => (
            lang && lang!=dropdownItems.key  ?
              <NavDropdown.Item  eventKey={dropdownItems.key} key={dropdownItems.key} >{dropdownItems.name}</NavDropdown.Item>
              :''
          ))}
            </NavDropdown>

            <NavDropdown className="countryDropdown langDropdown langDropdown-desktop" id="nav-dropdown-dark-example" title={<img src={process.env.RAZZLE_REACT_APP_BASE_URL+detection.detectionData.icon}/>} menuVariant="dark">
          {country.map((dropdownItems,index) => (
              dropdownItems.value!=detection.detectionData.value?
              
              <NavDropdown.Item  eventKey={dropdownItems.key} key={dropdownItems.key} onClick={()=>handleCountrySelect({"code":dropdownItems.code,"currency":dropdownItems.currency,"icon":dropdownItems.icon,"label":dropdownItems.label,"value":dropdownItems.value})}><img src={process.env.RAZZLE_REACT_APP_BASE_URL+dropdownItems.icon}/>{dropdownItems.code}</NavDropdown.Item>
              :""
              ))}
          </NavDropdown>

          
      
                      
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
         <ToastContainer
         position="bottom-right"
         autoClose={3000}
         hideProgressBar={true}
         newestOnTop={false}
         closeOnClick
         rtl={false}
         theme="light"
         pauseOnFocusLoss
         draggable
         pauseOnHover
       />
       </>
    );
}



export default NavMenu;