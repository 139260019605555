import React,{useState,useCallback} from "react";
import { useFormik } from "formik";
import {confirmPasswordValidation} from "../../schema/formValid";
import { profilePassworApi } from "../../api/axios";
import { useSelector } from "react-redux";
function Changepassword(props){

    const [message,setMessage]=useState();
    const user= useSelector((state) => state.user)


    // profile submit for password changer 
    const handleOnSubmit =(values) => {
        const {oldPassword,newPassword,confirmPassword}=values;  
        const data=profilePassworApi(oldPassword,newPassword,user.userData.token);
        data.then(result=>{
            setMessage(result)
            if(result[0].status===200){
                formik.resetForm();
                setTimeout(()=>{
                    props.resetTab();
                    setMessage([]);
                },3000);
               
            }
        });      
       }

       const formik = useFormik({
        
        initialValues: {
            oldPassword:"",
            newPassword:"",
            confirmPassword:""
          

        },
        validationSchema: confirmPasswordValidation(props.translate),
        onSubmit: handleOnSubmit,
        validateOnChange:false,

      });

      const setInputValue = useCallback(
        (key, value) =>
          formik.setValues({
            ...formik.values,
            [key]: value,
          }),
        [formik],
      );


    return(
        <form noValidate onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <input value={formik.values.oldPassword} type="password" className="form-control" name="password" placeholder={props.translate('input:placeholder.Current Password')}  onChange={(e) => setInputValue("oldPassword", e.target.value)}/>
                <small className="text-error">{formik.errors.oldPassword}</small>            
            </div>
            
            <div className="form-group">
                <input value={formik.values.newPassword} type="password" className="form-control" name="password" placeholder={props.translate('input:placeholder.New Password')} onChange={(e) => setInputValue("newPassword", e.target.value)}/>
                <small className="text-error">{formik.errors.newPassword}</small>            
            </div>
            
                <div className="form-group">
                <input value={formik.values.confirmPassword} type="password" className="form-control" name="password" placeholder={props.translate('input:placeholder.Confirm new password')} onChange={(e) => setInputValue("confirmPassword", e.target.value)}/>
                <small className="text-error">{formik.errors.confirmPassword}</small>            
            </div>

            <div className="row">

            <div className="col text-center">
            
            </div>
            </div>
            
            <div className="row">

            <div className="col text-center">
            <small className="text-success">{!formik.errors.oldPassword && !formik.errors.newPassword && !formik.errors.confirmPassword && message && message.length>0 && message[0].status===200 ?props.translate('public.'+message[0].message) :''}</small>         
            <small className="text-error">{!formik.errors.oldPassword && !formik.errors.newPassword && !formik.errors.confirmPassword && message && message.length>0 && message[0].status!=200 ?props.translate('public.'+message[0].message) :''}</small>         
            </div>
            </div>

            
            <div className="row">
                <div className="mt-3 col text-center">
            
            <button type="submit" className="save-profile btn btn-primary">{props.translate('public.Save')}</button>

            </div>
            </div>
          </form>

    );

}
export default Changepassword;