import React from "react";
import ButtonLink from "../../components/ButtonLink.js";
import { useTranslation } from "react-i18next";
import mainImage from "../../assets/image/static-page/main-image.png";
import offreImage from "../../assets/image/static-page/offreInfocagnotte.svg";
import asavoir from "../../assets/image/static-page/asavoir.png";
import Slider from "../../components/Slider.js";
import SEO from "../../components/SEO.js";

function Pack() {
  const { t } = useTranslation("common");

  return (
    <div className="container containerInfocagnotte container-pack">
       <SEO 
            title={t('seoContent.title new trustiT pack',{name:process.env.REACT_APP_NAME})}
            description={t('seoContent.description trustiT pack',{name:process.env.REACT_APP_NAME})}
            keywords={t('seoContent.keyword trustit pack')}
           />
            <h1 className="text-header">{t('SeoBody.pack h1',{name:process.env.REACT_APP_NAME})}</h1>

      <div className="row">
        <div className="col-lg-4">
          <figure>
            <img width="100%" src={offreImage} alt="" />
          </figure>
        </div>

        <div className="col-lg-3">
          <h3 className="title-form">{t("public.TrustiT Pack")}</h3>
          <p>
            {t(
              "offre.Intended for all types of businesses, the 'TrustiT' B2B pack is dedicated to solving all your IT problems"
            )}
          </p>
          <ButtonLink path="/pack-devis" name={t("public.register")} />
        </div>

        <div className="col-lg-4">
          <img className="handImage" src={mainImage} />
        </div>
      </div>


      <div className="mt-4 row justify-content-center">
        <div className="mt-5 card-deck">
          <div className="col-md-12 col-lg-4 col-sm-12 col-xs-6">
            <div className="mb-4 card card-pack">
              <div className="card-header bg-transparent ">
                {t("offre.Annual")}
              </div>
              <div className="card-header">{t("offre.Pack Pluton")}</div>
              <div className="card-body">
                <p className="card-text">
                  <p>{t("offre.1st free audit")}</p>{" "}
                  <p>{t("offre.Kaspersky license workstation", { nb: 1 })}</p>{" "}
                  <p>{t("offre.curative intervention", { nb: "20 "+t('offre.H') })}</p>{" "}
                  <p>{t("offre.Pick up and drop off")}</p>{" "}
                  <p>{t("offre.Preventive intervention")}</p>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-sm-6 col-xs-6">
            <div className="mb-4 card card-pack">
              <div className="card-header bg-transparent ">
                {t("offre.Half-yearly")}
              </div>
              <div className="card-header">{t("offre.Pack Uranus")}</div>
              <div className="card-body">
                <p className="card-text">
                  <p>{t("offre.1st free audit")}</p>{" "}
                  <p>{t("offre.Kaspersky license workstationnb", { nb: 3 })}</p>{" "}
                  <p>{t("offre.curative intervention", { nb: "30 "+t('offre.H') })}</p>{" "}
                  <p>{t("offre.Pick up and drop off")}</p>{" "}
                  <p>{t("offre.Preventive intervention")}</p>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-sm-6 col-xs-6">
            <div className="mb-4 card card-pack">
              <div className="card-header bg-transparent">
                {t("offre.Quarterly")}
              </div>
              <div className="card-header">{t("offre.Pack Jupiter")}</div>
              <div className="card-body">
                <p className="card-text">
                  <p>{t("offre.1st free audit")}</p>{" "}
                  <p>{t("offre.Kaspersky license workstationnb", { nb: 5 })}</p>{" "}
                  <p>{t("offre.curative intervention", { nb: "40 "+t('offre.H') })}</p>{" "}
                  <p>{t("offre.Pick up and drop off")}</p>{" "}
                  <p>{t("offre.Preventive intervention")}</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <div className="row margin-space">
        <div className="col-lg-6">
          <h3 className="title-form">
            {t("offre.TrustiT Pack what you need to know")}
          </h3>
          <span>
            {t(
              "offre.The TrustiT pack offers you a unique experience, made up of a set of turnkey services, the pack allows you to manage your computer equipment. With TrustiT say goodbye to security problems, delays, printing, etc. Quality and reasonable price, everything you will need in one pack. It should also be mentioned that you do not need a contractual commitment to benefit from our services, so what are you waiting for to try the pack that suits you?"
            )}
          </span>
        </div>

        <div className="col-lg-1"></div>

        <div className="mt-4 col-lg-4 mb-3">
          <img width="100%" src={asavoir} alt="" />
          <div className="row">
            <div className="mt-4 col text-center">
              <ButtonLink path="/contact" name={t("public.Contact us")} />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <Slider translate={t} />
      </div>
    </div>
  );
}
export default Pack;
