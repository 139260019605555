import React from "react";
import { useEffect,useState } from "react";
import moment from 'moment';
import { filterCurency, handleEtat, handlePrice, handleTranName } from "../../utils/repairState";
import {useHistory} from "react-router-dom";
import Pagination from 'react-bootstrap/Pagination';
import { useSelector } from "react-redux";

function ListMobile(props){

    const histroy=useHistory();
    const [repair,setMyRepair]=useState([]);
    const [repairFilter,setMyRepairFilter]=useState([]);
    const country= useSelector((state) => state.country.countryData)
    const user= useSelector((state) => state.user)

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;  
        
    const currentRecords = repair.slice(indexOfFirstRecord, indexOfLastRecord);
      const nPages = Math.ceil(repair.length / recordsPerPage)
      const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

      useEffect(() => {
          setMyRepair(props.repairProps)
          setMyRepairFilter(props.repairProps)
        }, []);
  
        const handleClick=(index,code)=>{
            histroy.push({
                pathname:"/dashboard/reparation/"+code.toLowerCase(),
                state:repair[index]
            })
            window.scrollTo(0,0);
        }

        
// set current pagination number 
  const handlePagination=(pageNb)=>{
    setCurrentPage(pageNb)
    window.scrollTo(0,100);
  } 

  // filter reparation table list
  const handleSearch=(event)=>{
    setMyRepair(repairFilter.filter(repairs=>repairs.code.toUpperCase().includes(event.target.value.toUpperCase())))
  }

  // render pagination view
  const renderPagination=(marginTrue)=>{
    return(
      nPages>1 ?
      <Pagination className={marginTrue?"pagination-hold mt-4":"pagination-hold"}>

      {pageNumbers.map(pageNumberss=>
         <Pagination.Item key={pageNumberss} activeLabel="" active={pageNumberss==currentPage?true:false} onClick={()=>handlePagination(pageNumberss)}>{pageNumberss}</Pagination.Item>
      )}


   </Pagination>
      :""
    );
  }

  
    return(
        <>
        <div className="col-lg-12 col-md-12 repair-mobile">
          <input type="text" onChange={handleSearch} className="mb-3 form-control" placeholder={props.translate('repair.Type your code')}/>
            {currentRecords.map((repairs,index)=>
                    <div key={repairs._id} onClick={()=>handleClick(index,repairs.code)} style={{cursor:"pointer"}} className="row">
                    <div className="col-lg-12">
                    <p>{handleTranName(props.resolvedLanguage,repairs,true,true)?handleTranName(props.resolvedLanguage,repairs,true,true) :repairs.category.displayName}</p>

                      </div>
                    <div className="col-lg-2 col-3">
                    <img width="50px" src={process.env.RAZZLE_REACT_APP_BASE_URL+repairs.ReparationProduct.typeReparation.picture}/>
                    </div>
                    <div className="col-lg-10 col-9">
                    <p className="text-right">{handlePrice(repairs.ReparationProduct.prix,repairs,country,props.translate)}</p>
                    <p>{repairs.code}</p>
                    <p>{props.translate('etat.'+handleEtat(repairs.etat))}</p>
                    <p className="text-right">
                    {moment(repairs.created_at).locale(props.resolvedLanguage!="ar" ? props.resolvedLanguage :'ar-tn').fromNow()}
                    </p>

                    </div>

                 </div>
                )}
             {renderPagination("marginTrue")}
        </div>
        </>
    );
}

export default ListMobile;