export const stepincrement = () => {
    return {
      type: "INCSTEP",
    };
  };
  
  export const stepdecrement = () => {
    return {
      type: "DECSTEP",
    };
  };

  export const stepCurrent = (nb) => {
    return {
      type: "ADDSTEP",
      payload:nb
    };
  };