import React from "react";
import computeroffre from "../../assets/image/static-page/computerOffre.svg";
import mainImage from "../../assets/image/static-page/main-image.png";
import repair from "../../assets/image/static-page/repair.png";
import OffreImage from "../../components/OffreImage.js";
import Slider from "../../components/Slider.js";
import ButtonLink from "../../components/ButtonLink.js";
import { useTranslation } from 'react-i18next';
import SEO from "../../components/SEO";

function OffreEducagnotte(){

    const { t,i18n} = useTranslation("common");
    const lang=i18n.resolvedLanguage;

    return(
        <>
        <div className="container containerInfocagnotte">

        <SEO 
            title={t('seoContent.title educagnotte offer',{name:process.RAZZLE_REACT_APP_NAME})}
            description={t('seoContent.description educagnotte offer',{name:process.RAZZLE_REACT_APP_NAME})}
            keywords={t('seoContent.keyword educagnotte offer')}

          />
            <h1 className="text-header">{t('SeoBody.educagnotte offer h1',{name:process.RAZZLE_REACT_APP_NAME})}</h1>

                <div className="row">
                <div className="col-lg-4">
                <figure>
                <img width="100%" src={computeroffre} alt=""/>
                </figure>
                </div>

                <div className="col-lg-3">
                <h3 className="title-form">{t('public.Educagnotte offer')}</h3>
                <p>{t('offre.A new perspective of optimized repair, aimed at private universities!')}</p>
                <ButtonLink fromCustompath={true} path={{pathname:`/${lang}/devis`,state:{"typeDevis":t('public.Educagnotte offer')}}} name={t('public.Ask for devis')}/>
                </div>

                <div className="col-lg-4">
                <img className="handImage" src={mainImage}/>

                </div>

                </div>

                <div className="row margin-space">
                <div className="mt-2 col-lg-6">
                <h3 className="title-form">{t('offre.The educagnotte the solution adapted to your needs')}</h3>
                <span>
                {t("offre.The Educagnotte outsourcing offer (maintenance and repair of electronic devices) is based on a kitty that you set and which will be dedicated to responding free of charge to your students' requests for their needs for repairs and purchases of computer equipment. pot can also be used for repairs / purchases of your staff and teaching staff. The objective is to ensure a high quality service and high availability everywhere in Tunisia duly adapted to your needs. With the exclusive offer of the educagnotte, you will be satisfied or refunded thanks to the commitment according to the duration that we already put at your disposal.")}
                </span>
                </div>

                <div className="col-lg-1">
                </div>

                <div className="mt-4 col-lg-4">
                <img width="100%" src={repair} alt=""/>
                <div className="row">
                    <div className="mt-4 mb-4 col text-center">
                    <ButtonLink path="/contact" name={t('public.Contact us')}/>

                </div>
                </div>
                </div>
                </div>
                <OffreImage translate={t}/>
                </div>

                <div className="container-fluid">
                <Slider translate={t}/>
                </div>

        </>
    );
}

export default OffreEducagnotte;