const initState={
    steperData:{}
}

const steperDataReducer = (state = initState , action) => {

    switch(action.type) {
        case 'FETCH_STEPER_DATA':
            return {...state,steperData:action.payload.steperData}
        default:
            return {...state};
    }
}

export default steperDataReducer