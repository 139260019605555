import {blogApi, blogCount} from "../../api/axios"
const fetchBlogSuccess = (blog,additionalData) => ({
    type: 'FETCH_ALL_BLOG',
    payload: {blog:blog,blogAdditionalData:additionalData }
})


// fetch blog data

export const fetchBlog =  (page) => {
    return async (dispatch,getState) => {

        const {countryDetected}=getState();
        const countryId=countryDetected && countryDetected.detectionData?countryDetected.detectionData.value:"";
        try {

            let additionalData={    
                countPerPage:9,
                page:1,
                totalBlog:0
            
            }
        
            if(page){
            additionalData.page=page;
            }
            
            let blog = await blogApi(additionalData.countPerPage,page,countryId);
            await blogCount().then(res=>{
                additionalData.totalBlog=res
            })
            dispatch(fetchBlogSuccess(blog,additionalData))
        }
        catch(e){
            console.log(e)
        }
    }
}