const brandReducer = (state = [] , action) => {

    switch(action.type) {
        case 'FETCH_ALL_BRAND':
            return {...state,brandData:action.payload.brand}
        default:
            return state
    }
}

export default brandReducer