import React from "react";
import offreInfocagnotte from "../../assets/image/static-page/offreInfocagnotte.svg";
import mainImage from  "../../assets/image/static-page/main-image.png";
import phoneBussnis from  "../../assets/image/static-page/phone-bussnis.png";
import computeroffre from "../../assets/image/static-page/computerOffre.svg";
import { useTranslation } from 'react-i18next';
import ButtonLink from "../../components/ButtonLink.js";
import Slider from "../../components/Slider.js";
import repair from  "../../assets/image/static-page/repair.svg";
import universe from  "../../assets/image/static-page/universe.svg";
import {detailsWebsite } from "../../utils/repairState";
import SEO from "../../components/SEO";

function OffreBusiness(){
    const {t} = useTranslation("common");

    return(
        <div className="offrebusiness-container container">
           <SEO 
            title={t('seoContent.title business offer',{name:process.env.RAZZLE_REACT_APP_NAME})}
            description={t('seoContent.description offre business',{name:process.env.RAZZLE_REACT_APP_NAME})}
            keywords={t('seoContent.keyword offre business')}
           />
            <h1 className="text-header">{t('SeoBody.business offers h1')}</h1>

            <div className="container-fluid slider-container">
             <Slider translate={t} changeImage={true}/>
            </div>

            <div style={{marginTop:"80px"}} className="row">
            <div className="col-lg-4">
            <figure>
            <img src={offreInfocagnotte} alt={t('public.Infocagnotte offer')}/>
            </figure>
            </div>

            <div className="col-lg-4">
            <h3 className="title-form">{t('public.Infocagnotte offer')}</h3>
            <p>{t('offre.Entrust your IT equipment to a trusted IT maintenance contract provider!')}</p>
            <ButtonLink path="/infocagnotte-offer" name={t('public.Learn more')}/>
             </div>

            <div className="col-lg-4">
            <img  className="hidden-static" src={mainImage}/>

            </div>

            </div>

            <div className="row margin-space">
            <div className="col-lg-4">
            <figure className="hidden-static">
            <img src={phoneBussnis} alt={t('public.Educagnotte offer')}/>
            </figure>
            </div>
            <div className="col-lg-3 mb-4">
            <h3 className="title-form">{t('public.Educagnotte offer')}</h3>
            <p>{t('offre.A new perspective of optimized repair, aimed at private universities!')}</p>
            <ButtonLink path="/educagnotte-offer" name={t('public.Learn more')}/>

            </div>

            <div className="col-lg-4">
            <img src={computeroffre}/>
            </div>

            </div>

            <div style={{marginTop:"60px"}} className="row">
            <div className="col-lg-5">
            <figure>
            <img src={universe} alt={t('offre.New TrustiT Pack')}/>
            </figure>
            </div>

            <div className="col-lg-3">
            <h3 className="title-form">{t('offre.New TrustiT Pack')}</h3>
            <p>{t('offre.Travel the universe with the new b2b pack - the TrustiT pack')}</p>
            <ButtonLink path="/pack" name={t('public.Learn more')}/>
             </div>

            <div className="col-lg-4">
            <img  className="hidden-static" src={repair}/>

            </div>

            </div>

            <div className="mb-5 row margin-space">
            <div className="col-lg-6">
            <div className="mb-3 block-contact contact-info">
            <span className="d-block"><i className="contact-logo phone-logo"></i><span><a className="remove-link"href="tel:25 888 874">25 888 874</a> / <a className="remove-link" href="tel:29 772 776">29 772 776</a></span></span>
            <span className="d-block"><i className="contact-logo email-logo"></i><span><a className="remove-link" href="mailto:sales@trustit.tn">sales@trustit.tn</a></span></span>
            <span className="d-block"><i className="contact-logo position-logo"></i>{t('public.Address')} : {detailsWebsite("","","address")}</span>

            </div>
         
            </div>
            <div className="col-lg-6">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51084.040391434915!2d10.156956929592832!3d36.84840254478863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd35fa41b73cf3%3A0x40651c0b73193dec!2sTrustiT!5e0!3m2!1sen!2stn!4v1654004200903!5m2!1sen!2stn" width="100%" height="250" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            </div>


        </div>
    );
}

export default OffreBusiness;