import React,{useState,useCallback} from "react";
import PrependInput from "../../components/PrependInput";
import { useFormik } from "formik";
import { callbackApi} from "../../api/axios";
import { phoneValidation } from "../../schema/formValid";

function CallBack(props){

    const [message,setMessage]=useState([]);
    const [viewDisable,setviewDisable]=useState(false);


    // call back phone submit 
    const handleOnSubmit =(values) => {
        const {phone}=values;
        setviewDisable(true);
        const data=callbackApi(phone,props.resolvedlang);
        data.then(response=>{
          setMessage(response);
          if(response[0] && response[0].status===200){
            setTimeout(()=>{
              setMessage([]);
              formik.resetForm();
              setviewDisable(false);
  
            },3000)
            
        }
        else {
          setviewDisable(false);
  
        }
      })
    }

    const formik = useFormik({
        initialValues: {
          phone :"",
        },
        validationSchema: phoneValidation(props.translate),
        onSubmit: handleOnSubmit,
        onClick: handleOnSubmit,
        validateOnChange:false,
  
      });
  
      const setInputValue = useCallback(
        (key, value) =>
          formik.setValues({
            ...formik.values,
            [key]: value,
          }),
        [formik],
      );
  

    return(
        <>
        <h3 className="title-form">{props.translate('home.headerRight')}</h3>
        <PrependInput formik={formik} value={formik.values.phone} backendMessage2={!formik.errors.phone && message && message.length>0 && message[0].status===200 ? props.translate('home.'+message[0].message) :''} backendMessage={!formik.errors.phone && message && message.length>0 && message[0].status===404 ? props.translate('public.'+message[0].message) :''} errorFormik={formik.errors.phone} fname={formik.handleSubmit} placeholderText={props.translate('input:placeholder.Phone number')} change={(e) => setInputValue("phone", e.target.value)}/>
        
        <span className="mb-3" >{props.translate('home.Depose your repair by simply adding your phone number!')}</span>
        </>
    );


}
export default CallBack;