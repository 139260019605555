import React from "react";
import PropagateLoader from "react-spinners/BeatLoader";

//spinner style 

const override= {
 textAlign:"center",
 marginTop:"60px"
};

function SpinnerLoad(){

    return(
        <PropagateLoader 
        color="#35A0EE"
        size={15}
        cssOverride={override}
        />
    );
}
export default SpinnerLoad;