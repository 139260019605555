import React, { useRef } from "react";
import { useEffect } from "react";
import PhoneInput from 'react-phone-number-input'
import { useSelector } from "react-redux";

function PhoneComponent(props){
  const ref=useRef(null);
  const detection= useSelector((state) => state.countryDetected)
  const country= useSelector((state) => state.country.countryData)
 
  // phone country change handler 
  const handleCountry=(countryCode)=>{
    if(props.setPhoneCountry){
    let filtredCountry=country.filter(countrys=>countrys.code==countryCode);
    if(filtredCountry && filtredCountry[0]){
    console.log(filtredCountry[0])
    props.setPhoneCountry(filtredCountry[0])
    
    }
  }
  }
  

  useEffect(()=>{
    console.log(detection)
  },[])


  const handleFocus=()=>{

    console.log("clicked")
    const inputLength=ref.current.value.length;
    ref.current.setSelectionRange(inputLength,inputLength);
    ref.current.focus();

  }

    return(
      <>
    <PhoneInput
      ref={ref}
      name="phone"
      placeholder={props.translate('input:placeholder.Phone')}
      value={props.value}
      className={props.formikError?"form-control is-invalid":"form-control"}
      countries={["TN", "SA","AE"]}
      international = {true}
      countryCallingCodeEditable={false}
      defaultCountry={detection.detectionData.code}
      addInternationalOption={false}
      onChange={props.change}
      onCountryChange={handleCountry}
      onClick={handleFocus}
      />

</>

    );
}

export default PhoneComponent;